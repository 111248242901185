import React from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import Modal, { defaultModalClassName } from "../modals/Modal";

const messages = defineMessages( {
	modalHeading: {
		id: "modal.heading",
		defaultMessage: "Yoast SEO Installation",
	},
	notCompatibleTYPO3ModalNoticeText: {
		id: "install.notCompatibleTYPO3.modal.notice.text",
		defaultMessage: "This plugin is not compatible with a TYPO3 site, it can only be installed on a WordPress site.",
	},
	notCompatibleTYPO3ModalCloseButton: {
		id: "install.notCompatibleTYPO3.modal.button.close",
		defaultMessage: "Close",
	},
} );

const ButtonPlacement = styled.div`
			display: flex;
			justify-content: flex-end;
			gap: 16px;
		`;

/**
	 * Modal for when the used entered a TYPO3 site URL.
	 *
	 * @param  {Object} props The props.
	 *
	 * @returns {ReactComponent} The rendered component.
	 */
const NotCompatibleTYPO3Modal = props => {
	return (
		<Modal
			title={ props.intl.formatMessage( messages.modalHeading ) }
			onRequestClose={ props.closeModal }
			className={ defaultModalClassName }
		>
			<p style={ { color: "#8F1919" } }><FormattedMessage { ...messages.notCompatibleTYPO3ModalNoticeText } /></p>

			<ButtonPlacement>
				<FormattedMessage { ...messages.notCompatibleTYPO3ModalCloseButton }>
					{ ( msg ) => ( <button onClick={ props.closeModal } className="yoast-button">{ msg }</button> ) }
				</FormattedMessage>
			</ButtonPlacement>
		</Modal>
	);
};

NotCompatibleTYPO3Modal.propTypes = {
	intl: intlShape.isRequired,
	closeModal: PropTypes.func.isRequired,
};

export default injectIntl( NotCompatibleTYPO3Modal );
