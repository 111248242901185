import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape, defineMessages } from "react-intl";
import { speak } from "@wordpress/a11y";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import PluginUpsell from "./PluginUpsell";
import AcademyUpsell from "./AcademyUpsell";
import SitesCard from "./SitesCard";
import SupportCard from "./SupportCard";
import BlogFeed from "./BlogCard";

const messages = defineMessages( {
	homePageLoaded: {
		id: "home.page.loaded",
		defaultMessage: "Home page loaded",
	},
} );

const cards = [
	{
		id: "academy-upsell-card",
		className: "UpsellCard",
		component: <AcademyUpsell />,
	},
	{
		id: "sites-card",
		className: "SitesCard",
		component: <SitesCard />,
	},
	{
		id: "plugin-upsell-card",
		className: "UpsellCard",
		component: <PluginUpsell />,
	},
	{
		id: "blog-card",
		className: "BlogCard",
		component: <BlogFeed />,
	},
	{
		id: "support-card",
		className: "SupportCard",
		component: <SupportCard />,
	},
];

/**
 * Returns the rendered HomePage component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered home page.
 */
const HomePage = props => {
	/**
	 * Speaks a message when the component mounted.
	 *
	 * @returns {void}
	 */
	useEffect( () => {
		// Announce navigation to assistive technologies.
		const message = props.intl.formatMessage( messages.homePageLoaded );
		speak( message );
	}, [] );

	/**
	 * Wraps the inner card content components in a ShadowDiv and a FullHeightCard.
	 *
	 * @param {Object[]} cardsArray An array of cards with id, className, and the inner card component.
	 *
	 * @returns {ReactElement[]} Returns an array of React elements.
	 */
	const createCards = ( cardsArray ) => {
		if ( props.isOnlyProvisionerSubscriptions ) {
			cardsArray = cardsArray.filter( card => ! [ "sites-card", "plugin-upsell-card" ].includes( card.id ) );
		}

		return cardsArray.map( card => {
			return <div id={ card.id } key={ card.id } className={ card.className }>
				{ card.component }
			</div>;
		} );
	};

	return (
		<ResponsiveMasonry
			columnsCountBreakPoints={ { 360: 1, 768: 2, 1024: 1, 1280: 2, 1536: 3 } }
		>
			<Masonry gutter="24px">
				{ createCards( cards ) }
			</Masonry>
		</ResponsiveMasonry>
	);
};

HomePage.propTypes = {
	intl: intlShape.isRequired,
	isOnlyProvisionerSubscriptions: PropTypes.bool,
};

HomePage.defaultProps = {
	isOnlyProvisionerSubscriptions: false,
};

export default injectIntl( HomePage );


