import "whatwg-fetch";

import { doRequest, prepareInternalRequest } from "../../functions/api";
import { getUserId } from "../../functions/auth";
import { getAllSubscriptions } from "./subscriptions";
import { getAllProducts } from "./products";
import { getProductGroups } from "./productGroups";
import { push } from "connected-react-router";

/**
 * Action types
 */

export const LINK_SITE_REQUEST = "LINK_SITE_REQUEST";
export const LINK_SITE_SUCCESS = "LINK_SITE_SUCCESS";
export const LINK_SITE_FAILURE = "LINK_SITE_FAILURE";

export const UPDATE_SITE_URL = "UPDATE_SITE_URL";

export const RETRIEVE_SITES_REQUEST = "RETRIEVE_SITE_REQUEST";
export const RETRIEVE_SITES_SUCCESS = "RETRIEVE_SITES_SUCCESS";
export const RETRIEVE_SITES_FAILURE = "RETRIEVE_SITES_FAILURE";

/**
 * Action creators
 */

/**
 * An action creator for the server request action.
 *
 * @param {string} url The url to add.
 *
 * @returns {Object} A server request action.
 */
export function updateSiteUrl( url ) {
	return {
		type: UPDATE_SITE_URL,
		url: url,
	};
}

/**
 * An action creator for the link site request action.
 *
 * @returns {Object} A link site request action.
 */
export function linkSiteRequest() {
	return {
		type: LINK_SITE_REQUEST,
	};
}

/**
 * An action creator for the link site success action.
 *
 * @param {Object} site The sites object.
 *
 * @returns {Object} A link site success action.
 */
export function linkSiteSuccess( site ) {
	return {
		type: LINK_SITE_SUCCESS,
		site: site,
	};
}

/**
 * An action creator for the link site failure action.
 *
 * @param {Object} error The error object that was thrown.
 *
 * @returns {Object} A link site failure action.
 */
export function linkSiteFailure( error ) {
	return {
		type: LINK_SITE_FAILURE,
		error: error,
	};
}

/**
 * An action creator for the link site action.
 *
 * @param {string}  url      The site url trying to be linked.
 * @param {string}  type     The CMS the to be linked site is running on.
 *
 * @returns {Object} A link site request action.
 */
export function linkSite( url, type ) {
	return ( dispatch ) => {
		dispatch( updateSiteUrl( url ) );
		dispatch( linkSiteRequest() );

		const userId = getUserId();
		const request = prepareInternalRequest( `Customers/${ userId }/sites/`, "POST", { url, type } );

		return doRequest( request )
			.then( json => dispatch( linkSiteSuccess( json ) ) )
			.then( json => dispatch( push( `/sites/${ json.site.id }` ) ) )
			.catch( error => dispatch( linkSiteFailure( error ) ) );
	};
}

/**
 * An action creator for the server request action.
 *
 * @returns {Object} A server request action.
 */
export function retrieveSitesRequest() {
	return {
		type: RETRIEVE_SITES_REQUEST,
	};
}

/**
 * An action creator for the retrieve sites success action.
 *
 * @param {Array} sites The sites to be retrieved.
 *
 * @returns {Object} A retrieve sites success action.
 */
export function retrieveSitesSuccess( sites ) {
	return {
		type: RETRIEVE_SITES_SUCCESS,
		sites: sites,
	};
}

/**
 * An action creator for the retrieve sites failure action.
 *
 * @param {object} error The error that was thrown.
 *
 * @returns {Object} A retrieve sites failure action.
 */
export function retrieveSitesFailure( error ) {
	return {
		type: RETRIEVE_SITES_FAILURE,
		error: error,
	};
}

/**
 * An action creator for the retrieve sites action.
 *
 * @returns {Object} A retrieve sites action.
 */
export function retrieveSites() {
	return async( dispatch ) => {
		dispatch( retrieveSitesRequest() );

		const userId = getUserId();
		const request = prepareInternalRequest(
			`Customers/${ userId }/sites/`,
			"GET",
			{},
			{ queryParams: { filter: { include: "subscriptions" } } },
		);

		try {
			const json = await doRequest( request );
			return dispatch( retrieveSitesSuccess( json ) );
		} catch ( error ) {
			return dispatch( retrieveSitesFailure( error ) );
		}
	};
}

/**
 * An action creator for loading sites, product(group)s and subscriptions.
 *
 * @returns {Object} a dispatcher that dispatches the actions.
 */
export function loadSites() {
	return ( dispatch ) => {
		dispatch( retrieveSites() );
		dispatch( getAllProducts() );
		dispatch( getProductGroups() );
		dispatch( getAllSubscriptions() );
	};
}
