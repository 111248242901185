import React from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import icon from "../../images/greeting.png";
import logo from "../../images/my-yoast-academy-logo.svg";
import { Title } from "@yoast/ui-library";

const messages = defineMessages( {
	header: {
		id: "login.defaultAside.header",
		defaultMessage: "Welcome back!",
	},
	message: {
		id: "login.defaultAside.message",
		defaultMessage: "Log in with your email address and password. If you don't remember your password, just reset it!",
	},
} );

/* eslint-disable max-len */
/**
 * Display's the left side with the Login branding.
 *
 * @returns {ReactElement} The component that contains the message.
 */
function DefaultLoginAside() {
	return (
		<>
			<img className="asideLogo" src={ logo } alt="MyYoast - Yoast Academy" />
			<img className="asideIcon" src={ icon } alt="" />
			<Title size="2">
				<FormattedMessage { ...messages.header } />
			</Title>
			<p>
				<FormattedMessage { ...messages.message } />
			</p>
		</>
	);
}

/* eslint-enable max-len */

export default injectIntl( DefaultLoginAside );
