import {
	GET_ORDERS_REQUEST,
	GET_ORDERS_SUCCESS,
	GET_ORDERS_FAILURE,
} from "shared-frontend/redux/actions/orders";

/**
 * Initial state
 */
const rootState = {
	orders: {
		retrievingOrders: false,
		error: null,
	},
};

/**
 * Reducers
 */

/**
 * A reducer for the orders object within the ui object.
 *
 * @param {Object} state The current state of the object.
 * @param {Object} action The current action received.
 * @returns {Object} The updated Orders object.
 */
export function uiOrdersReducer( state = rootState.orders, action ) {
	switch ( action.type ) {
		case GET_ORDERS_REQUEST:
			return Object.assign( {}, state, {
				retrievingOrders: true,
				error: null,
			} );
		case GET_ORDERS_SUCCESS:
			return Object.assign( {}, state, {
				retrievingOrders: false,
			} );
		case GET_ORDERS_FAILURE:
			return Object.assign( {}, state, {
				retrievingOrders: false,
				error: action.error,
			} );
		default:
			return state;
	}
}
