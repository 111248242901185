import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { injectIntl, intlShape, defineMessages, FormattedMessage } from "react-intl";
import DownloadItems from "./DownloadItems";
import { speak } from "@wordpress/a11y";
import ComposerHelp from "./ComposerHelp";
import { Title, Alert, Link, Modal } from "@yoast/ui-library";
import styles from "./DownloadsPageStyles.scss";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

const messages = defineMessages( {
	downloadsPageLoaded: {
		id: "downloadsPage.page.loaded",
		defaultMessage: "Downloads page loaded",
	},
	downloadPageTitle: {
		id: "downloads.page.title",
		defaultMessage: "Downloads",
	},
	downloadPageDescription: {
		id: "downloads.page.description",
		defaultMessage: "Need help installing Yoast plugins? Read our { link }.",
	},
	guide: {
		id: "downloadsPage.noProducts",
		defaultMessage: "our guide",
	},
	noDownloads: {
		id: "downloadsPage.noDownloads",
		defaultMessage: "You don't have anything to download yet. To get downloads visit our { link }.",
	},
	infoDownloadsOnlyProvisionedSubscriptions: {
		id: "downloadsPage.infoProvisionedNotShopify",
		defaultMessage: "You have sites with one or more of our provisioning partners. " +
			"The download and installation of these products are handled on purchase by them.",
	},
	infoDownloadsMixedProvisionedSubscriptions: {
		id: "downloadsPage.infoMixedProvisionedSubscriptions",
		defaultMessage: "On this page, you can download zip files related to your Yoast SEO products. " +
			"The download and installation of products purchased through third parties " +
			"are handled by their teams respectively.",
	},
} );

/**
 * Returns an Alert if certain conditions are met.
 *
 * @param {Boolean} isOnlyProvisionerSubscriptions Does user have only provisioned subscriptions?
 * @param {Boolean} hasMixedSubscriptions Does user have subscriptions mixed between Yoast and any of the provisioners?
 * @param {Object} intl Intl object.
 *
 * @returns {Boolean|false|ReactElement} An info alert or false if conditions are not met.
 */
const DownloadsPageAlert = ( { isOnlyProvisionerSubscriptions, hasMixedSubscriptions, intl } ) => {
	return ( isOnlyProvisionerSubscriptions || hasMixedSubscriptions ) && (
		<Alert
			className={ styles.downloadsPageAlert }
			as="div"
			variant="info"
			role="alert"
		>
			{ isOnlyProvisionerSubscriptions && intl.formatMessage( messages.infoDownloadsOnlyProvisionedSubscriptions ) }
			{ hasMixedSubscriptions && intl.formatMessage( messages.infoDownloadsMixedProvisionedSubscriptions ) }
		</Alert>
	);
};

DownloadsPageAlert.propTypes = {
	isOnlyProvisionerSubscriptions: PropTypes.bool,
	hasMixedSubscriptions: PropTypes.bool,
	intl: intlShape.isRequired,
};

/**
 * Returns the rendered Downloads Page component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {JSX.Element} The downloads page.
 */
const DownloadsPage = ( props ) => {
	useEffect( () => {
		const message = props.intl.formatMessage( messages.downloadsPageLoaded );
		speak( message );
	}, [] );

	/**
	 * Returns the modal for help on using Composer.
	 *
	 * @returns {ReactElement} The modal for help on using Composer.
	 */
	const getModal = () => {
		const modalAriaLabel = {
			id: "modal.arialabel.create",
			defaultMessage: "Help on using Composer",
		};

		return (
			<Modal
				isOpen={ props.composerHelpModalIsOpen }
				onClose={ props.onComposerHelpModalClose }
				aria-label={ modalAriaLabel }
			>
				<Modal.Panel>
					<ComposerHelp
						onClose={ props.onComposerHelpModalClose }
						productName={ props.composerHelpProductName }
						productGlNumber={ props.composerHelpProductGlNumber }
						createComposerToken={ props.composerHelpCreateComposerToken }
						composerToken={ props.composerToken }
					/>
				</Modal.Panel>
			</Modal>
		);
	};

	const hideForWooCommerceProvisioner =
				props.provisioners.length === 1 &&
				props.provisioners.includes( "WooCommerce" );

	return (
		<>
			<div className={ styles.downloadsPageHeader }>
				<Title as="h1" size="1">
					<FormattedMessage { ...messages.downloadPageTitle } />
				</Title>
				<p>
					<FormattedMessage
						{ ...messages.downloadPageDescription }
						values={
							{
								link: <Link className={ styles.downloadPageLink } target="_blank" href="https://yoa.st/myyoast-installation">
									<span>installation guides</span>
									<ArrowTopRightOnSquareIcon className={ styles.downloadPageLinkIcon } />
								</Link>,
							}
						}
					/>
				</p>
			</div>
			<div className={ styles.downloadsPageContentWrapper }>
				{ ! hideForWooCommerceProvisioner && <DownloadsPageAlert { ...props } /> }

				{ props.plugins.length === 0 && (
					<p className={ styles.downloadsPageNoDownloads }>
						<FormattedMessage
							{ ...messages.noDownloads }
							values={
								{
									link: <Link className={ styles.downloadPageLink } target="_blank" href="https://yoast.com/shop">
										<span>shop</span>
										<ArrowTopRightOnSquareIcon className={ styles.downloadPageLinkIcon } />
									</Link>,
								}
							}
						/>
					</p>
				) }
			</div>

			{ props.plugins.length > 0 && (
				<DownloadItems
					products={ props.plugins }
					composerToken={ props.composerToken }
					onComposerHelpModalOpen={ props.onComposerHelpModalOpen }
					onComposerHelpModalClose={ props.onComposerHelpModalClose }
				/>
			) }

			{ getModal() }
		</>
	);
};

DownloadsPage.propTypes = {
	intl: intlShape.isRequired,
	plugins: PropTypes.array,
	composerToken: PropTypes.object,
	composerHelpModalIsOpen: PropTypes.bool,
	onComposerHelpModalOpen: PropTypes.func.isRequired,
	onComposerHelpModalClose: PropTypes.func.isRequired,
	composerHelpCreateComposerToken: PropTypes.func.isRequired,
	composerHelpProductName: PropTypes.string,
	composerHelpProductGlNumber: PropTypes.string,
	composerHelpComposerToken: PropTypes.object,
	isOnlyProvisionerSubscriptions: PropTypes.bool.isRequired,
	hasMixedSubscriptions: PropTypes.bool.isRequired,
	provisioners: PropTypes.array,
};

DownloadsPage.defaultProps = {
	plugins: [],
	composerToken: null,
	composerHelpModalIsOpen: false,
	composerHelpProductName: "",
	composerHelpProductGlNumber: "0",
	composerHelpComposerToken: null,
	provisioners: [],
};

export default injectIntl( DownloadsPage );
