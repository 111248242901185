import React, { useEffect, useState } from "react";

import { getUserId, getAccessToken } from "shared-frontend/functions/auth";
import { Button, Card, Title } from "@yoast/ui-library";
import styles from "./DebugInfoStyles.scss";
import { WrenchScrewdriverIcon } from "@heroicons/react/24/outline";

/**
 * Copies to clipboard
 * @param {String} text Text to be copied.
 * @returns {boolean|void} Nothing.
 */
const copyToClipboard = ( text ) => {
	if ( window.clipboardData && window.clipboardData.setData ) {
		return window.clipboardData.setData( "Text", text );
	}

	if ( document.queryCommandSupported && document.queryCommandSupported( "copy" ) ) {
		const textarea          = document.createElement( "textarea" );
		textarea.textContent    = text;
		textarea.style.position = "fixed";
		document.body.appendChild( textarea );
		textarea.select();
		try {
			return document.execCommand( "copy" );
		} catch ( error ) {
			console.warn( "Copy to clipboard failed.", error );
		} finally {
			document.body.removeChild( textarea );
		}
	}
};

/**
 * Some info to help debugging
 *
 * @returns {JSXElement} The element.
 */
export default function DebugInfo() {
	if ( process.env.NODE_ENV !== "development" ) {
		return null;
	}

	const [ userIdCopied, setUserIdCopied ]           = useState( false );
	const [ accessTokenCopied, setAccessTokenCopied ] = useState( false );

	useEffect( () => {
		const timeout = setTimeout( () => {
			setUserIdCopied( false );
			setAccessTokenCopied( false );
		}, 3000 );
		return () => {
			clearTimeout( timeout );
		};
	}, [ userIdCopied, accessTokenCopied ] );


	/**
	 * Handles copying to clipboard.
	 *
	 * @param {Event} event Event type (click in this case).
	 * @param {String} valueToCopy Text to be copied.
	 * @returns {boolean|void} Nothing.
	 */
	const handleCopyToClipboard = ( event, valueToCopy ) => {
		if ( event.target.id === "copyUserId" ) {
			setUserIdCopied( true );
			setAccessTokenCopied( false );
		}

		if ( event.target.id === "copyAccessToken" ) {
			setUserIdCopied( false );
			setAccessTokenCopied( true );
		}

		return copyToClipboard( valueToCopy );
	};

	return <Card className={ styles.card }>
		<div className={ styles.debugInfoHeader }>
			<Title as="h2" size="4" className={ styles.debugInfoHeaderTitle }>
				<WrenchScrewdriverIcon className={ styles.debugInfoHeaderIcon } />
				Debug
			</Title>
		</div>
		<Card.Content>
			<ul className={ styles.debugInfoList }>
				<li>
					<p className={ styles.debugInfoListItemHeader }>
						<span>User ID</span>
						<Button
							id="copyUserId"
							variant="secondary"
							size="small"
							onClick={ event => handleCopyToClipboard( event, getUserId() ) }
						>
							{ userIdCopied ? "Copied" : "Copy" }
						</Button>
					</p>
					<p className={ styles.debugInfoListItemContent }>{ getUserId() }</p>
				</li>
				<li>
					<p className={ styles.debugInfoListItemHeader }>
						<span>Access token</span>
						<Button
							id="copyAccessToken"
							variant="secondary"
							size="small"
							onClick={ event => handleCopyToClipboard( event, getAccessToken() ) }
						>
							{ accessTokenCopied ? "Copied" : "Copy" }
						</Button>
					</p>
					<p className={ styles.debugInfoListItemContent }>{ getAccessToken() }</p>
				</li>
			</ul>
		</Card.Content>
	</Card>;
}
