import { connect } from "react-redux";

import UserProfile from "../components/UserProfile";
import { logout } from "shared-frontend/redux/actions/user";
import { getUserEmail, getUserFirstName, getUserLastName } from "shared-frontend/redux/selectors/user/data/profile";
import { getLogoutError, isLoggedIn, isLoggingOut } from "shared-frontend/redux/selectors/user/user";

/* eslint-disable require-jsdoc */
const mapStateToProps = ( state ) => {
	return {
		displayUserFirstName: getUserFirstName( state ),
		displayUserLastName: getUserLastName( state ),
		displayEmail: getUserEmail( state ),
		loggedIn: isLoggedIn( state ),
		loggingOut: isLoggingOut( state ),
		logoutError: getLogoutError( state ),
	};
};

const mapDispatchToProps = ( dispatch ) => {
	return {
		onLogoutClick: () => {
			dispatch( logout() );
		},
	};
};

const UserStatus = connect(
	mapStateToProps,
	mapDispatchToProps,
)( UserProfile );

export default UserStatus;
