import reduceReducers from "reduce-reducers";

import {
	UPDATE_SITE_URL,
	LINK_SITE_SUCCESS,
	LINK_SITE_FAILURE,
	RETRIEVE_SITES_REQUEST,
	RETRIEVE_SITES_FAILURE,
	RETRIEVE_SITES_SUCCESS,
	LINK_SITE_REQUEST,
} from "shared-frontend/redux/actions/sites";

import {
	LINK_SITE_MODAL_OPEN,
	LINK_SITE_MODAL_CLOSE,
} from "../actions/sites";

/**
 * Initial state
 */

const rootState = {
	sites: {
		// Whether or not we are currently adding a new site.
		addSiteModalOpen: false,

		// Whether or not we are currently doing a request to the server to add/link a new site.
		linkingSite: false,

		// The URL that is currently trying to be linked.
		linkingSiteUrl: "",

		// Whether or not linking of a site has failed.
		linkSiteFailed: false,

		// The error object we retrieved from the server, which contains information on why the linking of the server failed.
		linkSiteError: null,

		// Whether or not we are currently doing a request to the server to retrieve the sites.
		retrievingSites: false,

		// Whether or not the retrieving of the site has failed.
		retrievingSitesFailed: false,

		// The error message we retrieved from the server about why the retrieving of the sites failed.
		retrievingSitesError: null,

		// Whether or not the connected sites have been retrieved from the server.
		sitesRetrieved: false,
	},
};

/**
 * A reducer for the modal actions within the ui object.
 *
 * @param {Object} state The current state of the object.
 * @param {Object} action The current action received.
 *
 * @returns {Object} The updated sites object.
 */
function modalReducer( state = rootState.sites, action ) {
	switch ( action.type ) {
		case LINK_SITE_MODAL_OPEN:
			return Object.assign( {}, state, {
				addSiteModalOpen: true,
			} );
		case LINK_SITE_MODAL_CLOSE:
			return Object.assign( {}, state, {
				addSiteModalOpen: false,
				linkSiteFailed: false,
				linkSiteError: null,
				linkingSiteUrl: "",
			} );
		default:
			return state;
	}
}

/**
 * A reducer for the sites object within the ui object.
 *
 * @param {Object} state The current state of the object.
 * @param {Object} action The current action received.
 *
 * @returns {Object} The updated Sites object.
 */
export function linkReducer( state = rootState.sites, action ) {
	switch ( action.type ) {
		case UPDATE_SITE_URL:
			return Object.assign( {}, state, {
				linkingSite: true,
				linkingSiteUrl: action.url,
				linkSiteError: null,
				linkSiteSuccess: false,
			} );
		case LINK_SITE_REQUEST:
			return Object.assign( {}, state, {
				linkingSite: true,
				linkSiteSuccess: false,
			} );
		case LINK_SITE_SUCCESS:
			return Object.assign( {}, state, {
				linkSiteFailed: false,
				addSiteModalOpen: false,
				linkingSiteUrl: "",
				linkSiteError: null,
				linkSiteSuccess: true,
				linkingSite: false,
			} );
		case LINK_SITE_FAILURE:
			return Object.assign( {}, state, {
				linkSiteFailed: true,
				linkSiteError: action.error,
				linkSiteSuccess: false,
				linkingSite: false,
			} );
		default:
			return state;
	}
}

/**
 * A reducer for the sites object within the ui object.
 *
 * @param {Object} state The current state of the object.
 * @param {Object} action The current action received.
 *
 * @returns {Object} The updated Sites object.
 */
function retrieveSitesReducer( state = rootState.sites, action ) {
	switch ( action.type ) {
		case RETRIEVE_SITES_REQUEST:
			return Object.assign( {}, state, {
				retrievingSites: true,
			} );
		case RETRIEVE_SITES_SUCCESS:
			return Object.assign( {}, state, {
				retrievingSites: false,
				retrievingSitesFailed: false,
				sitesRetrieved: true,
				retrievingSitesError: null,
			} );
		case RETRIEVE_SITES_FAILURE:
			return Object.assign( {}, state, {
				retrievingSites: false,
				retrievingSitesFailed: true,
				retrievingSitesError: action.error,
			} );
		default:
			return state;
	}
}

const uiSites = reduceReducers( linkReducer, retrieveSitesReducer, modalReducer );

/**
 * A reducer for the sites object within the ui object.
 *
 * @param {Object} state The current state of the object.
 * @param {Object} action The current action received.
 *
 * @returns {Object} The updated Sites object.
 */
export function uiSitesReducer( state = rootState.sites, action ) {
	return uiSites( state, action );
}
