/* External dependencies */
import { createSelector } from "reselect";
import _groupBy from "lodash/groupBy";
import _maxBy from "lodash/maxBy";
import _sortBy from "lodash/sortBy";
import _includes from "lodash/includes";
/* Internal dependencies */
import { createAllOfEntitySelector } from "./factories";
import { getCourses, getCoursesById } from "./courses";
import { getUserProfile } from "./user";
import { getAllSubscriptionsById } from "./subscriptions";

/**
 * Returns all subscriptions in the state.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} All subscriptions.
 */
export const getCourseEnrollments = createAllOfEntitySelector( "courseEnrollments" );

export const getGroupedCourseEnrollments = createSelector(
	[ getCourseEnrollments, getCourses, getUserProfile, getCoursesById ],
	( courseEnrollments, courses, profile, coursesById ) => {
		if ( ! courses || courses.length === 0 ) {
			return [];
		}

		courseEnrollments = courseEnrollments.filter( enrollment => enrollment.status !== "refunded" );
		const courseEnrollmentsByIdentifier = _groupBy( courseEnrollments, e => e.lineItemId ? `${ e.lineItemId }:${ e.lineItemNumber }` : e.id );

		let groupedCourseEnrollments = Object.keys( courseEnrollmentsByIdentifier ).map( ( identifier ) => {
			const enrollments = courseEnrollmentsByIdentifier[ identifier ];
			const isGrouped = enrollments.length > 1;
			const firstEnrollment = enrollments[ 0 ];
			const buyerEmail = firstEnrollment.buyer ? firstEnrollment.buyer.userEmail : "";
			const buyerName = firstEnrollment.buyer ? [ firstEnrollment.buyer.userFirstName, firstEnrollment.buyer.userLastName ].join( " " ) : "";
			// eslint-disable-next-line max-len
			const studentName = firstEnrollment.student ? [ firstEnrollment.student.userFirstName, firstEnrollment.student.userLastName ].join( " " ) : "";
			const studentEmail = firstEnrollment.student ? firstEnrollment.student.userEmail : "";
			const icon = ( isGrouped && firstEnrollment.product ) ? firstEnrollment.product.icon : "";
			const outsideTrialProgress = enrollments.some( ( enrollment ) => enrollment.outsideTrialProgress );
			const isTrial = enrollments.some( ( enrollment ) => enrollment.isTrial );
			const progress = _maxBy( enrollments, "progress" ) || 0;
			const status = enrollments.some( ( enrollment ) => enrollment.status === "started" ) ? "started" : "not started";
			const firstCourse = coursesById[ firstEnrollment.courseId ];

			let courseName;
			if ( isGrouped && firstEnrollment.product ) {
				courseName = firstEnrollment.product.name;
			} else if ( enrollments.length >= 8 ) {
				courseName = "Training Subscription";
			} else {
				courseName = firstCourse.name.replace( /\s\((block|classic) editor\)/, "" );
			}

			return {
				icon,
				id: isGrouped ? `bulk:${ identifier }` : `individual:${ firstEnrollment.id }`,
				progress,
				courseId: isGrouped ? " grouped" : firstCourse.id,
				courseName,
				buyerEmail,
				buyerName,
				buyerId: firstEnrollment.buyerId,
				studentId: firstEnrollment.studentId,
				status,
				studentEmail,
				studentName,
				isTrial,
				outsideTrialProgress,
			};
		} );

		const freeEnrollments = courses
			.filter( course => course.open && groupedCourseEnrollments.every( enrollment => enrollment.courseId !== course.id ) )
			.map( course => {
				const icon = course.products[ 0 ] ? course.products[ 0 ].icon : "";

				return {
					// EnrollmentId is not unique across users.
					id: "free-course:" + course.id,
					progress: 0,
					courseId: course.id,
					courseName: course.name,
					icon,
					buyerId: "",
					buyerEmail: "",
					buyerName: "",
					status: "not started",
					studentEmail: profile.email,
					studentId: profile.userId,
					studentName: [ profile.userFirstName, profile.userLastName ].join( " " ),
				};
			} );

		groupedCourseEnrollments = _sortBy( groupedCourseEnrollments, "courseId" );
		return groupedCourseEnrollments.concat( freeEnrollments );
	},
);

/**
 * Get all the courses associated to a subscription.
 *
 * @param {Object} state 			Application state.
 * @param {string} subscriptionId 	The subscription id.
 *
 * @returns {Array} 				An array of courseEnrollments.
 */
export function getCoursesFromSubscription( state, subscriptionId ) {
	const subscription = getAllSubscriptionsById( state )[ subscriptionId ];
	return getCourseEnrollments( state ).filter(
		enrollment =>
			_includes( subscription.orders, enrollment.orderId ) &&
			enrollment.studentId );
}
