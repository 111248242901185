import { connect } from "react-redux";

import ProfilePage from "../components/ProfilePage";
import { isOnlyProvisionerSubscriptions } from "../redux/selectors/ui/subscriptions";

import {
	disableUser,
	resetSaveMessage,
	updatePassword,
	updateProfile,
} from "shared-frontend/redux/actions/user";
import {
	createComposerToken,
	deleteComposerToken,
	fetchComposerTokens,
	renameComposerToken,
} from "shared-frontend/redux/actions/composerTokens";
import {
	createTokenModalClosed,
	createTokenModalOpen,
	manageTokenModalClosed,
	manageTokenModalOpen,
} from "../redux/actions/composerTokens";
import {
	getUserEmail,
	getUserFirstName,
	getUserLastName,
} from "shared-frontend/redux/selectors/user/data/profile";
import { getComposerTokens } from "shared-frontend/redux/selectors/entities/composerTokens";
import {
	getManageTokenData,
	getTokenError,
	isCreateTokenModalIsOpen,
	isManageTokenModalIsOpen,
	isTokenDeleted,
} from "../redux/selectors/ui/composerTokens";
import {
	getSaveEmailError,
	isProfilePageDeleting,
	isProfilePageSaved,
	isProfilePageSaving,
} from "shared-frontend/redux/selectors/user/profilePage";
import {
	getPasswordResetError,
	isPasswordResetSending,
	isPasswordResetSent,
} from "shared-frontend/redux/selectors/user/passwordReset";

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state ) => {
	return {
		email: getUserEmail( state ),
		userFirstName: getUserFirstName( state ),
		userLastName: getUserLastName( state ),
		isOnlyProvisionerSubscriptions: isOnlyProvisionerSubscriptions( state ),

		composerTokens: getComposerTokens( state ),

		isSaving: isProfilePageSaving( state ),
		isSaved: isProfilePageSaved( state ),
		isDeleting: isProfilePageDeleting( state ),
		saveEmailError: getSaveEmailError( state ),

		isSavingPassword: isPasswordResetSending( state ),
		passwordIsSaved: isPasswordResetSent( state ),
		passwordResetError: getPasswordResetError( state ),

		createTokenModalIsOpen: isCreateTokenModalIsOpen( state ),
		manageTokenModalIsOpen: isManageTokenModalIsOpen( state ),
		manageTokenData: getManageTokenData( state ),
		tokenError: getTokenError( state ),
		tokenDeleted: isTokenDeleted( state ),
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	dispatch( fetchComposerTokens() );

	return {
		onSaveProfile: ( profile ) => {
			dispatch( updateProfile( profile ) );
		},
		onSavePassword: ( password ) => {
			dispatch( updatePassword( password ) );
		},
		resetSaveMessage: () => {
			dispatch( resetSaveMessage() );
		},
		onDeleteProfile: () => {
			// eslint-disable-next-line
			if ( window.confirm( "WARNING! This action CANNOT be undone.\n\n" +
				"If you continue, you will lose access to your downloads and will no longer receive updates to" +
				" the premium plugins you've bought from Yoast.\n\nAre you sure you want to delete your Yoast account?" ) ) {
				dispatch( disableUser() );
			}
		},
		onCreateTokenModalOpen: () => {
			dispatch( createTokenModalOpen() );
		},
		onCreateTokenModalClose: () => {
			dispatch( createTokenModalClosed() );
		},
		onCreateTokenClick: ( data ) => {
			dispatch( createComposerToken( data ) );
		},
		onManageTokenClick: ( data ) => {
			dispatch( manageTokenModalOpen( data ) );
		},
		onManageTokenModalClose: () => {
			dispatch( manageTokenModalClosed() );
		},
		onSaveTokenClick: ( data ) => {
			dispatch( renameComposerToken( data.id, data.name ) );
		},
		onDeleteTokenClick: ( data ) => {
			dispatch( deleteComposerToken( data ) );
		},
	};
};

export const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
	return Object.assign( {}, ownProps, stateProps, dispatchProps, {} );
};

const ProfilePageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	mergeProps,
)( ProfilePage );

export default ProfilePageContainer;
