import _union from "lodash/union";

/**
 * A reducer for fetching a user's composer tokens.
 *
 * @param {Object} state The previous state of the store.
 * @param {Object} action The action that just occurred.
 * @returns {Object} The new state of the store.
 */
import {
	CREATE_COMPOSER_TOKEN_SUCCESS,
	FETCH_COMPOSER_TOKENS_SUCCESS,
	RENAME_COMPOSER_TOKEN_SUCCESS,
	DELETE_COMPOSER_TOKEN_SUCCESS,
} from "../actions/composerTokens";
import { LOGOUT_SUCCESS } from "../actions/user";

/**
 * Initial state
 */
const initialState = {
	composerTokens: {
		byId: {},
		allIds: [],
	},
};

/**
 * Reducers
 */

/**
 * A reducer for the byIdComposerTokensReducer list.
 *
 * @param {Object} state The current state of the byIdComposerTokensReducer list.
 * @param {Object} action The current action received.
 * @returns {Object} The updated byIdComposerTokensReducer object.
 */
export function byIdComposerTokensReducer( state = initialState.composerTokens.byId, action ) {
	let composerTokens;

	switch ( action.type ) {
		case FETCH_COMPOSER_TOKENS_SUCCESS:
			composerTokens = Object.assign( {}, state );

			action.composerTokens.forEach( ( order ) => {
				composerTokens[ order.id ] = order;
			} );

			return composerTokens;
		case CREATE_COMPOSER_TOKEN_SUCCESS:
		case RENAME_COMPOSER_TOKEN_SUCCESS:
		case DELETE_COMPOSER_TOKEN_SUCCESS:
			composerTokens = Object.assign( {}, state );

			composerTokens[ action.composerToken.id ] = action.composerToken;

			return composerTokens;
		case LOGOUT_SUCCESS:
			return initialState.composerTokens.byId;
		default:
			return state;
	}
}

/**
 * A reducer for the allIdsComposerTokensReducer list.
 *
 * @param {Array} state The current state of the allIdsComposerTokensReducer list.
 * @param {Object} action The current action received.
 * @returns {Array} The updated allIdsComposerTokensReducer array.
 */
export function allIdsComposerTokensReducer( state = initialState.composerTokens.allIds, action ) {
	switch ( action.type ) {
		case FETCH_COMPOSER_TOKENS_SUCCESS:
			return _union( state, action.composerTokens.map( order => order.id ) );
		case CREATE_COMPOSER_TOKEN_SUCCESS:
		case RENAME_COMPOSER_TOKEN_SUCCESS:
		case DELETE_COMPOSER_TOKEN_SUCCESS:
			return _union( state, [ action.composerToken.id ] );
		case LOGOUT_SUCCESS:
			return initialState.composerTokens.allIds;
		default:
			return state;
	}
}
