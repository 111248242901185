/**
	 * Strips common wrong subdirectories from the selectedSite url.
	 *
	 * @param {string} url The url string.
	 *
	 * @returns {Object} 1. The stripped url {string} 2. The last stripped subdirectory {String}
	 */
export default function stripCommonWrongSubdirectories( url ) {
	const commonWrongSubdirectories = [ /wp-admin/ ];
	let strippedUrl;
	const strippedSubdirectories = [];
	commonWrongSubdirectories.forEach( subdirectory => {
		strippedUrl = url.split( subdirectory );
		if ( strippedUrl.length > 1 ) {
			const subdirectoryAsString = subdirectory.toString().substring( 1, subdirectory.toString().length - 1 );
			strippedSubdirectories.push( subdirectoryAsString );
			url = strippedUrl[ 0 ];
		}
	} );
	return { url: strippedUrl[ 0 ], strippedSubdirectory: strippedSubdirectories[ 0 ]  };
}
