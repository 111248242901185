import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { Button, Table } from "@yoast/ui-library";
import React, { useState } from "react";
import styles from "./styles.scss";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { messages } from "./messages";
import SubscriptionRow from "./SubscriptionRow";

/**
 * When multiple subscriptions for the same product are present, this component will render a collapsible row.
 * The row shows which product the subscriptions are for and allows the user to expand the row to see the individual subscriptions.
 *
 * @param {Object} props Properties of the component.
 * @returns {Element} CollapsibleSubscriptionsRow component.
 * @constructor
 */
const CollapsibleSubscriptionsRow = ( props ) => {
	const [ isOpen, setIsOpen ] = useState( false );

	const icon = props.subscriptionsArray[ 0 ].icon;
	const name = props.subscriptionsArray[ 0 ].name;

	const threeEmptyColumns = <>
		<Table.Cell className={styles.tableUsage}>
			<div />
		</Table.Cell>
		<Table.Cell className={styles.tableRenewal}>
			<div />
		</Table.Cell>
		<Table.Cell className={styles.tableBilling}>
			<div />
		</Table.Cell>
	</>;

	return <>
		<Table.Row className={ styles.collapsibleRow } onClick={ ( () => setIsOpen( ! isOpen ) ) }>
			<Table.Cell>
				<div className={ styles.collapsibleRowInfo }>
					<div className={ styles.subscriptionIcon }>
						<img src={ icon } alt="" />
					</div>
					<span className={ styles.subscriptionName }>{ name }</span>
				</div>
			</Table.Cell>
			{ ! props.isProvisioned && threeEmptyColumns }
			<Table.Cell>
				<Button
					className={ styles.expandButton }
					onClick={ () => {
						setIsOpen( ! isOpen );
					} }
					aria-label={ props.intl.formatMessage( isOpen ? messages.hideSubscriptions : messages.showSubscriptions ) }
				>
					<span className={ styles.buttonIcon }>
						{ isOpen ? <ChevronUpIcon /> : <ChevronDownIcon /> }
					</span>
				</Button>
			</Table.Cell>
		</Table.Row>
		{
			isOpen && props.subscriptionsArray.map( ( subscription ) => {
				return <SubscriptionRow
					key={ subscription.id }
					subscriptionsArray={ [ subscription ] }
					provisionerData={ props.provisionerData }
					onManage={ props.onManage }
					isProvisioned={ props.isProvisioned }
					needsAttention={ props.needsAttention }
					isInactive={ props.isInactive }
					showDetailsModal={ props.showDetailsModal }
					isPartOfCollapsible={ true }
				/>;
			} )
		}
	</>;
};


CollapsibleSubscriptionsRow.propTypes = {
	onManage: PropTypes.func.isRequired,
	subscriptionsArray: PropTypes.array.isRequired,
	provisionerData: PropTypes.object,
	intl: intlShape.isRequired,
	needsAttention: PropTypes.bool,
	isProvisioned: PropTypes.bool,
	isInactive: PropTypes.bool,
	showDetailsModal: PropTypes.func,
};

CollapsibleSubscriptionsRow.defaultProps = {
	provisionerData: {},
	showDetailsModal: null,
	isProvisioned: false,
	needsAttention: false,
	isInactive: false,
};

export default injectIntl( CollapsibleSubscriptionsRow );
