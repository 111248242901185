import { search } from "../../functions/search";
import { getMyYoastHost, handleJSONResponse } from "../../functions/api";

const host = getMyYoastHost();

export const GET_SEARCH_ROLES_SEND = "GET_SEARCH_ROLES_SEND";
export const GET_SEARCH_ROLES_SUCCESS = "GET_SEARCH_ROLES_SUCCESS";
export const GET_SEARCH_ROLES_FAILURE = "GET_SEARCH_ROLES_FAILURE";

export const GET_ALL_ROLES_SEND = "GET_ALL_ROLES_SEND";
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";
export const GET_ALL_ROLES_FAILURE = "GET_ALL_ROLES_FAILURE";

export const GET_ALL_CUSTOMERS_WITH_ROLE_SEND = "GET_ALL_CUSTOMERS_WITH_ROLE_SEND";
export const GET_ALL_CUSTOMERS_WITH_ROLE_SUCCESS = "GET_ALL_CUSTOMERS_WITH_ROLE_SUCCESS";
export const GET_ALL_CUSTOMERS_WITH_ROLE_FAILURE = "GET_ALL_CUSTOMERS_WITH_ROLE_FAILURE";

export const FETCH_CUSTOMER_IDS_WITH_ROLE_SEND = "FETCH_CUSTOMER_IDS_WITH_ROLE_SEND";
export const FETCH_CUSTOMER_IDS_WITH_ROLE_SUCCESS = "FETCH_CUSTOMER_IDS_WITH_ROLE_SUCCESS";
export const FETCH_CUSTOMER_IDS_WITH_ROLE_FAILURE = "FETCH_CUSTOMER_IDS_WITH_ROLE_FAILURE";

export const SET_ROLES_FOR_USER_SEND = "SET_ROLES_FOR_USER_SEND";
export const SET_ROLES_FOR_USER_SUCCESS = "SET_ROLES_FOR_USER_SUCCESS";
export const SET_ROLES_FOR_USER_FAILURE = "SET_ROLES_FOR_USER_FAILURE";


/**
 * An action creator that updates the reducer that the request will be send.
 *
 * @returns {Object} The action
 */
const getSearchRoles = () => {
	return {
		type: GET_SEARCH_ROLES_SEND,
	};
};

/**
 * An action creator that updates the reducer that the request succeeded.
 *
 * @param {Object} responseData Data from the response.
 *
 * @returns {Object} The action
 */
const getSearchRolesSuccess = responseData => {
	return {
		type: GET_SEARCH_ROLES_SUCCESS,
		searchResults: responseData.length === 0 ? [] : responseData,
	};
};

/**
 * An action creator that updates the reducer that the request failed.
 *
 * @returns {Object} The action
 */
const getSearchRolesFailure = () => {
	return {
		type: GET_SEARCH_ROLES_FAILURE,
	};
};

/**
 * Sends a role search request to the search endpoint
 *
 * @param {Object} query The search query.
 *
 * @returns {void}
 */
export const searchRoles = query => async( dispatch, getState ) => {
	if ( query === "" ) {
		return;
	}
	dispatch( getSearchRoles() );
	const accessToken = getState().user.accessToken;
	try {
		const like = { like: query, options: "i" };
		const responseData = await search(
			"Customers",
			{
				where: { or: [ { email: like }, { userEmail: like }, { username: like }, { userFirstName: like }, { userLastName: like } ] },
				include: "roles", limit: 500,
			}, accessToken );

		dispatch( getSearchRolesSuccess( responseData ) );
	} catch ( error ) {
		dispatch( getSearchRolesFailure() );
	}
};


/**
 * An action creator that updates the reducer that the request will be send.
 *
 * @returns {Object} The action
 */
const getAllRolesSend = () => {
	return {
		type: GET_ALL_ROLES_SEND,
	};
};

/**
 * An action creator that updates the reducer that the request succeeded.
 *
 * @param {Object} roles T
 *
 * @returns {Object} The action
 */
const getAllRolesSuccess = roles => {
	return {
		type: GET_ALL_ROLES_SUCCESS,
		roles: roles,
	};
};


/**
 * An action creator that updates the reducer that the request failed.
 *
 * @returns {Object} The action
 */
const getAllRolesFailure = () => {
	return {
		type: GET_ALL_ROLES_FAILURE,
	};
};

/**
 * Retrieves all the possible roles that can be assigned to users.
 *
 * @returns {Promise} A promise resolving to an array with key/value pairs of possible roles and their ids.
 */
export const getAllRoles = () => async( dispatch, getState ) => {
	dispatch( getAllRolesSend() );
	const accessToken = getState().user.accessToken;
	const url = `${host}/api/Customers/allroles?access_token=${ accessToken }`;

	try {
		const rawResponse = await fetch( url, { method: "GET" } );
		const response = await handleJSONResponse( rawResponse );

		dispatch( getAllRolesSuccess( response ) );
	} catch ( error ) {
		dispatch( getAllRolesFailure() );
	}
};

/**
 * An action creator that updates the reducer that the request will be send.
 *
 * @returns {Object} The action
 */
const getAllCustomersWithRoleSend = () => {
	return {
		type: GET_ALL_CUSTOMERS_WITH_ROLE_SEND,
	};
};

/**
 * An action creator that updates the reducer that the request succeeded.
 *
 * @param {Object} customers T
 *
 * @returns {Object} The action
 */
const getAllCustomersWithRoleSuccess = customers => {
	return {
		type: GET_ALL_CUSTOMERS_WITH_ROLE_SUCCESS,
		customers,
	};
};

/**
 * An action creator that updates the reducer that the request failed.
 *
 * @returns {Object} The action
 */
const getAllCustomersWithRoleFailure = () => {
	return {
		type: GET_ALL_CUSTOMERS_WITH_ROLE_FAILURE,
	};
};


/**
 * Updates the state with all the customers in the supplied array and their role.
 *
 * @param {Array} customerIds Array with customer id's.
 *
 * @returns {void}
 */
export const getAllCustomersWithRole = customerIds => async( dispatch, getState ) => {
	dispatch( getAllCustomersWithRoleSend() );
	const accessToken = getState().user.accessToken;

	try {
		const customers = await search( "Customers", { where: { id: { inq: customerIds } }, include: "roles" }, accessToken );

		dispatch( getAllCustomersWithRoleSuccess( customers ) );
	} catch ( error ) {
		dispatch( getAllCustomersWithRoleFailure() );
	}
};

/**
 * An action creator that updates the reducer that the request will be send.
 *
 * @returns {Object} The action
 */
const fetchCustomerIdsWithRoleSend = () => {
	return {
		type: FETCH_CUSTOMER_IDS_WITH_ROLE_SEND,
	};
};

/**
 * An action creator that updates the reducer that the request succeeded.
 *
 * @param {Object} customersIds An array with customer id's
 *
 * @returns {Object} The action
 */
const fetchCustomerIdsWithRoleSuccess = customersIds => {
	return {
		type: FETCH_CUSTOMER_IDS_WITH_ROLE_SUCCESS,
		customersIds,
	};
};

/**
 * An action creator that updates the reducer that the request failed.
 *
 * @returns {Object} The action
 */
const fetchCustomerIdsWithRoleFailure = () => {
	return {
		type: FETCH_CUSTOMER_IDS_WITH_ROLE_FAILURE,
	};
};


/**
 * Retrieves all Customers that have a role set.
 *
 * @returns {Promise} A promise resolving to an array with a customer id for each roleMapping set.
 */
export const fetchCustomerIdsWithRole = () => async( dispatch, getState ) => {
	dispatch( fetchCustomerIdsWithRoleSend() );
	const accessToken = getState().user.accessToken;
	const url = `${host}/api/Customers/rolemapping-ids?access_token=${accessToken}`;

	try {
		const rawResponse = await fetch( url, { method: "GET" } );
		const customerIds = await handleJSONResponse( rawResponse );

		dispatch( fetchCustomerIdsWithRoleSuccess( customerIds ) );
	} catch ( error ) {
		dispatch( fetchCustomerIdsWithRoleFailure() );
	}
};

/**
 * An action creator that updates the reducer that the request will be send.
 *
 * @param {string} userId the id of a user.
 *
 * @returns {Object} The action
 */
const setRolesForUserSend = userId => {
	return {
		type: SET_ROLES_FOR_USER_SEND,
		userId,
	};
};

/**
 * An action creator that updates the reducer that the request succeeded.
 *
 * @param {Object} customerWithRoles A user object with included roles.
 *
 * @returns {Object} The action
 */
const setRolesForUserSuccess = customerWithRoles => {
	return {
		type: SET_ROLES_FOR_USER_SUCCESS,
		customerWithRoles,
	};
};

/**
 * An action creator that updates the reducer that the request failed.
 *
 * @param {string} userId The id of a user.
 *
 * @returns {Object} The action
 */
const setRolesForUserFailure = userId => {
	return {
		type: SET_ROLES_FOR_USER_FAILURE,
		userId,
	};
};

/**
 * Creates a new rolemapping between a customer and a role.
 *
 * @param {number} userId The customer id to assign a role to.
 * @param {number} roleId The role id to map to the customer.
 *
 * @returns {Function} The addNewCustomerRole action.
 */
export const addNewCustomerRole = ( userId, roleId ) => async( dispatch, getState ) => {
	const accessToken = getState().user.accessToken;
	const url = `${host}/api/Customers/${userId}/roleMapping?access_token=${accessToken}`;

	dispatch( setRolesForUserSend( userId ) );

	try {
		const rawResponse = await fetch(
			url,
			{
				method: "POST",
				headers: { Accept: "application/json", "Content-Type": "application/json" },
				body: JSON.stringify( { roleId } ),
			} );
		const customerWithRoles = await handleJSONResponse( rawResponse );

		dispatch( setRolesForUserSuccess( customerWithRoles ) );
	} catch ( error ) {
		dispatch( setRolesForUserFailure( userId ) );
	}
};

/**
 * Creates a new rolemapping between a customer and a role.
 *
 * @param {number} userId The customer id to assign a role to.
 * @param {number} roleId The role id to map to the customer.
 *
 * @returns {Promise} A promise resolving to an object with the roleMapping row data.
 */
export const deleteCustomerRole = ( userId, roleId ) => async( dispatch, getState ) => {
	const accessToken = getState().user.accessToken;
	const url = `${host}/api/Customers/${userId}/roleMapping?access_token=${accessToken}`;

	dispatch( setRolesForUserSend( userId ) );

	try {
		const rawResponse = await fetch(
			url,
			{
				method: "DELETE",
				headers: { Accept: "application/json", "Content-Type": "application/json" },
				body: JSON.stringify( { roleId } ),
			} );
		const customerWithRoles = await handleJSONResponse( rawResponse );

		dispatch( setRolesForUserSuccess( customerWithRoles ) );
	} catch ( error ) {
		dispatch( setRolesForUserFailure( userId ) );
	}
};

/**
 * Sets the selected roles for a userId.
 *
 * @param {string} userId The user's id.
 * @param {Array} selectedRoles The roles that the user selected.
 * @param {Array} currentRoles The roles that the user currently has.
 *
 * @returns {Function} The SetRolesForUser action which adds and/or removes roles.
 */
export const setRolesForUser = ( userId, selectedRoles, currentRoles ) => async( dispatch, getState ) => {
	const allRoles = getState().entities.roleManagement.allRoles;
	const selectedRolesArray = selectedRoles.map( option => option.value );

	// Get the roles that need to be added.
	selectedRolesArray.forEach( ( role ) => {
		if ( ! currentRoles.includes( role ) ) {
			dispatch( addNewCustomerRole( userId, allRoles[ role ] ) );
		}
	} );

	// Get the roles that need to be deleted.
	currentRoles.forEach( ( role ) => {
		if ( ! selectedRolesArray.includes( role ) ) {
			dispatch( deleteCustomerRole( userId, allRoles[ role ] ) );
		}
	} );
};
