import { connect } from "react-redux";
import { push } from "connected-react-router";
import groupBy from "lodash/groupBy";
import _isEmpty from "lodash/isEmpty";
import _flow from "lodash/flow";
import _differenceBy from "lodash/differenceBy";
import { orderBy } from "lodash/collection";

import { onSearchQueryChange } from "../redux/actions/search";
import { getAllSubscriptions } from "shared-frontend/redux/actions/subscriptions";
import SubscriptionsPage from "../components/SubscriptionsPage";
import { getOrders } from "shared-frontend/redux/actions/orders";
import { getSearchQuery } from "shared-frontend/redux/selectors/entities/search";
import { getAllOfEntity } from "shared-frontend/redux/selectors/entities/factories";
import { getAllProvisionerData } from "shared-frontend/redux/actions/provisionerData";
import { formatDate } from "shared-frontend/functions/dateHelper";

/**
 * Maps a subscription to the props of a subscription.
 *
 * @param   {Object} subscription Subscription in the state
 * @returns {Object}              Subscription for the component.
 */
function mapSubscriptionToProps( subscription ) {
	const hasSites = ! (
		subscription.product.productGroups.length === 1 &&
		subscription.product.productGroups[ 0 ].slug === "all-courses"
	);

	return {
		id: subscription.id,
		icon: subscription.product.icon,
		name: subscription.name,
		used: subscription.used,
		limit: subscription.limit,
		provisionerId: subscription.provisionerId,
		subscriptionNumber: subscription.subscriptionNumber,
		requiresManualRenewal: subscription.requiresManualRenewal,
		hasNextPayment: subscription.nextPayment !== null,
		nextPayment: new Date( subscription.nextPayment ),
		hasEndDate: subscription.endDate !== null,
		endDate: new Date( subscription.endDate ),
		billingAmount: subscription.price,
		billingCurrency: subscription.currency,
		status: subscription.status,
		hasSites,
		product: subscription.product || {},
		renewalSecret: subscription.renewalSecret,
		startDate: new Date( subscription.startDate ),
	};
}

/**
 * Filters a list of subscriptions based on the given search query.
 *
 * @param   {string} query         The typed search query.
 * @param   {Array}  subscriptions Given subscriptions already filtered by mapSubscriptionToProps
 * @returns {Array}                The filtered list of subscriptions.
 */
function filterSubscriptionsByQuery( query, subscriptions ) {
	if ( query.length < 1 ) {
		return subscriptions;
	}
	return subscriptions.filter( ( subscription ) => {
		const formattedDate = formatDate( subscription.nextPayment );

		return subscription.name.toUpperCase().includes( query.toUpperCase() ) ||
			subscription.limit.toString() === query ||
			subscription.used.toString() === query ||
			subscription.subscriptionNumber.toUpperCase().includes( query ) ||
			formattedDate.toUpperCase().includes( query.toUpperCase() ) ||
			(
				subscription.billingAmount / 100
			).toString().includes( query.toUpperCase() );
	} );
}

/**
 * Groups subscriptions into an object, behind a key that is the subscription's product's glNumber.
 *
 * @param   {Object}  subscriptionsByType The subscriptions that should be grouped.
 * @returns {Object}                      An object with subscription type as key,
 *                                        and all subscriptions belonging to that type,
 *                                        grouped in an object with 'glNumber-term' as keys.
 */
function groupSubscriptionsByProduct( subscriptionsByType ) {
	const subscriptionTypes = Object.keys( subscriptionsByType );
	return subscriptionTypes.reduce(
		( groupedObject, key ) => {
			groupedObject[ key ] = groupBy(
				subscriptionsByType[ key ],
				subscription => ( subscription.product.glNumber + "-" + subscription.product.billingTerm ),
			);
			return groupedObject;
		},
		{},
	);
}

/**
 * Determines whether to show the manage button for the subscription. As we don't want always want to provide access to the downloads.
 *
 * @param   {Array} subscriptions The subscriptions that will be displayed.
 *
 * @returns {Array}               The subscriptions with the property whether to show the manage button.
 */
function showManageButton( subscriptions ) {
	return subscriptions.map( subscription => {
		subscription.showManageButton = [ "active", "pending-cancel" ].includes( subscription.status );
		subscription.showManageButton = subscription.showManageButton && _isEmpty( subscription.provisionerId );
		return subscription;
	} );
}

/**
 * Sorts a list of subscriptions by either the nextPaymentDate (for WooCommerce subscriptions),
 * or endDate (in the case of EDD subscriptions that are active).
 *
 * @param   {Array} subscriptions Given subscriptions already filtered by mapSubscriptionToProps
 *
 * @returns {Array}               The sorted list of subscriptions.
 */
function sortByUpcomingPayment( subscriptions ) {
	return subscriptions
		.map( ( subscription ) => {
			let paymentDate;
			if ( subscription.hasEndDate && subscription.status === "active" ) {
				paymentDate = subscription.endDate;
			} else {
				paymentDate = subscription.nextPayment;
			}
			subscription.paymentDate = paymentDate;
			return subscription;
		} )
		.sort( ( a, b ) => a.paymentDate - b.paymentDate );
}

/**
 * Function to check if the date is within one month from now
 * @param { Date } date Contains the date that needs to be checked
 * @returns {boolean} True if date is within one month from now, false otherwise.
 */
function dateWithinOneMonth( date ) {
	const currentDate = new Date();
	currentDate.setMonth( currentDate.getMonth() + 1 );
	return date.getTime() <= currentDate.getTime();
}

/**
 * Function to check if the date is outside one month from now
 * @param { Date } date Contains the date that needs to be checked
 * @returns {boolean} True if date is outside one month from now, false otherwise.
 */
function dateOutsideOneMonth( date ) {
	const currentDate = new Date();
	currentDate.setMonth( currentDate.getMonth() - 1 );
	return currentDate.getTime() >= date.getTime();
}

/**
 * Returns true if the status is active
 *
 * @param   { Object }  subscription The subscription
 * @returns { boolean }              True if subscription.status is "active", false otherwise
 */
function isActive( subscription ) {
	return subscription.status === "active";
}

/**
 * Returns true if the status is active
 *
 * @param   { Object }  subscription The subscription
 * @returns { boolean }              True if subscription.status is "active", false otherwise
 */
function isPendingCancel( subscription ) {
	return subscription.status === "pending-cancel";
}

/**
 * Returns true if the status is on-hold
 *
 * @param   { Object }  subscription The subscription
 * @returns { boolean }              True if subscription.status is "on-hold", false otherwise
 */
function isOnHold( subscription ) {
	return subscription.status === "on-hold";
}

/**
 * Returns true if the status is expired, false otherwise
 * @param   { Object }  subscription The subscription
 * @returns { boolean }              True if subscription.status is "expired", false otherwise
 */
function isExpired( subscription ) {
	return subscription.status === "expired";
}

/**
 * Returns true if the status is cancelled, false otherwise
 * @param   { Object }  subscription The subscription
 * @returns { boolean }              True if subscription status is "cancelled", false otherwise
 */
function isCancelled( subscription ) {
	return subscription.status === "cancelled";
}

/**
 * Returns true if the status is cancelled, false otherwise
 * @param   { Object }  subscription The subscription
 * @returns { boolean }              True if subscription status is "cancelled", false otherwise
 */
function isRefunded( subscription ) {
	return subscription.status === "refunded";
}

/**
 * Checks if the subscription is provisioned.
 *
 * @param {Subscription} subscription The subscription to check.
 *
 * @returns {boolean} True if provisioned, false otherwise.
 */
function isProvisioned( subscription ) {
	return ! _isEmpty( subscription.provisionerId );
}

/**
 * Returns true if the status is active and the subscription needs to be renewed manually within a month.
 *
 * @param   { Object }  subscription The subscription
 * @returns { boolean }              See above.
 */
function shouldBeManuallyRenewedWithinMonth( subscription ) {
	return ( subscription.hasNextPayment &&
		isActive( subscription ) &&
		dateWithinOneMonth( subscription.nextPayment ) &&
		subscription.requiresManualRenewal
	);
}

/**
 * Returns true if the status is active and the subscription is ending within a month.
 *
 * @param   { Object }  subscription The subscription
 * @returns { boolean }              See above.
 */
function endsWithinMonth( subscription ) {
	return ( subscription.hasEndDate &&
		isActive( subscription ) &&
		dateWithinOneMonth( subscription.endDate )
	);
}

/**
 * Returns true if the status [on-hold, cancelled, expired] and the subscription ended outside of month.
 *
 * @param   { Object }  subscription The subscription
 * @returns { boolean }              See above.
 */
function expiredForLongerThanMonth( subscription ) {
	if (
		isExpired( subscription ) &&
		subscription.hasEndDate &&
		dateOutsideOneMonth( subscription.endDate )
	) {
		return true;
	}

	if ( isCancelled( subscription ) || isRefunded( subscription ) ) {
		return true;
	}

	return false;
}

/**
 * Function that returns whether or not a subscription needs attention
 * @param { subscription } subscription A subscription object
 * @returns { boolean } True when the subscription needs attention, False otherwise
 */
function needsAttention( subscription ) {
	if ( _isEmpty( subscription.renewalSecret ) ) {
		return false;
	}

	if ( isProvisioned( subscription ) ) {
		return false;
	}

	if ( expiredForLongerThanMonth( subscription ) ) {
		return false;
	}

	const pastNeedsAttention   = isOnHold( subscription ) || isExpired( subscription );
	const futureNeedsAttention = shouldBeManuallyRenewedWithinMonth( subscription ) || endsWithinMonth( subscription );

	return ( pastNeedsAttention || futureNeedsAttention );
}

/**
 * Function that returns whether a subscription is inactive.
 * @param { subscription } subscription A subscription object
 * @returns { boolean } True when the subscription is inactive, False otherwise
 */
function inactiveSubscription( subscription ) {
	if ( isProvisioned( subscription ) ) {
		return false;
	}

	return expiredForLongerThanMonth( subscription );
}

/**
 * Transforms the array of subscriptions to an object in which the array is split according to type.
 * @param {Array}   subscriptions The array to be transformed.
 * @returns {Object}               The object with the array split according to type.
 */
function splitSubscriptionsByType( subscriptions ) {
	const needsAttentionSubscriptions = subscriptions.filter( needsAttention );
	const regularSubscriptions        = _differenceBy( subscriptions, needsAttentionSubscriptions, sub => sub.id );
	const provisionedSubscriptions    = regularSubscriptions.filter( isProvisioned );
	const remainingSubscriptions      = _differenceBy( regularSubscriptions, provisionedSubscriptions, sub => sub.id );
	const inactiveSubscriptions       = subscriptions.filter( inactiveSubscription );

	return {
		needsAttentionSubscriptions: needsAttentionSubscriptions,
		provisionedSubscriptions: provisionedSubscriptions,
		regularSubscriptions: remainingSubscriptions.filter( sub => ( isActive( sub ) || isPendingCancel( sub ) ) ),
		inactiveSubscriptions: orderBy( inactiveSubscriptions, [ "endDate" ], [ "desc" ] ),
	};
}

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state ) => {
	// Filter queried subscriptions.
	const query = getSearchQuery( state );

	const subscriptions   = getAllOfEntity( state, "subscriptions" ).map( mapSubscriptionToProps );
	const provisionerData = state.entities.provisionerData.byId;

	const subscriptionPipeline = _flow( [
		// Filter queried subscriptions.
		filterSubscriptionsByQuery.bind( null, query ),
		// Whether to show the manage button for each subscription.
		showManageButton,
		// Sort subscriptions.
		sortByUpcomingPayment,
		// Group by subscription type.
		splitSubscriptionsByType,
		// Group by product sku.
		groupSubscriptionsByProduct,
	] );

	const {
		needsAttentionSubscriptions,
		provisionedSubscriptions,
		regularSubscriptions,
		inactiveSubscriptions,
	} = subscriptionPipeline( subscriptions );

	return {
		needsAttentionSubscriptions,
		regularSubscriptions,
		provisionedSubscriptions,
		inactiveSubscriptions,
		provisionerData,
		query,
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	return {
		onSearchChange: ( query ) => {
			dispatch( onSearchQueryChange( query ) );
		},
		onManage: ( subscriptionId ) => {
			dispatch( push( "/account/subscriptions/" + subscriptionId ) );
		},
		loadData: () => {
			dispatch( getOrders() );
			dispatch( getAllProvisionerData() );
			dispatch( getAllSubscriptions() );
		},
	};
};

const SubscriptionsPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( SubscriptionsPage );

export default SubscriptionsPageContainer;
