/* External dependencies */
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import _isUndefined from "lodash/isUndefined";
import _debounce from "lodash/debounce";
import validate from "validate.js";
import { speak } from "@wordpress/a11y";
import { TextField } from "@yoast/ui-library";

const messages = defineMessages( {
	validationInvalidCharactersURL: {
		id: "validation.invalid.characters.url",
		defaultMessage: "Please do not enter your credentials in the URL.",
	},
} );

/**
 * Text input field with functionality to validate on input
 * and show errors if validation fails.
 */
class ValidationInputField extends React.Component {
	/**
	 * Constructor for the ValidationInputField component.
	 *
	 * @param {Object} props The props that are passed to this component.
	 *
	 * @returns {void}
	 */
	constructor( props ) {
		super( props );

		this.state = {
			errors: [],
		};

		this.onInputChange = this.onInputChange.bind( this );
		this.announceErrors = this.announceErrors.bind( this );
		this.getValidationErrors = this.getValidationErrors.bind( this );
		this.validate = this.validate.bind( this );
		this.validateDebounced = _debounce( this.validate, this.props.delay );
	}

	/**
	 * Called whenever the text in the input field changes.
	 *
	 * @param {*} event the event.
	 * @returns {void}
	 */
	onInputChange( event ) {
		let value = event.target.value;

		if ( this.props.trimWhiteSpace ) {
			value = value.trim();
		}

		let validating = false;

		if ( this.props.constraint ) {
			this.validateDebounced( value );
			validating = true;
		}
		this.props.onChange( value, this.state.errors, validating );
	}

	/**
	 * Validates the given value according to the constraints as set in the properties.
	 *
	 * @param {*} value the value to check.
	 * @returns {string[]} an array of error messages, will be empty if there are none.
	 */
	validate( value ) {
		const errors = this.getValidationErrors( value );

		const validating = false;
		this.props.onChange( value, errors, validating );

		this.setState( {
			errors: errors,
		}, this.announceErrors );
	}

	/**
	 * Announces any validation errors. Uses @wordpress/a11y's speak() function.
	 *
	 * @returns {void}
	 */
	announceErrors() {
		if ( ! this.state.errors.length ) {
			return;
		}

		const combinedErrorString = this.state.errors.join( "." );
		speak( combinedErrorString, "assertive" );
	}

	/**
	 * Validates the given value according to the constraints as set in the properties.
	 *
	 * @param {*} value the value to check.
	 * @returns {string[]} an array of error messages, will be empty if there are none.
	 */
	getValidationErrors( value ) {
		let errors = validate.single( value, this.props.constraint, { format: "detailed" } );

		// Account for credentials in the URL object if the constraints are for url string.
		if ( this.props.constraint && this.props.constraint.hasOwnProperty( "url" ) ) {
			try {
				const url = new URL( value );

				if ( url.username || url.password ) {
					return [ this.props.intl.formatMessage( messages.validationInvalidCharactersURL ) ];
				}
			} catch ( error ) {
				return errors;
			}
		}

		if ( _isUndefined( errors ) ) {
			errors = [];
		}

		return errors;
	}

	/**
	 * Called whenever the component will disappear from the screen.
	 *
	 * @returns {void}
	 */
	componentWillUnmount() {
		this.validateDebounced.cancel();
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		const errors = this.props.errors.concat( this.state.errors );

		return (
			<Fragment>
				<TextField
					{ ...this.props }
					id={ this.props.id }
					onChange={ this.onInputChange }
					label={ this.props.label }
					type={ this.props.type }
					name={ this.props.name }
					validation={ { variant: "error", message: errors[ 0 ] } }
				/>
			</Fragment>
		);
	}
}

ValidationInputField.propTypes = {
	intl: intlShape.isRequired,
	id: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	type: PropTypes.string,
	label: PropTypes.string,
	delay: PropTypes.number,
	constraint: PropTypes.object,
	errors: PropTypes.array,
	name: PropTypes.string,
	trimWhiteSpace: PropTypes.bool,
};

ValidationInputField.defaultProps = {
	errors: [],
	delay: 1000,
	type: "text",
	label: "",
	constraint: null,
	name: null,
	trimWhiteSpace: false,
};

export default injectIntl( ValidationInputField );
