import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import queryString from "query-string";

// Components.
import LoginColumnLayout from "./LoginColumnLayout";
import LoginMessage from "./LoginMessage";
import SignupContainer from "../../containers/Signup";
import LoginContainer from "../../containers/Login";
import SubNavigation, { SubNavigationRoutes } from "../SubNavigation";
import { getRedirectUrl, saveIntendedDestination } from "shared-frontend/functions/auth";

const itemRoutes = [
	{
		component: SignupContainer,
		path: "/signup",
		title: "Sign up",
	},
	{
		component: LoginContainer,
		path: "/login",
		title: "Log in",
	},
];

/**
 * Test page to test the login layout / styling.
 *
 * @param  {object} props The props.
 *
 * @returns {ReactElement} Returns the login layout.
 */
function LoginSignupPage( props ) {
	const queryParams = queryString.parse( props.location.search );

	/**
	 * Stores the redirect destination when the component is mounted.
	 *
	 * @returns {void}
	 */
	useEffect( () => {
		if ( queryParams.redirect_to ) {
			saveIntendedDestination( queryParams.redirect_to );
		}
	}, [] );


	/**
	 * Select the aside type per defined parameters.
	 *
	 * @returns {string} The selected aside type.
	 */
	function selectAsideType() {
		if ( getRedirectUrl().includes( "academy.yoast" ) ) {
			return "academy";
		}

		// Only show the post-checkout aside when the user tries to visit the install page after the checkout.
		if ( getRedirectUrl().includes( "/install" ) && getRedirectUrl().includes( "invoice_number=" ) ) {
			return "post-checkout";
		}

		if ( props.location.pathname === "/login" || props.location.pathname === "/login/" ) {
			return "login";
		}

		return "signup";
	}

	return (
		<LoginColumnLayout>
			<LoginMessage asideType={ selectAsideType() } />
			<div>
				<SubNavigation itemRoutes={ itemRoutes } />
				<SubNavigationRoutes itemRoutes={ itemRoutes } />
			</div>
		</LoginColumnLayout>
	);
}

LoginSignupPage.propTypes = {
	location: PropTypes.object.isRequired,
};

export default injectIntl( LoginSignupPage );
