/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
/* Internal dependencies */
import ErrorDisplay, { ErrorPropTypeShape } from "../../../src/errors/ErrorDisplay";
import { TextField, Checkbox, Button, Link } from "@yoast/ui-library";
import styles from "./LoginFormStyles.scss";
import { NavLink } from "react-router-dom";

// Messages
const messages = defineMessages( {
	labelEmail: {
		id: "signup.email",
		defaultMessage: "Email address",
	},
	labelPassword: {
		id: "signup.password",
		defaultMessage: "Password",
	},
	labelOTP: {
		id: "signup.otp",
		defaultMessage: "Verification code",
	},
	loginButton: {
		id: "login.login",
		defaultMessage: "Log in",
	},
	rememberMe: {
		id: "login.rememberMe",
		defaultMessage: "Remember me",
	},
	forgotPassword: {
		id: "login.forgotPassword",
		defaultMessage: "Forgot your password?",
	},
	loading: {
		id: "login.loading",
		defaultMessage: "Logging in...",
	},
} );

/**
 * Login component for logging in to MyYoast.
 *
 * @param {Object} props the props.
 *
 * @returns {ReactElement} The login form element.
 */
const LoginForm = ( props ) => {
	const disabled   = props.loading || ! props.email || ! props.password;
	const buttonText = props.loading ? messages.loading : messages.loginButton;
	return (
		<form onSubmit={ props.handleSubmit } className={ styles.form }>
			<ErrorDisplay error={ props.error } />
			<div>
				<TextField
					id="email-address"
					name="email"
					type="text"
					autoComplete="email"
					label={ props.intl.formatMessage( messages.labelEmail ) }
					value={ props.email }
					onChange={ props.onUpdateEmail }
				/>
			</div>

			<div>
				<TextField
					id="password"
					name="password"
					type="password"
					autoComplete="current-password"
					label={ props.intl.formatMessage( messages.labelPassword ) }
					onChange={ props.onUpdatePassword }
				/>
			</div>

			{
				props.requireOTP &&
				<div>
					<TextField
						id="otp"
						name="otp"
						type="text"
						inputmode="numeric"
						autoComplete="one-time-code"
						label={ props.intl.formatMessage( messages.labelOTP ) }
						onChange={ props.onUpdateOTP }
					/>
				</div>
			}

			<div className={ styles.rememberForgot }>
				<Checkbox
					id="remember-me"
					name="remember-me"
					label={ props.intl.formatMessage( messages.rememberMe ) }
					onChange={ props.onRememberCheck }
					value=""
					checked={ props.rememberMe }
				/>
				<Link
					as={ NavLink }
					to="/forgot-password"
				>
					<FormattedMessage { ...messages.forgotPassword } />
				</Link>
			</div>

			<div>
				<Button
					className={ styles.button }
					type="submit"
					disabled={ disabled }
					aria-disabled={ disabled }
				>
					<FormattedMessage { ...buttonText } />
				</Button>
			</div>
		</form>
	);
};

LoginForm.propTypes = {
	intl: intlShape.isRequired,
	rememberMe: PropTypes.bool,
	email: PropTypes.string,
	password: PropTypes.string,
	error: ErrorPropTypeShape,
	onUpdateEmail: PropTypes.func.isRequired,
	onUpdatePassword: PropTypes.func.isRequired,
	onUpdateOTP: PropTypes.func.isRequired,
	onRememberCheck: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	loading: PropTypes.bool,
	requireOTP: PropTypes.bool,
};

LoginForm.defaultProps = {
	rememberMe: false,
	email: "",
	password: "",
	error: null,
	loading: false,
	requireOTP: false,
};

export default injectIntl( LoginForm );
