import PropTypes from "prop-types";

const Transaction = PropTypes.shape( {
	type: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	invoiceNumber: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	currency: PropTypes.string.isRequired,
	totalAmount: PropTypes.number.isRequired,
	items: PropTypes.array.isRequired,
	status: PropTypes.string.isRequired,
} );

export default Transaction;
