import React from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import icon from "../../images/seo_academy_bubble.svg";
import logo from "../../images/yoast-seo-academy-logo.png";
import { Title } from "@yoast/ui-library";

const messages = defineMessages( {
	header: {
		id: "login.academyAside.header",
		defaultMessage: "Start learning SEO!",
	},
	message: {
		id: "login.academyAside.message",
		defaultMessage: "Discover the ins and outs of SEO, site structure, copywriting and" +
		" more with our free video courses presented by industry professionals!" +
		" Sign up for Yoast SEO academy and learn from the best.",
	},
} );

/**
 * Display's the left side with the Academy branding.
 *
 * @returns {ReactElement} The component that contains the message.
 */
function AcademyLoginAside() {
	return (
		<>
			<img className="asideLogo" src={ logo } alt="MyYoast - Yoast Academy" />
			<img className="asideIcon" src={ icon } alt="" />
			<Title size="2">
				<FormattedMessage { ...messages.header } />
			</Title>
			<p>
				<FormattedMessage { ...messages.message } />
			</p>
		</>
	);
}

export default injectIntl( AcademyLoginAside );
