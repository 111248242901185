/* External dependencies */
import { createSelector } from "reselect";

/* Internal dependencies */
import {
	createAllOfEntitySelector,
	createEntityByIdSelector,
	createEntityStateSelector,
} from "./factories";
import { getSearchQuery } from "./search";
import { formatDate } from "../../../functions/dateHelper";

/**
 * Determines if the order is completed or not.
 *
 * @param {object} order The order to check.
 *
 * @returns {boolean} True for completed, false otherwise.
 */
const isCompletedOrder = ( order => {
	return [ "completed", "processing", "refunded" ].includes( order.status );
} );

/**
 * Returns the full state of all orders.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} The full state of all orders.
 */
export const getOrdersState = createEntityStateSelector( "orders" );

/**
 * Returns all orders in the state, sorted by date.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} All orders, sorted by order date.
 */
export const getSortedOrders = createSelector(
	createAllOfEntitySelector( "orders" ),
	orders => orders.sort( ( a, b ) => {
		return new Date( b.date ) - new Date( a.date );
	} ),
);

/**
 * Returns a map of all orders in the state.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} A map of all orders.
 */
export const getOrdersById = createEntityByIdSelector( "orders" );

/**
 * Returns the orders with a status indicating payment has been provided at some point.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} The paid orders.
 */
export const getPaidOrders = createSelector(
	getSortedOrders,
	orders => orders.filter( isCompletedOrder ),
);

/**
 * Returns the orders for which an invoice should be shown.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} The orders with invoices.
 */
export const getInvoiceOrders = createSelector(
	getSortedOrders,
	orders => orders.filter( order => {
		// Manually paid orders should be shown if they are on-hold.
		if ( order.status === "on-hold" && order.paymentGateway === "bacs" ) {
			return true;
		}
		return isCompletedOrder( order );
	} ),
);

/**
 * Factory for creating selectors with different order selectors, and filtering by query.
 *
 * @function
 *
 * @param {Function}   orderSelector The order selector to use in the resulting selector.
 *
 * @returns {Function}               The createSelector.
 */
function createFilteredOrderSelector( orderSelector ) {
	return createSelector(
		orderSelector,
		getSearchQuery,
		( orders, query ) => {
			if ( query.length > 0 ) {
				orders = orders.filter( order => {
					const formattedDate = formatDate( order.date );

					// Orders where one of productName, invoiceNumber, totalAmount or the date matches the query.
					return order.lineItems.find( item => item.productName.toUpperCase().includes( query.toUpperCase() ) ) ||
						order.invoiceNumber.toUpperCase().includes( query.toUpperCase() ) ||
						( order.totalAmount / 100 ).toString().includes( query ) ||
						formattedDate.toUpperCase().includes( query.toUpperCase() );
				} );
			}
			return orders;
		},
	);
}

/**
 * Gets the orders from the state, filtered by the search query.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} The orders, filtered by the search query.
 */
export const getFilteredOrders = createFilteredOrderSelector( getSortedOrders );

/**
 * Returns the orders that were paid, filtered by the search query.
 *
 * @function
 *
 * @param {Object}  state Application state.
 *
 * @returns {Array}       The filtered paid orders.
 */
export const getFilteredPaidOrders = createFilteredOrderSelector( getPaidOrders );

/**
 * Returns the orders that have invoices.
 *
 * @function
 *
 * @param {Object}  state Application state.
 *
 * @returns {Array}       The filtered orders.
 */
export const getOrdersWithInvoices = createFilteredOrderSelector( getInvoiceOrders );

export const getOrderTransactions = createSelector(
	getOrdersWithInvoices,
	( orders ) => {
		return orders.map( order => {
			return {
				type: "order",
				id: order.id,
				invoiceNumber: order.invoiceNumber,
				date: formatDate( new Date( order.date ) ),
				totalAmount: order.totalAmount,
				currency: order.currency,
				items: order.lineItems,
				status: order.status,
			};
		} );
	},
);

/**
 * Test whether all orders are correctly retrieved and not empty or undefined.
 *
 * @param {Array}     orders The orders array to test.
 * @returns {boolean}        Whether or not all orders are loaded.
 */
export const allOrdersLoaded = ( orders ) => {
	return ( orders.filter( order => ! ! order ).length === orders.length );
};
