import _union from "lodash/union";

import {
	RETRIEVE_COURSES_SUCCESS,
	RETRIEVE_COURSE_ENROLLMENTS_SUCCESS,
	SEND_COURSE_INVITE_SUCCESS,
} from "../actions/courses";

import { LOGOUT_SUCCESS } from "../actions/user";

/**
 * Initial state
 */
const initialState = {
	courses: {
		byId: {},
		allIds: [],
	},
	courseEnrollments: {
		byId: {},
		allIds: [],
	},
};

/**
 * A reducer for the byIdCourses list.
 *
 * @param {Object} state The current state of the byIdCourses list.
 * @param {Object} action The current action received.
 * @returns {Object} The updated byIdCourses object.
 */
export function byIdCoursesReducer( state = initialState.courses.byId, action ) {
	let courses;

	switch ( action.type ) {
		case RETRIEVE_COURSES_SUCCESS:
			courses = Object.assign( {}, state );

			action.courses.forEach( ( course ) => {
				courses[ course.id ] = course;
			} );

			return courses;
		case LOGOUT_SUCCESS:
			return initialState.courses.byId;
		default:
			return state;
	}
}

/**
 * A reducer for the allIdsCourses list.
 *
 * @param {Array} state The current state of the allIdsCourses list.
 * @param {Object} action The current action received.
 * @returns {Array} The updated allIdsCourses array.
 */
export function allIdsCoursesReducer( state = initialState.courses.allIds, action ) {
	switch ( action.type ) {
		case RETRIEVE_COURSES_SUCCESS:
			return _union( state, action.courses.map( course => course.id ) );
		case LOGOUT_SUCCESS:
			return initialState.courses.allIds;
		default:
			return state;
	}
}

/**
 * A reducer for the byIdCourseEnrollments list.
 *
 * @param {Object} state The current state of the byIdCourseEnrollments list.
 * @param {Object} action The current action received.
 * @returns {Object} The updated byIdCourseEnrollments object.
 */
export function byIdCourseEnrollmentsReducer( state = initialState.courseEnrollments.byId, action ) {
	let courses;
	switch ( action.type ) {
		case RETRIEVE_COURSE_ENROLLMENTS_SUCCESS:
			courses = Object.assign( {}, state );

			action.courseEnrollments.forEach( ( course ) => {
				courses[ course.id ] = course;
			} );

			return courses;
		case SEND_COURSE_INVITE_SUCCESS:
			courses = Object.assign( {}, state );

			action.updatedCourseEnrollments.map( ( updatedCourseEnrollment ) => {
				courses[ updatedCourseEnrollment.id ] = updatedCourseEnrollment;
			} );

			return courses;
		case LOGOUT_SUCCESS:
			return initialState.courseEnrollments.byId;
		default:
			return state;
	}
}

/**
 * A reducer for the allIdsCourseEnrollments list.
 *
 * @param {Array} state The current state of the allIdsCourseEnrollments list.
 * @param {Object} action The current action received.
 * @returns {Array} The updated allIdsCourseEnrollments array.
 */
export function allIdsCourseEnrollmentsReducer( state = initialState.courseEnrollments.allIds, action ) {
	switch ( action.type ) {
		case RETRIEVE_COURSE_ENROLLMENTS_SUCCESS:
			return _union( state, action.courseEnrollments.map( course => course.id ) );
		case LOGOUT_SUCCESS:
			return initialState.courseEnrollments.allIds;
		default:
			return state;
	}
}
