/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, Button, Title } from "@yoast/ui-library";

/* Internal dependencies */
import ButtonsContainer from "../../general/ButtonsContainer";


import styles from './styles.scss';

/**
 * Renders the AddLicenses component.
 *
 * @param {Object} props Component props.
 * @param {Function} props.onClose   The function to execute when the cancel button is clicked.
 * @param {Function} props.onUpgrade The function to execute when the link button is clicked.
 *
 * @returns {ReactElement} A react component describing the AddLicenses modal.
 */
export default function AddLicenses( props ) {
  return (
    <div className={ styles.addLicensesModal } role="document">
      <Title>
        <FormattedMessage id="add-licenses.header" defaultMessage="You are out of licenses" />
      </Title>
      <p className={ styles.addLicensesText }>
        <FormattedMessage
          id="add-licenses.text"
          defaultMessage="You've used up all the site licenses in your current subscription.
            If you want to add more sites, please buy another subscription."
        />
      </p>
      <ButtonsContainer>
        <Button type="button" variant="secondary" size="large" onClick={ props.onClose }>
          <FormattedMessage id="add-licenses.buy-more.cancel" defaultMessage="Cancel" />
        </Button>

        <Link href={ props.onShop } targer="_blank">
          <Button type="button" variant="primary" size="large">
            <FormattedMessage id="add-licenses.buy-more.shop" defaultMessage="Shop" />
          </Button>
        </Link>
      </ButtonsContainer>
    </div>
  );
}

AddLicenses.propTypes = {
  onClose: PropTypes.func.isRequired,
  onShop: PropTypes.string.isRequired,
};
