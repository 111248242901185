/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import { injectIntl, intlShape, FormattedMessage, defineMessages } from "react-intl";

/* Internal dependencies */
import ErrorDisplay, { ErrorPropTypeShape } from "../../../errors/ErrorDisplay";
import styles from "./ManageTokenStyles.scss";
import { Button, TextField, Title } from "@yoast/ui-library";

const messages = defineMessages(
	{
		manageTokenLabel: {
			id: "manage.token.label",
			defaultMessage: "Composer token description",
		},
		manageTokenDescription: {
			id: "manage.token.description",
			defaultMessage: "You can type a new description for the token here, in case you want to change it.",
		},
	},
);

/**
 * Returns the ManageToken component.
 *
 * @returns {ReactElement} The ManageToken component.
 */
class ManageToken extends React.Component {
	/**
	 * Initializes the class with the specified props.
	 *
	 * @param {Object} props The props to be passed to the class that was extended from.
	 *
	 * @returns {void}
	 */
	constructor( props ) {
		super( props );
		this.state = {
			tokenDescriptionInput: props.manageTokenData.name,
			tokenId: props.manageTokenData.id,
		};
	}

	/**
	 * Calls onChange function when website url changes.
	 *
	 * @param {Object} event The event returned by the WebsiteURLChange.
	 *
	 * @returns {void}
	 */
	onTokenDescriptionChange = event => {
		const value = event.target.value;
		this.setState( {
			tokenDescriptionInput: value,
		} );
	}

	/**
	 * Handles the submit event.
	 *
	 * @param {object} event The submit event.
	 *
	 * @returns {void}
	 */
	handleSubmit = event => {
		event.preventDefault();
		if ( this.state.tokenDescriptionInput !== "" ) {
			const data = {
				id: this.props.manageTokenData.id,
				name: this.state.tokenDescriptionInput,
			};
			this.props.onSaveTokenClick( data );
		}
	}

	/**
	 * Handles the onDeleteTokenClick from props.
	 *
	 * @returns {void}
	 */
	handleOnDeleteTokenClick = () => {
		this.props.onDeleteTokenClick( this.state.tokenId );
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		const isEnabled = this.state.tokenDescriptionInput !== "";
		return (
			<div>
				<div className={ styles.header }>
					<Title as="h2" size="4">
						<FormattedMessage id="profile.manageToken.header" defaultMessage="Manage this token" />
					</Title>
				</div>
				<form onSubmit={ this.handleSubmit } noValidate={ true } className={ styles.form }>
					<TextField
						id="change-token-description-input"
						placeholder={ this.state.tokenDescriptionInput }
						value={ this.state.tokenDescriptionInput }
						label={ this.props.intl.formatMessage( messages.manageTokenLabel ) }
						description={ this.props.intl.formatMessage( messages.manageTokenDescription ) }
						onChange={ this.onTokenDescriptionChange }
					/>

					<ErrorDisplay error={ this.props.error } />

					<div className={ styles.actions }>
						<div className={ styles.actionsLeft }>
							<Button
								type="submit"
								enabledStyle={ isEnabled }
								aria-disabled={ ! isEnabled }
							>
								<FormattedMessage id="profile.manage-token.save" defaultMessage="Save" />
							</Button>
							<Button
								onClick={ this.props.onClose }
								variant="secondary"
							>
								<FormattedMessage id="profile.manage-token.cancel" defaultMessage="Cancel" />
							</Button>
						</div>
						<Button
							onClick={ this.handleOnDeleteTokenClick }
							variant="error"
						>
							<FormattedMessage id="profile.manage-token.delete" defaultMessage="Delete" />
						</Button>
					</div>
				</form>
			</div>
		);
	}
}

ManageToken.propTypes = {
	intl: intlShape.isRequired,
	onSaveTokenClick: PropTypes.func.isRequired,
	onDeleteTokenClick: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	manageTokenData: PropTypes.object.isRequired,
	error: ErrorPropTypeShape,
};

ManageToken.defaultProps = {
	error: null,
};

export default injectIntl( ManageToken );
