import "whatwg-fetch";

import { doRequest, prepareInternalRequest } from "../../functions/api";

/*
 * Action types
 */

export const GET_ALL_PROVISIONER_DATA_REQUEST = "GET_ALL_PROVISIONER_DATA_REQUEST";
export const GET_ALL_PROVISIONER_DATA_SUCCESS = "GET_ALL_PROVISIONER_DATA_SUCCESS";
export const GET_ALL_PROVISIONER_DATA_FAILURE = "GET_ALL_PROVISIONER_DATA_FAILURE";

/*
 * Action creators
 */

/**
 * An action creator for the get all products request action.
 *
 * @returns {Object} A get all provisioner data action.
 */
export function getAllProvisionerDataRequest() {
	return {
		type: GET_ALL_PROVISIONER_DATA_REQUEST,
	};
}

/**
 * An action creator for the get all products success action.
 * @param {Object} provisionerData The provisioner data json object
 * @returns {Object} A get all products success action.
 */
export function getAllProvisionerDataSuccess( provisionerData ) {
	return {
		type: GET_ALL_PROVISIONER_DATA_SUCCESS,
		provisionerData: provisionerData,
	};
}

/**
 * An action creator for the get all products failure action.
 *
 * @param {object} error The error that was returned.
 *
 * @returns {Object} A get all products failure action.
 */
export function getAllProvisionerDataFailure( error ) {
	return {
		type: GET_ALL_PROVISIONER_DATA_FAILURE,
		error: error,
	};
}

/**
 * An action creator for the get all provisioner data action.
 *
 * @returns {Object} A get all products action.
 */
export function getAllProvisionerData() {
	return ( dispatch ) => {
		dispatch( getAllProvisionerDataRequest() );

		const request = prepareInternalRequest( "provisionerData/", "GET", {} );

		return doRequest( request )
			.then( json => dispatch( getAllProvisionerDataSuccess( json ) ) )
			.catch( error => dispatch( getAllProvisionerDataFailure( error ) ) );
	};
}
