import _union from "lodash/union";

import { GET_ALL_PROVISIONER_DATA_SUCCESS } from "../actions/provisionerData";

/*
 * Initial state
 */

const rootState = {
	provisionerData: {
		byId: {},
		allIds: [],
	},
};

/*
 * Reducers
 */

/**
 * A reducer for the byIdProducts object.
 *
 * @param {Object} state The current state of the byIdProducts object.
 * @param {Object} action The current action received.
 * @returns {Object} The updated byIdProducts object.
 */
export function byIdProvisionerDataReducer( state = rootState.provisionerData.byId, action ) {
	let data;

	switch ( action.type ) {
		case GET_ALL_PROVISIONER_DATA_SUCCESS:
			data = Object.assign( {}, state );

			action.provisionerData.forEach( ( provisionerData ) => {
				data[ provisionerData.userId ] = Object.assign( {}, provisionerData );
			} );

			return data;

		default:
			return state;
	}
}

/**
 * A reducer for the allIdsProducts array.
 *
 * @param {Array} state The current state of the allIdsProducts array.
 * @param {Object} action The current action received.
 * @returns {Array} The updated allIdsProducts array.
 */
export function allIdsProvisionerDataReducer( state = rootState.provisionerData.allIds, action ) {
	switch ( action.type ) {
		case GET_ALL_PROVISIONER_DATA_SUCCESS:
			return _union( state, action.provisionerData.map( provisionerData => provisionerData.userId ) );
		default:
			return state;
	}
}
