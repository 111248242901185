import React from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";

import CheckoutSuccessImage from "../../icons/checkout-success.svg";
import LogoImage from "../../icons/Yoast-logo.svg";
import InstallDesktop from "./InstallDesktop";
import styles from "./InstallStyles.scss";
import classNames from "classnames";

const messages = defineMessages( {
	checkout: {
		id: "install.checkout",
		defaultMessage: "Checkout",
	},
	stepOne: {
		id: "install.stepOne",
		defaultMessage: "Enter your details",
	},
	stepTwo: {
		id: "install.stepTwo",
		defaultMessage: "Pay",
	},
	stepThree: {
		id: "install.stepThree",
		defaultMessage: "Receive your products",
	},
	stepFour: {
		id: "downloading.stepFour",
		defaultMessage: "Install",
	},
	orderSuccess: {
		id: "install.orderSuccess",
		defaultMessage: "Your order has been processed successfully!",
	},
} );

/**
 * Install a subscription on a site flow.
 *
 * @param {object} props The properties.
 *
 * @returns {ReactComponent} The rendered component.
 */
const Install = ( props ) => {
	return (
		<div className={ styles.installWrapper }>
			<div className={ styles.header }>
				<div className={ classNames( styles.headerContainer, styles.wideContent ) }>
					<div className={ styles.headerLogo }>
						<img src={ LogoImage } alt="Yoast SEO for everyone" />
						<p>
							<FormattedMessage { ...messages.checkout } />
						</p>
					</div>
				</div>
			</div>

			<div className={ classNames( styles.steps, styles.wideContent ) }>
				<div className={ styles.step }><FormattedMessage { ...messages.stepOne } /></div>
				<div className={ styles.step }><FormattedMessage { ...messages.stepTwo } /></div>
				<div className={ classNames( styles.step, styles.activeStep ) }><FormattedMessage { ...messages.stepThree } /></div>
				<div className={ styles.step }><FormattedMessage { ...messages.stepFour } /></div>
			</div>

			<div className={ classNames( styles.narrowContent, styles.ySpacing ) }>
				<img
					className={ styles.successImage }
					src={ CheckoutSuccessImage }
					alt={ props.intl.formatMessage( messages.orderSuccess ) }
				/>
				<InstallDesktop
					plugins={ props.plugins }
					intl={ props.intl }
					sites={ props.sites }
					loadingSites={ props.loadingSites }
					submitAddSite={ props.submitAddSite }
				/>
			</div>
		</div>
	);
};

Install.propTypes = {
	intl: intlShape.isRequired,
	sites: PropTypes.array,
	loadingSites: PropTypes.bool,
	submitAddSite: PropTypes.func.isRequired,
	plugins: PropTypes.array,
};

Install.defaultProps = {
	sites: [],
	plugins: [],
	loadingSites: false,
};

export default injectIntl( Install );
