/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import { Alert } from "@yoast/ui-library";

/**
 * Creates a Form Validation Alert component.
 *
 * @param {object} props Properties for this element.
 *
 * @returns {string|ReactElement} the Form Validation Alert component.
 */
function FormValidationAlert( props ) {
	if ( props.errors.length <= 0 ) {
		return "";
	}

	return (
		<Alert variant={ props.type }>
			<ul>
				{ props.errors.map( ( error, i ) =>
					<li key={ i }> { error } </li>,
				) }
			</ul>
		</Alert>
	);
}

FormValidationAlert.propTypes = {
	errors: PropTypes.arrayOf( PropTypes.string ),
	type: PropTypes.oneOf( [ "error", "success", "warning", "info" ] ),
};

FormValidationAlert.defaultProps = {
	errors: [],
	type: "error",
};

export default FormValidationAlert;
