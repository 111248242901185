/* External dependencies */
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import validate from "validate.js";
import _every from "lodash/every";
import _isUndefined from "lodash/isUndefined";
import _debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import isShallowEqual from "@wordpress/is-shallow-equal";
import { getChangeButtons } from "./FormElements";
import ErrorDisplay, { ErrorPropTypeShape } from "../../../errors/ErrorDisplay";
import { passwordRepeatConstraint } from "../../login/CommonConstraints";
import FormValidationAlert from "../../FormValidationAlert";
import { validatePasswordStrength } from "../../../functions/validatePasswordStrength";
import styles from "./PasswordResetFormStyles.scss";
import { TextField } from "@yoast/ui-library";

const messages = defineMessages( {
	confirmPassword: {
		id: "confirm.password",
		defaultMessage: "Confirm new password",
	},
	currentPassword: {
		id: "current.password",
		defaultMessage: "Current password",
	},
	newPassword: {
		id: "new.password",
		defaultMessage: "New password",
	},
} );

/**
 * Returns the rendered PasswordResetForm component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {ReactElement} The rendered ProfileForm component.
 */
class PasswordResetForm extends React.Component {
	/**
	 * Constructs the ProfileForm class.
	 *
	 * Sets (input) validation constraints, including password.
	 *
	 * @param {Object} props The props passed to the component.
	 * @returns {void}
	 */
	constructor( props ) {
		super( props );

		this.state = {
			currentPassword: "",
			newPassword: "",
			passwordRepeat: "",
			passwordValidation: {
				isFilledIn: false,
				score: 0,
				isStrongEnough: false,
				feedback: {
					suggestions: [],
					warning: "",
				},
				errors: [],
				isAllowedPassword: false,
			},
			passwordRepeatErrors: [],
			onDiscard: false,
		};

		this.onCurrentPassword = this.onCurrentPassword.bind( this );
		this.onNewPassword     = this.onNewPassword.bind( this );
		this.onPasswordRepeat  = this.onPasswordRepeat.bind( this );

		this.validateNewPassword = _debounce( this.validateNewPassword, 500 );
		this.handleSubmit        = this.handleSubmit.bind( this );
		this.discardChanges      = this.discardChanges.bind( this );

		this.isSaved             = this.isSaved.bind( this );
	}

	/**
	 * Handles the change event on the current password input field and sets the currentPassword state.
	 *
	 * @param {object} event The input field change event.
	 *
	 * @returns {void}
	 */
	onCurrentPassword( event ) {
		this.setState( { currentPassword: event.target.value } );
	}

	/**
	 * Handles the change event on the new password input field and sets the newPassword state.
	 *
	 * @param {object} event The input field change event.
	 *
	 * @returns {void}
	 */
	onNewPassword( event ) {
		const newPassword = event.target.value;
		this.setState( { newPassword: newPassword }, this.validate );
		this.validateNewPassword( newPassword );
	}

	/**
	 * Handles the change event on the confirm new password input field and sets the confirmPassword state.
	 *
	 * @param {object} event The input field change event.
	 *
	 * @returns {void}
	 */
	onPasswordRepeat( event ) {
		this.setState( { passwordRepeat: event.target.value }, this.validate );
	}

	/**
	 * Discards the changes of personal info and resets it to initial state.
	 *
	 * @returns {void}
	 */
	discardChanges() {
		this.setState( {
			currentPassword: "",
			newPassword: "",
			passwordRepeat: "",
			onDiscard: false,
		} );
	}

	/**
	 * Validates the new password using zxcvbn, and puts its findings in the state.
	 *
	 * @param {string} newPassword The new password.
	 *
	 * @returns {void}
	 */
	validateNewPassword( newPassword ) {
		this.setState( { passwordValidation: validatePasswordStrength( newPassword, [] ) } );
	}

	/**
	 * Validates the password and password repeat fields
	 * and returns an array of errors if there are any,
	 * and an empty array if none are present.
	 *
	 * @returns {void}
	 */
	validate() {
		const passwordRepeatErrors = validate(
			{
				password: this.state.newPassword,
				passwordRepeat: this.state.passwordRepeat,
			},
			{ passwordRepeat: passwordRepeatConstraint( this.props.intl ) },
			{ format: "detailed" },
		);

		let newErrorState = [];
		if ( this.state.passwordRepeat.length > 0 && ! _isUndefined( passwordRepeatErrors ) ) {
			newErrorState = [ passwordRepeatErrors[ 0 ].options.message ];
		}

		this.setState( { passwordRepeatErrors: newErrorState } );
	}

	/**
	 * Whether we have saved.
	 *
	 * @returns {boolean} Whether we are currently saving.
	 */
	isSaved() {
		return this.props.passwordIsSaved && ! this.state.onDiscard &&
			_every( [ "newPassword" ], key => this.props[ key ] === this.state[ key ] );
	}

	/**
	 * Handles the submit event on the form.
	 *
	 * @param {object} event The form submit event.
	 *
	 * @returns {void}
	 */
	handleSubmit( event ) {
		event.preventDefault();
		/*
		 * While saving: prevent multiple submissions but don't disable the
		 * button for better accessibility (avoid keyboard focus loss).
		 */

		if ( this.props.isSavingPassword || ! this.canSave() ) {
			return;
		}

		this.props.onSavePassword( {
			/* eslint-disable camelcase */
			password: this.state.newPassword,
			password_confirmation: this.state.passwordRepeat,
			old_password: this.state.currentPassword,
			/* eslint-enable camelcase */
		} );


		this.setState( {
			onDiscard: false,
			currentPassword: "",
			newPassword: "",
			passwordRepeat: "",
		} );
	}

	/**
	 * Whether the form should be able to be submitted.
	 *
	 * @returns {boolean} Whether or not the form should be able to be submitted.
	 */
	canSave() {
		return ( ! isEmpty( this.state.currentPassword ) ) &&
			( ! isEmpty( this.state.newPassword ) ) &&
			( ! isEmpty( this.state.passwordRepeat ) ) &&
			this.state.passwordRepeatErrors.length <= 0 &&
			this.state.passwordValidation.isAllowedPassword;
	}

	/**
	 * Tries to reduce rerenderings when props and state don't change.
	 *
	 * Useful when other child components trigger parent componet updates.
	 *
	 * @param {object} nextProps The next props.
	 * @param {object} nextState The next state.
	 *
	 * @returns {boolean} Should component update.
	 */
	shouldComponentUpdate( nextProps, nextState ) {
		return ! isShallowEqual( nextProps, this.props ) || ! isShallowEqual( nextState, this.state );
	}

	/**
	 * Resets the profile saved message when the component unmounts.
	 *
	 * @returns {void}
	 */
	componentWillUnmount() {
		this.props.resetSaveMessage();
	}

	/**
	 * Puts all found validation errors in a single array of strings, and returns it.
	 *
	 * @param {Array<string>} passwordErrors The errors found for the password field.
	 *
	 * @returns {Array.<string>} An array of validation errors.
	 */
	getAlertContents( passwordErrors ) {
		return passwordErrors.concat( this.state.passwordRepeatErrors );
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		const formValidationFeedback = []
			.concat( this.state.passwordValidation.feedback.suggestions )
			.concat( this.state.passwordRepeatErrors );

		const feedbackType = ( this.state.passwordRepeatErrors || this.state.newPasswordScore < 3 ) ? "error" : "warning";

		return (
			<form onSubmit={ this.handleSubmit } className={ styles.passwordForm }>
				<FormValidationAlert errors={ formValidationFeedback } type={ feedbackType } />
				<ErrorDisplay error={ this.props.passwordResetError } />
				<div>
					<TextField
						id="current-password"
						name="current password"
						type="password"
						label={ this.props.intl.formatMessage( messages.currentPassword ) }
						value={ this.state.currentPassword }
						onChange={ this.onCurrentPassword }
					/>
				</div>
				<div>
					<TextField
						id="new-password"
						name="new password"
						type="password"
						label={ this.props.intl.formatMessage( messages.newPassword ) }
						value={ this.state.newPassword }
						onChange={ this.onNewPassword }
					/>
				</div>
				<div>
					<TextField
						id="confirm-password"
						name="confirm password"
						type="password"
						label={ this.props.intl.formatMessage( messages.confirmPassword ) }
						value={ this.state.passwordRepeat }
						onChange={ this.onPasswordRepeat }
					/>
				</div>
				{ getChangeButtons(
					this.canSave(),
					this.props.isSavingPassword,
					this.props.passwordIsSaved,
					this.discardChanges,
				) }
			</form>
		);
	}
}

PasswordResetForm.propTypes = {
	intl: intlShape.isRequired,
	onSavePassword: PropTypes.func.isRequired,
	isSavingPassword: PropTypes.bool,
	passwordIsSaved: PropTypes.bool,
	passwordResetError: ErrorPropTypeShape,
	resetSaveMessage: PropTypes.func.isRequired,
};

PasswordResetForm.defaultProps = {
	isSavingPassword: false,
	passwordIsSaved: false,
	passwordResetError: null,
};

export default injectIntl( PasswordResetForm );
