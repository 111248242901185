/**
 * Get the user id.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {string} The user id.
 */
export const getUserId = ( state ) => {
	return state.user.userId;
};
