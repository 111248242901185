import React from "react";
import { Button } from '@yoast/ui-library';
import { FormattedMessage } from "react-intl";
import { ArrowLongLeftIcon} from "@heroicons/react/20/solid";

import styles from "./styles.scss";

export const BackButton = ( props ) => {
  const goBack = () => {
      window.history.back();
    }

  return (
    <Button
      type="button"
      variant="secondary"
      onClick={ goBack }
      className={ styles.backButton }
    >
      <ArrowLongLeftIcon className={ styles.icon } /> <FormattedMessage id="button.back" defaultMessage="Go back" />
    </Button>
  )
}