const dateFormatter = new Intl.DateTimeFormat( "en-US", {
	year: "numeric",
	month: "long",
	day: "numeric",
} );

/**
 * Formats a date to a human-readable format.
 *
 * @param {string|Date} date The date to format.
 *
 * @returns {string} A formatted date string.
 */
export function formatDate( date ) {
	if ( date instanceof Date ) {
		return dateFormatter.format( date );
	}

	return dateFormatter.format( new Date( date ) );
}
