import React, { Fragment } from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";

import { noop } from "shared-frontend/functions/noop";
import { SelectField } from "@yoast/ui-library";

const messages = defineMessages( {
	selectExistingSite: {
		id: "install.select.orChooseExisting",
		defaultMessage: "Or select an existing site",
	},
	selectExistingSitePlaceholder: {
		id: "install.select.selectExistingPlaceholder",
		defaultMessage: "Select site",
	},
} );

/**
 * Renders the SelectExistingSite component.
 *
 * @param {object} props The properties.
 *
 * @returns {ReactElement} The SelectExistingSite component.
 */
const SelectExistingSite = ( props ) => {
	/**
	 * Passes selected value of the select field.
	 *
	 * @param {string} value The selected value.
	 *
	 * @returns {void}
	 */
	function onChange( value ) {
		props.selectionChanged( value );
	}

	const wordPressSites = props.existingSites
		.filter( site => site.siteType === "wordpress" )
		.map( site => ( { value: site.url, label: site.url } ) );

	return (
		<>
			<SelectField
				id="select-previously-added-site"
				name="selectSite"
				label={ props.intl.formatMessage( messages.selectExistingSite ) }
				value={ props.selectedSiteUrl }
				onChange={ value => onChange( value ) }
				disabled={ props.disabled }
				options={ [
					{ value: "", label: props.intl.formatMessage( messages.selectExistingSitePlaceholder ) },
					...wordPressSites,
				] }
			/>
		</>
	);
};

SelectExistingSite.propTypes = {
	selectedSiteUrl: PropTypes.string,
	selectionChanged: PropTypes.func,
	existingSites: PropTypes.array,
	disabled: PropTypes.bool,
	intl: intlShape.isRequired,
};

SelectExistingSite.defaultProps = {
	selectedSiteUrl: "",
	selectionChanged: noop,
	existingSites: [],
	disabled: false,
};

export default injectIntl( SelectExistingSite );
