import React from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import icon from "../../icons/checkout-success.svg";
import logo from "../../images/my-yoast-academy-logo.svg";
import { Title } from "@yoast/ui-library";

const messages = defineMessages( {
	header: {
		id: "login.postCheckoutAside.header",
		defaultMessage: "Almost there!",
	},
	message: {
		id: "login.postCheckoutAside.message",
		defaultMessage: "Thank you for your purchase! " +
			"It appears you already have a MyYoast account. That’s great! " +
			"Please log in to start using your new products.",
	},
} );

/**
 * Display's the left side with the post-checkout branding.
 *
 * @returns {ReactElement} The component that contains the message
 */
function PostCheckoutLoginAside() {
	return (
		<>
			<img className="asideLogo" src={ logo } alt="MyYoast - Yoast Academy" />
			<img className="asideIcon" src={ icon } alt="" />
			<Title size="2">
				<FormattedMessage { ...messages.header } />
			</Title>
			<p>
				<FormattedMessage { ...messages.message } />
			</p>
		</>
	);
}

export default injectIntl( PostCheckoutLoginAside );
