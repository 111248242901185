import getEnv from "shared-frontend/functions/getEnv";
import { getAccessToken } from "shared-frontend/functions/auth";

/**
 * Generates a payment method switch URL for a subscription.
 *
 * @param {Subscription} subscription The subscription to get a payment method switch URL for.
 * @returns {string} The payment method switch URL.
 */
export function generateSwitchPaymentMethodUrl( subscription ) {
	const { id, subscriptionNumber } = subscription;
	const homeUrl                    = getEnv( "API_URL", "https://my.yoast.test/api" );

	return `${ homeUrl }/subscriptions/switchPaymentMethod/${ id }/${ subscriptionNumber }?access_token=${ getAccessToken() }`;
}

/**
 * Generates a URL for a subscription to switch to automatic billing.
 *
 * @param {Subscription} subscription The subscription to get an enable automatic billing URL for.
 * @returns {string} The enable automatic billing URL.
 */
export function generateSwitchToAutomaticUrl( subscription ) {
	const { id, subscriptionNumber } = subscription;
	const homeUrl                    = getEnv( "API_URL", "https://my.yoast.test/api" );

	return `${ homeUrl }/subscriptions/enable-automatic-billing/${ id }/${ subscriptionNumber }?access_token=${ getAccessToken() }`;
}
