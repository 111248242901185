import { connect } from "react-redux";

import RenewalNotification from "../components/RenewalNotification";
import { getAllSubscriptions } from "shared-frontend/redux/actions/subscriptions";
import { getUpcomingRenewalSubscriptions } from "shared-frontend/redux/selectors/entities/subscriptions";
import { getAllSubscriptionsById } from "shared-frontend/redux/selectors/entities/subscriptions";

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state ) => {
	return {
		upcomingRenewals: getUpcomingRenewalSubscriptions( state ),
		allSubscriptions: getAllSubscriptionsById( state ),
	};
};

export const mapDispatchToProps = ( dispatch ) => {
	return {
		loadData: () => {
			dispatch( getAllSubscriptions() );
		},
	};
};

const RenewalNotificationContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( RenewalNotification );

export default RenewalNotificationContainer;
