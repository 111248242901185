/* eslint-disable global-require */
if ( typeof Promise === "undefined" ) {
	// Rejection tracking prevents a common issue where React gets into an
	// Iconsistent state due to an error, but it gets swallowed by a Promise,
	// And the user has no idea what causes React's erratic future behavior.
	require( "promise/lib/rejection-tracking" ).enable();
	window.Promise = require( "promise/lib/es6-extensions.js" );
}

// Fetch() polyfill for making API calls.
require( "whatwg-fetch" );

