import {
	GET_PRODUCT_GROUPS_FAILURE,
	GET_PRODUCT_GROUPS_REQUEST,
	GET_PRODUCT_GROUPS_SUCCESS,
} from "shared-frontend/redux/actions/productGroups";

/*
 * Initial state
 */

const rootState = {
	productGroups: {
		requesting: false,
		error: null,
	},
};

/*
 * Reducers
 */

/**
 * A reducer for the ui productGroups object.
 *
 * @param {Object} state The current state of the ui productGroups object.
 * @param {Object} action The current action received.
 * @returns {Object} The updated ui product object.
 */
export function uiAllProductGroupsReducer( state = rootState.productGroups, action ) {
	switch ( action.type ) {
		case GET_PRODUCT_GROUPS_REQUEST:
			return Object.assign( {}, state, {
				requesting: true,
				error: null,
			} );
		case GET_PRODUCT_GROUPS_SUCCESS:
			return Object.assign( {}, state, {
				requesting: false,
			} );
		case GET_PRODUCT_GROUPS_FAILURE:
			return Object.assign( {}, state, {
				requesting: false,
				error: action.error,
			} );
		default:
			return state;
	}
}
