/* External dependencies */
import React from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape, defineMessages } from "react-intl";

/* Internal dependencies */
import { TextField } from "@yoast/ui-library";
import styles from "./ProfileDetailsBlockStyles.scss";

const messages = defineMessages( {
	labelFirstName: {
		id: "profile.label.firstName",
		defaultMessage: "First name",
	},
	labelLastName: {
		id: "profile.label.lastName",
		defaultMessage: "Last name",
	},
} );

/**
 * A component for entering the first- and last name and
 * uploading a profile image.
 */
class ProfileDetailsBlock extends React.Component {
	// eslint-disable-next-line require-jsdoc
	constructor( props ) {
		super( props );

		this.state = {
			userFirstName: this.props.userFirstName,
			userLastName: this.props.userLastName,
		};

		this.onUpdateFirstName = this.onUpdateField.bind( this, "userFirstName" );
		this.onUpdateLastName  = this.onUpdateField.bind( this, "userLastName" );
		this.handleSubmit      = this.handleSubmit.bind( this );
	}

	/**
	 * Updates the given field in the state with the target value in the
	 * given event.
	 *
	 * @param {string} field the field that needs to be updated.
	 * @param {object} event the event from which to get the new value.
	 * @returns {void}
	 */
	onUpdateField( field, event ) {
		this.setState( {
			[ field ]: event.target.value,
		} );
	}

	/**
	 * Calls the onSubmit function given in the props
	 * with the entered first name, last name and image file.
	 *
	 * @param {event} event The submit event.
	 * @returns {void}
	 */
	handleSubmit( event ) {
		event.preventDefault();
		this.props.onSubmit( this.state.userFirstName, this.state.userLastName );
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return <form
			className={ styles.form }
			onSubmit={ event => this.handleSubmit( event ) }
		>
			<TextField
				id="first-name"
				name="first name"
				type="text"
				label={ this.props.intl.formatMessage( messages.labelFirstName ) }
				value={ this.state.userFirstName }
				onChange={ this.onUpdateFirstName }
			/>
			<TextField
				id="last-name"
				name="last name"
				type="text"
				label={ this.props.intl.formatMessage( messages.labelLastName ) }
				value={ this.state.userLastName }
				onChange={ this.onUpdateLastName }
			/>
			<div>
				{ this.props.children }
			</div>
		</form>;
	}
}

export default injectIntl( ProfileDetailsBlock );

ProfileDetailsBlock.propTypes = {
	intl: intlShape.isRequired,
	children: PropTypes.any,
	onSubmit: PropTypes.func.isRequired,
	userFirstName: PropTypes.string,
	userLastName: PropTypes.string,
	profileSaving: PropTypes.bool,
};

ProfileDetailsBlock.defaultProps = {
	userFirstName: "",
	userLastName: "",
	profileSaving: false,
	children: "",
};
