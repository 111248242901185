/**
 * Returns the user's email state.
 *
 * @param {Object} state Application state.
 *
 * @returns {String} The user email state.
 */
export function getUserEmail( state ) {
	return state.user.data.profile.email;
}

/**
 * Returns the user's first name state.
 *
 * @param {Object} state Application state.
 *
 * @returns {String} The user first name state.
 */
export function getUserFirstName( state ) {
	return state.user.data.profile.userFirstName;
}

/**
 * Returns the user's last name state.
 *
 * @param {Object} state Application state.
 *
 * @returns {String} The user last name state.
 */
export function getUserLastName( state ) {
	return state.user.data.profile.userLastName;
}

/**
 * Get the user profile from state.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Object} The complete user profile.
 */
export const getProfile = state => state.user.data.profile;
