/**
 * Action types
 */

export const LINK_SITE_MODAL_OPEN = "LINK_SITE_MODAL_OPEN";
export const LINK_SITE_MODAL_CLOSE = "LINK_SITE_MODAL_CLOSE";


/**
 * Action creators
 */

/**
 * An action creator for the opening link site modal action.
 *
 * @returns {Object} An open link site modal action.
 */
export function linkSiteModalOpen() {
	return {
		type: LINK_SITE_MODAL_OPEN,
	};
}

/**
 * An action creator for the closing link site modal action.
 *
 * @returns {Object} A close link site modal action.
 */
export function linkSiteModalClose() {
	return {
		type: LINK_SITE_MODAL_CLOSE,
	};
}
