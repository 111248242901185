import { connect } from "react-redux";

import Activate from "../components/login/Activate";
import { activateRequest } from "shared-frontend/redux/actions/signup";
import { resetOauthError } from "shared-frontend/redux/actions//login";
import { getUser } from "shared-frontend/redux/selectors/entities/user";
import { getActivate } from "../redux/selectors/ui/site";

/* eslint-disable require-jsdoc */
export const mapStateToProps = ( state ) => {
	return Object.assign(
		{},
		getUser( state ),
		getActivate( state ),
	);
};

export const mapDispatchToProps = ( dispatch ) => {
	return {
		activateUser: ( data ) => {
			dispatch( activateRequest( data ) );
		},
		resetOauthError: () => {
			dispatch( resetOauthError() );
		},
	};
};

const ActivateContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( Activate );

export default ActivateContainer;
