import PropTypes from "prop-types";
import React from "react";
import { injectIntl, FormattedMessage, defineMessages, intlShape } from "react-intl";
import { Button, Select } from "@yoast/ui-library";

import styles from "./styles.scss";

const messages = defineMessages( {
	platformSelectLabel: {
		id: "sites.details.changePlatformType.label",
		defaultMessage: "Please select a platform",
	},
	platformSelectDescription: {
		id: "sites.details.changePlatformType.description",
		defaultMessage: "Here you can select the platform that your website is running on.",
	},
	platformSelectDisabled: {
		id: "sites.details.changePlatformType.disabled",
		defaultMessage: "This option is currently disabled because you still have plugins active on this site.",
	},
	platformSelectConfirm: {
		id: "sites.details.changePlatformType.confirm",
		defaultMessage: "Confirm",
	},
} );

const SITE_TYPE_OPTIONS = {
	wordpress: {
		value: "wordpress",
		label: "WordPress",
	},
	typo3: {
		value: "typo3",
		label: "TYPO3",
	},
};

/**
 * Returns the PlatformSelect component.
 *
 * @returns {ReactElement} The PlatformSelect component.
 */
class PlatformSelect extends React.Component {
	/**
	 * Initializes the class with the specified props.
	 *
	 * @param {Object} props The props to be passed to the class that was extended from.
	 *
	 * @returns {void}
	 */
	constructor( props ) {
		super( props );

		const value = props.siteType;
		const label = SITE_TYPE_OPTIONS[value].label;

		this.state = {
			selectedOption: {
				value,
				label,
			},
		};

		this.handleConfirm = this.handleConfirm.bind( this );
		this.handleOnChange = this.handleOnChange.bind( this );
	}

	/**
	 * Handles the change event.
	 *
	 * @param {string} value The select field value.
	 *
	 * @returns {void}
	 */
	handleOnChange( value ) {
		const label = SITE_TYPE_OPTIONS[value].label;

		this.setState( {
			selectedOption: {
				value,
				label,
			},
		} );
	}

	/**
	 * Handles the confirmation of the selected option.
	 *
	 * @param {object} event The click event on the Confirm button.
	 *
	 * @returns {void}
	 */
	handleConfirm( event ) {
		event.preventDefault();
		if ( ! this.props.disablePlatformSelect ) {
			this.props.onConfirm( this.props.siteId, this.state.selectedOption.value );
		}
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return (
				<div className={ styles.platformSelect }>
					<div className={ styles.selectAndConfirm }>
						<Select
								className={ styles.select }
								id="select-platform"
								name="select-platform"
								label={ this.props.intl.formatMessage( messages.platformSelectLabel ) }
								value={ this.state.selectedOption.value }
								onChange={ this.handleOnChange }
								disabled={ this.props.disablePlatformSelect }
								options={ Object.keys( SITE_TYPE_OPTIONS ).map( ( option ) => {
									const value = SITE_TYPE_OPTIONS[option].value;
									const label = SITE_TYPE_OPTIONS[option].label;

									return { value, label };
								} ) }
						/>
						<Button
								size="large"
								onClick={ this.handleConfirm }
								disabled={ this.props.disablePlatformSelect }
						>
							<FormattedMessage { ...messages.platformSelectConfirm } />
						</Button>
					</div>
					<p>
						{ this.props.disablePlatformSelect
								? <FormattedMessage { ...messages.platformSelectDisabled } />
								: <FormattedMessage { ...messages.platformSelectDescription } />
						}
					</p>
				</div>
		);
	}
}

PlatformSelect.propTypes = {
	intl: intlShape.isRequired,
	siteId: PropTypes.string.isRequired,
	siteType: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	onConfirm: PropTypes.func,
	disablePlatformSelect: PropTypes.bool,
};

PlatformSelect.defaultProps = {
	disablePlatformSelect: false,
};

export default injectIntl( PlatformSelect );
