import _isObject from "lodash/isObject";
import _merge from "lodash/merge";
import "whatwg-fetch";

import { getAccessToken, hasAccessToken, removeCookies as removeAuthCookies, appPrefersImpersonator } from "./auth";
import getEnv from "./getEnv";

/**
 * Returns the API URL that should be used to do AJAX requests to.
 *
 * @returns {string} The URL of the API.
 */
export function getApiUrl() {
	return getEnv( "API_URL", "https://my.yoast.test/api" );
}

/**
 * Determines whether a valid HTTP method was passed.
 *
 * @param {string} method The HTTP method to check.
 * @returns {boolean} Whether or not the HTTP method is valid.
 */
function determineValidMethod( method ) {
	const validMethods = [ "GET", "POST", "PUT", "FETCH", "HEAD", "DELETE", "PATCH" ];

	if ( typeof method !== "string" ) {
		return false;
	}

	return validMethods.includes( method.toUpperCase() );
}

/**
 * Prepares the payload based on the methods. GET and HEAD can't have payloads, and FormData cannot be stringified.
 *
 * @param {string} method The API method for the request.
 * @param {Object} payload The payload that came with the request.
 *
 * @returns {Object} null, a FormData object, or a stringified object.
 */
function preparePayload( method, payload ) {
	if ( payload instanceof FormData ) {
		return payload;
	}

	return JSON.stringify( payload );
}

/**
 * Prepares a request for sending.
 *
 * @param {string} url The URL to send the request to.
 * @param {string} method The HTTP method to use for the request.
 * @param {object} payload The payload of the request.
 * @param {Object} additionalOptions An optional object containing options to be used by the request object.
 *
 * @returns {Request} The Request object.
 */
export function prepareRequest( url, method = "GET", payload = {}, additionalOptions = {} ) {
	if ( ! determineValidMethod( method ) ) {
		throw new Error( `Invalid method of ${ method } supplied. Please ensure it's a string and a valid HTTP method.` );
	}

	const defaultOptions = {
		method,
		headers: { "Content-Type": "application/json" },
	};

	if ( method !== "GET" && method !== "HEAD" ) {
		defaultOptions.body = preparePayload( method, payload );
	}

	// Perform a deep merge. This ensures additional headers are merged in the default headers.
	const options = _merge( {}, defaultOptions, additionalOptions );

	// Remove the content type headers if told to do so, to allow browsers to set the boundary.
	if ( options.headers[ "Content-Type" ] === "let-the-browser-set-this" ) {
		delete options.headers[ "Content-Type" ];
	}

	return new Request( url, options );
}

/**
 * A function for preparing an API call to the my-yoast server.
 *
 * @param {string} path The route to call.
 * @param {string} method The method ( GET, POST, etc. ).
 * @param {object} payload The body, if applicable.
 * @param {object} additionalOptions Additional request options.
 *
 * @returns {Request} The request.
 */
export function prepareInternalRequest( path, method = "GET", payload = {}, additionalOptions = {} ) {
	if ( hasAccessToken() ) {
		additionalOptions.headers = Object.assign(
			{},
			additionalOptions.headers || {},
			{ Authorization: "Bearer " + getAccessToken( appPrefersImpersonator() ) },
		);
	}

	const queryParams = Object.assign(
		{},
		additionalOptions.queryParams || {},
	);

	let queryString = Object.keys( queryParams )
		.map( paramKey => {
			if ( _isObject( queryParams[ paramKey ] ) ) {
				return paramKey.concat( "=", JSON.stringify( queryParams[ paramKey ] ) );
			}
			return paramKey.concat( "=", queryParams[ paramKey ] );
		} )
		.join( "&" );

	// Only add the question mark to start query parameters if there are any.
	if ( queryString.length > 0 ) {
		queryString = "?" + queryString;
	}

	return prepareRequest( `${ getApiUrl() }/${ path }${ queryString }`, method, payload, additionalOptions );
}

/**
 * Handle an invalid accessToken.
 *
 * @returns {void}
 */
function handleInvalidAccessToken() {
	removeAuthCookies();
	document.location.href = "login";
	throw new Error( "Authentication required" );
}

/**
 * @returns {string} Returns the host.
 */
export function getMyYoastHost() {
	return getEnv( "HOME_URL", "https://my.yoast.test" );
}

/**
 * Handles the response.
 *
 * @param {Response} response The server response object.
 * @returns {object} The processes response.
 */
function handleResponse( response ) {
	/*
	 With opaque response types, no inference can be made on whether the request was successful.
	 For now we'll assume all such responses come from external requests, and can only be successful.
	 */
	if ( response.type === "opaque" ) {
		return Promise.resolve();
	}

	// If no content in response, resolve empty promise.
	if ( response.status === 204 ) {
		return Promise.resolve();
	}

	if ( response.status === 429 ) {
		return Promise.reject( { message: "We got too many requests in a short timespan. Please try again later." } );
	}

	return new Promise( ( resolve, reject ) => {
		// Some requests (like delete requests) have an empty body. So response.json() throws.
		// We catch those errors and replace the body value with {}.
		if ( ! response.ok ) {
			return response.json()
				.then( ( body ) => {
					if ( response.status === 401 && body.invalidAccessToken ) {
						handleInvalidAccessToken();
					}
					return reject( body );
				} )
				.catch( () => reject( {} ) );
		}
		return response.json()
			.then( ( body ) => resolve( body ) )
			.catch( () => resolve( {} ) );
	} );
}

/**
 * Executes a specific request and handles potential errors.
 *
 * @param {Request} request The request to execute.
 * @returns {Promise} The fetched request object.
 */
export async function doRequest( request ) {
	return fetch( request )
		.then( handleResponse )
		.catch( err => {
			throw err;
		} );
}

/**
 * Rejects the login request if a redirect is involved.
 * In the case of the request being redirected, we assume it is because the user would be redirected to the OFAC information page.
 *
 * @param {Response} response The response.
 *
 * @returns {Response} The response object.
 */
function preventRedirect( response ) {
	if ( response.type === "opaqueredirect" ) {
		return Promise.reject( { message: "Unavailable for legal reasons. " +
				"We are not permitted to serve visitors from countries under OFAC sanctions. " +
				"For more information, visit the [ofac_information_page]" } );
	}
	return response;
}

/**
 * Executes a login request and handles potential errors.
 *
 * @param {Request} request The login request to execute.
 *
 * @returns {Promise} The fetched request object.
 */
export async function doLoginRequest( request ) {
	return fetch( request, { redirect: "manual" } )
		.then( preventRedirect )
		.then( handleResponse )
		.catch( err => {
			throw err;
		} );
}


/**
 * Returns the invoice URL for a certain invoice.
 *
 * @param {string} type  The kind of transaction we want to get the invoice URL for.
 * @param {string} resourceId The ID of the resource.
 * @param {string} orderId  The id of the Order, in the event that it concerns a refund.
 *
 * @returns {string} The URL to the order invoice.
 */
export function getInvoiceUrl( type, resourceId, orderId = null ) {
	if ( type === "order" ) {
		return getApiUrl() + "/Orders/" + resourceId + "/invoice?access_token=" + getAccessToken( appPrefersImpersonator() );
	}

	if ( type === "refund" ) {
		return getApiUrl() + "/Orders/" + orderId + "/refunds/" + resourceId + "/invoice?access_token=" + getAccessToken( appPrefersImpersonator() );
	}

	return getApiUrl() + "/ProductSwitches/" + resourceId + "/invoice?access_token=" + getAccessToken( appPrefersImpersonator() );
}

/**
 * Returns the URL for downloading the profile of the user with the given ID (in CSV format).
 *
 * @param {string} userId the ID of the user
 * @returns {string} the URL to the profile
 */
export function getDownloadProfileUrl( userId ) {
	const path = `Customers/${ userId }/download/`;
	return `${ getApiUrl() }/${ path }?access_token=${ getAccessToken( appPrefersImpersonator() ) }`;
}

/**
 * Handles a JSON response.
 *
 * @param {response} response The response.
 *
 * @returns {Promise.<*>} A promise with the parsed JSON data.
 */
export const handleJSONResponse = response => {
	if ( ! response ) {
		return Promise.reject( "Could not read response data!" );
	}

	if ( response.status === 200 ) {
		return response.json();
	}

	if ( response.status === 201 ) {
		return response.json();
	}

	if ( response.status === 204 ) {
		return Promise.resolve( null );
	}

	if ( response.status === 400 ) {
		return response.json();
	}

	if ( response.status === 401 ) {
		window.location = getEnv( "LOGIN_URL", "https://my.yoast.test/login" ) + "?redirect_to=" + encodeURIComponent( window.location.href );
		return response.json();
	}

	if ( response.status === 404 ) {
		return Promise.reject( new Error( "Not found!" ) );
	}

	if ( response.status === 409 ) {
		return response.json();
	}

	return response.json()
		.then( ( json ) => {
			let error = new Error( `Invalid status code: ${ response.status }` );

			if ( json.code && json.message ) {
				error = new Error( json.message, json.code );
			}

			throw error;
		} );
};
