import {
	LOGIN_FAILURE,
	LOGIN_OAUTH_FAILURE,
	LOGIN_OAUTH_RESET,
	LOGIN_REQUEST,
	REQUIRE_OTP,
} from "shared-frontend/redux/actions/login";
import { LOGIN } from "shared-frontend/redux/actions/user";

/**
 * Initial state
 */
const rootState = {
	login: {
		loading: false,
		error: null,
		oauthError: false,
		requireOTP: false,
		amountOfOTPWarnings: 0,
	},
};

/**
 * Changes the 2FA error to a warning the first time it is presented.
 *
 * @param {Object} error The login error, possibly being a 2FA error.
 * @param {number} amountOfOTPWarnings The number of times the OTP error has been shown.
 *
 * @returns {Object} The error.
 */
function warnOnFirstOtpError( error, amountOfOTPWarnings ) {
	if ( amountOfOTPWarnings === 1 && error.message === "Your verification code is incorrect or has expired." ) {
		error.message = "Please enter your two-factor verification code.";
		error.type    = "warning";
	}
	return error;
}

/**
 * Reducers
 */

/* eslint-disable complexity */
/**
 * A reducer for the orders object within the ui object.
 *
 * @param {Object} state The current state of the object.
 * @param {Object} action The current action received.
 * @returns {Object} The updated ui object.
 */
export function uiLoginReducer( state = rootState.login, action ) {
	switch ( action.type ) {
		case LOGIN:
			return Object.assign( {}, state, {
				loading: false,
				error: null,
				requireOTP: false,
				amountOfOTPWarnings: 0,
			} );
		case LOGIN_FAILURE:
			return Object.assign( {}, state, {
				loading: false,
				error: warnOnFirstOtpError( action.error, state.amountOfOTPWarnings ),
			} );
		case LOGIN_OAUTH_FAILURE:
			return Object.assign( {}, state, {
				loading: false,
				error: warnOnFirstOtpError( action.error, state.amountOfOTPWarnings ),
				oauthError: true,
			} );
		case LOGIN_REQUEST:
			return Object.assign( {}, state, {
				loading: true,
				error: null,
			} );
		case LOGIN_OAUTH_RESET:
			return Object.assign( {}, state, {
				error: null,
				oauthError: false,
			} );
		case REQUIRE_OTP:
			return Object.assign( {}, state, {
				requireOTP: true,
				amountOfOTPWarnings: state.amountOfOTPWarnings + 1,
			} );
		default:
			return state;
	}
}

/* eslint-enable complexity */
