import React from "react";
import { applyMiddleware, compose, createStore } from "redux";
import { createRoot } from "react-dom/client";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import "./index.css";
import App from "./App";
import rootReducer from "./redux/reducers";
import { fetchUser, login } from "shared-frontend/redux/actions/user";
import analyticsMiddleware from "./config/Analytics";
import {
	cleanupOldAuthCookies,
	fetchAccessToken,
	getAccessToken,
	getUserId,
	hasAccessToken,
	hasMalformedAccessToken,
	removeCookies,
} from "shared-frontend/functions/auth";

const history = createBrowserHistory();

/**
 * If we are in a development environment, we want the store to include the redux-logger.
 * On a production build we want the logger to be omitted.
 */
const middleware = [
	thunkMiddleware,
	routerMiddleware( history ),
	analyticsMiddleware,
];

if ( process.env.NODE_ENV === "development" ) {
	middleware.push( createLogger() );
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store     = createStore(
	rootReducer( history ),
	composeEnhancers(
		applyMiddleware( ...middleware ),
	),
);

/**
 * Checks if the current instance is in an Iframe.
 *
 * @returns {boolean} If the instance is in an Iframe.
 */
const inIframe = () => {
	try {
		return window.self !== window.top;
	} catch ( e ) {
		return true;
	}
};

/**
 * Login in the user.
 *
 * @returns {void}
 */
const loginUser = async() => {
	cleanupOldAuthCookies();

	if ( ! hasAccessToken() && ! inIframe() ) {
		await fetchAccessToken();
	}
	if ( ! hasAccessToken() ) {
		return;
	}
	if ( hasMalformedAccessToken() ) {
		removeCookies();
		return;
	}

	store.dispatch( login( getAccessToken(), getUserId() ) );
	store.dispatch( fetchUser( getUserId() ) );
};

/**
 * Bootstrapping the App, so that we can call it after checking whether users need to be redirected.
 *
 * @returns {void}
 */
const app = async() => {
	try {
		loginUser();
	} catch ( error ) {
		// Do nothing
	} finally {
		const root = createRoot( document.getElementById( "root" ) );
		root.render( <App store={ store } history={ history } /> );
	}
};

if ( global.Intl ) {
	app();
} else {
	require.ensure(
		[ "intl", "intl/locale-data/jsonp/en.js" ],
		require => {
			require( "intl" );
			require( "intl/locale-data/jsonp/en.js" );
			app();
		},
	);
}
