import React from "react";
import styled from "styled-components";

import { defineMessages, FormattedMessage } from "react-intl";
import { Button } from "@yoast/ui-library";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import styles from "./FormElementsStyles.scss";

const messages = defineMessages( {
	discardChanges: {
		id: "discard.changes",
		defaultMessage: "Discard changes",
	},
	saveChanges: {
		id: "change.save.button",
		defaultMessage: "Save changes",
	},
} );

export const ButtonArea = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
`;

/**
 * Generates a row of buttons to save or discard changes in a form.
 *
 * @param {boolean} isSaveButtonEnabled Whether the save button should be enabled.
 * @param {boolean} isSaving Whether the form is currently in the progress of saving.
 * @param {boolean} isSaved Whether the form has been saved.
 * @param {function} discardChanges Callback function to call when the discard button is pressed.
 *
 * @returns {React.Component} The component with the change buttons.
 */
export function getChangeButtons( isSaveButtonEnabled, isSaving, isSaved, discardChanges ) {
	return (
		<div className={ styles.buttonsWrapper }>
			<Button
				type="submit"
				isLoading={ isSaving && ! isSaved }
				disabled={ ! isSaveButtonEnabled }
			>
				{ isSaved && ! isSaving && <CheckCircleIcon className={ styles.savedIcon } /> }
				<FormattedMessage
					id={ messages.saveChanges.id }
					defaultMessage={ messages.saveChanges.defaultMessage }
				/>
			</Button>
			<Button
				onClick={ discardChanges }
				variant="secondary"
			>
				<FormattedMessage
					id={ messages.discardChanges.id }
					defaultMessage={ messages.discardChanges.defaultMessage }
				/>
			</Button>
			{ isSaved && ! isSaving && <span className={ styles.savedMessage }>
				<FormattedMessage id="changes.saved" defaultMessage="Changes saved" />
			</span>
			}
		</div>
	);
}
