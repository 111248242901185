import { getShopUrl } from "./products";

/**
 * Creates a cart URL for a given shopId and mapping of product IDs and quantities.
 * If subscription product has WooCommerce status of "private" that means that it is an "old product",
 * in which case we generate relevant shop page URL.
 *
 * @param {int}           shopId            The shop id.
 * @param {string}        productShopStatus Subscription product status in the WooCommerce.
 * @param {Object}        productQuantities A mapping of product id to quantity. ({[string]: int})
 *
 * @returns {string} The cart or shop page URL.
 */
// eslint-disable-next-line complexity,require-jsdoc
export function createCartUrl( shopId, productShopStatus, productQuantities ) {
	let url = getShopUrl();

	switch ( shopId ) {
		case 2:
			url += "/eu";
			break;
		case 7:
			url += "/uk";
			break;
	}

	if ( productShopStatus === "private" || productShopStatus === "draft" ) {
		return url + "/shop";
	}

	url += "/cart/?multiple-add-to-cart=";

	const queryValue = [];
	for ( const productId in productQuantities ) {
		if ( ! Object.prototype.hasOwnProperty.call( productQuantities, productId ) ) {
			continue;
		}

		const quantity = productQuantities[ productId ];

		queryValue.push( `${productId}:${quantity}` );
	}

	return url + queryValue.join( "," );
}

/**
 * Creates a cart url for a subscription.
 *
 * @param {Subscription} subscription The subscription.
 *
 * @returns {string} The cart url.
 */
export function createCartUrlForSubscription( subscription ) {
	return createCartUrl(
		subscription.product.sourceShopId,
		subscription.product.shopStatus,
		{ [ subscription.product.sourceId ]: subscription.limit },
	);
}
