import _union from "lodash/union";

import { GET_REFUNDS_SUCCESS } from "../actions/refunds";
import { LOGOUT_SUCCESS } from "../actions/user";

/**
 * Initial state
 */
const initialState = {
	refunds: {
		byId: {},
		allIds: [],
	},
};

/**
 * Reducers
 */

/**
 * A reducer for the byIdRefundsReducer list.
 *
 * @param {Object} state The current state of the byIdRefundsReducer list.
 * @param {Object} action The current action received.
 * @returns {Object} The updated byIdRefundsReducer object.
 */
export function byIdRefundsReducer( state = initialState.refunds.byId, action ) {
	let refunds;

	switch ( action.type ) {
		case GET_REFUNDS_SUCCESS:
			refunds = Object.assign( {}, state );

			action.refunds.forEach( ( refund ) => {
				refunds[ refund.id ] = refund;
			} );

			return refunds;
		case LOGOUT_SUCCESS:
			return initialState.refunds.byId;
		default:
			return state;
	}
}

/**
 * A reducer for the allIdsRefundsReducer list.
 *
 * @param {Array} state The current state of the allIdsRefundsReducer list.
 * @param {Object} action The current action received.
 * @returns {Array} The updated allIdsRefundsReducer array.
 */
export function allIdsRefundsReducer( state = initialState.refunds.allIds, action ) {
	switch ( action.type ) {
		case GET_REFUNDS_SUCCESS:
			return _union( state, action.refunds.map( refund => refund.id ) );
		case LOGOUT_SUCCESS:
			return initialState.refunds.allIds;
		default:
			return state;
	}
}
