import React, { useEffect } from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import { ErrorPropTypeShape } from "../../errors/ErrorDisplay";
import { NavLink, Redirect } from "react-router-dom";
import queryString from "query-string";
import PropTypes from "prop-types";
import LoginMessage from "./LoginMessage";
import getEnv from "shared-frontend/functions/getEnv";
import { Alert, Button, Link } from "@yoast/ui-library";
import styles from "./ActivateStyles.scss";

const messages = defineMessages( {
	buttonToLoginPage: {
		id: "activate.loginButton",
		defaultMessage: "Go to the login page",
	},
	userAlreadyActive: {
		id: "activate.alreadyActive",
		defaultMessage: "Your account was already activated",
	},
	genericActivationFailed: {
		id: "activate.genericActivationFailed",
		defaultMessage: "Activating your account failed: {errorMessage}",
	},
	linkToYoast: {
		id: "activate.link.to.yoast",
		defaultMessage: "Go to yoast.com",
	},
} );

// eslint-disable-next-line require-jsdoc
function getActivationError( isLoading, activationError ) {
	if ( isLoading || ! activationError ) {
		return null;
	}

	if ( activationError.code === "already_active" ) {
		return <Alert variant="info" role="alert">
			<FormattedMessage { ...messages.userAlreadyActive } />
		</Alert>;
	}

	return <Alert variant="error" role="alert">
		<FormattedMessage
			{ ...messages.genericActivationFailed }
			values={ { errorMessage: activationError.message } }
		/>
	</Alert>;
}

// eslint-disable-next-line require-jsdoc
function goToLoginPageButton( resetOauthError ) {
	return <Button as={ NavLink } to={ "/login" } onClick={ resetOauthError }>
		<FormattedMessage { ...messages.buttonToLoginPage } />
	</Button>;
}

// eslint-disable-next-line require-jsdoc
function getLinkToYoastcom() {
	return (
		<Link
			className={ styles.activateActionsLink }
			href={ getEnv( "SHOP_URL", "https://yoast.test" ) }
			aria-label={ messages.linkToYoast.defaultMessage }
		>
			<FormattedMessage { ...messages.linkToYoast } /> &rarr;
		</Link>
	);
}

// eslint-disable-next-line require-jsdoc
export const Activate = ( props ) => {
	const parsedQuery = queryString.parse( props.location.search, { arrayFormat: "bracket" } );
	const key = parsedQuery.key || "";

	useEffect( () => {
		if ( key ) {
			props.activateUser( key );
		}
	}, [ key ] );

	if ( props.loggedIn ) {
		return ( <Redirect to={ "/enter-details" } /> );
	}

	if ( ! key ) {
		return ( <Redirect to={ "/login" } /> );
	}

	const error = getActivationError( props.loading, props.activationError );

	const showButton = ( props.activationError !== null && props.activationError.code === "already_active" );

	return <div className={ styles.activateContentWrapper }>
		<div className={ styles.loginMessageWrapper }>
			<LoginMessage asideType="activate" />
		</div>
		{ error }
		<div className={ styles.activateActionsWrapper }>
			{ showButton && goToLoginPageButton( props.resetOauthError ) }
			{ ( props.activationError && ! props.loading ) && getLinkToYoastcom() }
		</div>
	</div>;
};

Activate.propTypes = {
	loggedIn: PropTypes.bool,
	activateUser: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
	activationError: ErrorPropTypeShape,
	loading: PropTypes.bool,
	resetOauthError: PropTypes.func.isRequired,
};

Activate.defaultProps = {
	loggedIn: false,
	loading: false,
	activationError: null,
};

export default injectIntl( Activate );
