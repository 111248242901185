import PropTypes from "prop-types";
import React from "react";
import { injectIntl, defineMessages, FormattedMessage } from "react-intl";

// Custom components.
import { Button, Title } from "@yoast/ui-library";
import styles from "../../../ProfilePageStyles.scss";
import classNames from "classnames";

const messages = defineMessages( {
	labelDelete: {
		id: "profile.label.delete",
		defaultMessage: "Delete account",
	},
	deletingAccount: {
		id: "profile.deleting",
		defaultMessage: "Deleting your account...",
	},
	deleteAccount: {
		id: "profile.delete",
		defaultMessage: "Delete your account",
	},
	warningMessage: {
		id: "profile.delete.message",
		defaultMessage: "Warning! If you delete your account you lose access to" +
		" your downloads and you will no longer receive updates for any Premium" +
		" plugins you've bought from us.",
	},
} );

/**
 * The DeleteAccount component.
 */
class DeleteAccount extends React.Component {
	constructor( props ) {
		super( props );
		this.handleDelete = this.handleDelete.bind( this );
	}

	handleDelete( event ) {
		event.preventDefault();
		this.props.onDeleteProfile();
	}

	/**
	 * Creates the text to be displayed on the save button.
	 *
	 * @returns {string} Text to be used on the submit button.
	 */
	deleteButtonText() {
		return this.props.isDeleting
			? <FormattedMessage id={ messages.deletingAccount.id } defaultMessage={ messages.deletingAccount.defaultMessage } />
			: <FormattedMessage id={ messages.deleteAccount.id } defaultMessage={ messages.deleteAccount.defaultMessage } />;
	}

	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		return <form onSubmit={ this.handleDelete } className={ classNames( styles.pageSection, styles.noBorder ) }>
			<div className={ styles.sectionTitle }>
				<Title as="h2" size="4">
					<FormattedMessage
						id={ messages.labelDelete.id }
						defaultMessage={ messages.labelDelete.defaultMessage }
					/>
				</Title>
			</div>
			<div className={ styles.sectionContent }>
				<p>
					<FormattedMessage
						id={ messages.warningMessage.id }
						defaultMessage={ messages.warningMessage.defaultMessage }
					/>
				</p>
				<Button type="submit" disabled={ this.isDeleting } variant="error">{ this.deleteButtonText() }</Button>
			</div>
		</form>
		;
	}
}

DeleteAccount.propTypes = {
	onDeleteProfile: PropTypes.func.isRequired,
	isDeleting: PropTypes.bool,
};

DeleteAccount.defaultProps = {
	isDeleting: false,
};

export default injectIntl( DeleteAccount );
