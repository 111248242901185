// External dependencies.
import React from "react";
import PropTypes from "prop-types";

// Internal dependencies.
import getEnv from "shared-frontend/functions/getEnv";
import { defineMessages, FormattedMessage } from "react-intl";
import { Link } from "@yoast/ui-library";
import styles from "./LoginColumnLayoutStyles.scss";

const messages = defineMessages( {
	linkToYoast: {
		id: "link.to.yoast",
		defaultMessage: "Go to yoast.com",
	},
} );

/**
 * A one or two column layout for a login / registration / reset password page.
 *
 * First child component is displayed in the left column, second is displayed on the right.
 *
 * Reverts to a one column layout when only one child is given.
 * When more then two child components are given, only the first two are shown.
 *
 * @returns {ReactElement} A two column layout containing the two children as columns.
 */
class LoginColumnLayout extends React.Component {
	/**
	 * Renders the component.
	 *
	 * @returns {ReactElement} The rendered component.
	 */
	render() {
		const twoColumns = this.props.children.length > 1;

		return (
			<div className={ styles.columnLayout }>
				<div className={ styles.firstColumn }>
					{ twoColumns ? this.props.children[ 0 ] : this.props.children }
				</div>
				<div className={ styles.secondColumn }>
					{ twoColumns ? this.props.children[ 1 ] : null }
				</div>
				<div className={ styles.linkColumn }>
					<Link href={ getEnv( "SHOP_URL", "https://yoast.com" ) } target="_blank">
						<FormattedMessage { ...messages.linkToYoast } /> &rarr;
					</Link>
				</div>
			</div>
		);
	}
}

LoginColumnLayout.propTypes = {
	children: PropTypes.any.isRequired,
};

export default LoginColumnLayout;
