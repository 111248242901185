import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import AddNewSite from "./AddNewSite";
import SelectExistingSite from "./SelectExistingSite";
import { noop } from "shared-frontend/functions/noop";

/**
 * Initializes the site selector for new or existing sites.
 *
 * @param {Object} props The component properties.
 *
 * @returns {ReactComponent} The rendered component.
 */
const SelectOrAddSite = ( props ) => {
	const [ selectedSiteUrl, setSelectedSiteUrl ] = useState( "" );
	const [ newSiteUrl, setNewSiteUrl ] = useState( "" );
	const [ validatedNewSiteUrl, setValidatedNewSiteUrl ] = useState( "" );
	const [ disableExistingSite, setDisableExistingSite ] = useState( false );
	const [ disableNewSite, setDisableNewSite ] = useState( false );

	/**
	 * Gets the current value of the selected or new site url. An empty string if both are set (which is an invalid state).
	 *
	 * @returns {string} The current value of the selected or new site url.
	 */
	function getSiteValue() {
		return selectedSiteUrl || validatedNewSiteUrl;
	}

	useEffect( () => {
		setDisableNewSite( selectedSiteUrl !== "" );
		setDisableExistingSite( newSiteUrl !== "" );

		props.onSiteUrlChange( getSiteValue() );
	}, [ selectedSiteUrl, newSiteUrl, validatedNewSiteUrl ] );

	/**
	 * Renders the selectOrAddSite component.
	 *
	 * @returns {ReactElement} The selectOrAddSite component.
	 */
	return (
		<Fragment>
			<AddNewSite
				newSiteUrl={ newSiteUrl }
				onUrlChange={ setNewSiteUrl }
				onValidUrl={ setValidatedNewSiteUrl }
				disabled={ disableNewSite }
			/>

			{ props.existingSites.length !== 0 &&
			<SelectExistingSite
				selectedSiteUrl={ selectedSiteUrl }
				selectionChanged={ setSelectedSiteUrl }
				existingSites={ props.existingSites }
				disabled={ disableExistingSite }
			/>
			}
		</Fragment>
	);
};

SelectOrAddSite.propTypes = {
	onSiteUrlChange: PropTypes.func,
	existingSites: PropTypes.array,
};

SelectOrAddSite.defaultProps = {
	onSiteUrlChange: noop,
	existingSites: [],
};

export default SelectOrAddSite;
