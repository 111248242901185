import PropTypes from "prop-types";
import React from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import { Label, TextInput } from "@yoast/ui-library";
import styles from "./SearchStyles.scss";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

const messages = defineMessages( {
	searchLabel: {
		id: "search.label",
		defaultMessage: "Search sites",
	},
	description: {
		id: "search.description",
		defaultMessage: "The search results will be updated as you type.",
	},
} );

/**
 * Renders the Search component.
 *
 * @param {Object} props The props to use.
 * @returns {ReactElement} The rendered html.
 */
function Search( props ) {
	/**
	 * Callback when the search request has been changed.
	 *
	 * @param {event} event The new search request.
	 *
	 * @returns {void}
	 */
	const changeSearchQuery = ( event ) => {
		props.onChange( event.target.value );
	};

	const searchLabel = props.searchLabel ? props.searchLabel : props.intl.formatMessage( messages.searchLabel );

	return <div>
		<Label className={ styles.searchLabel } htmlFor={ props.id }>
			{ searchLabel }
		</Label>
		<div className={ styles.searchInputWrapper }>
			<MagnifyingGlassIcon className={ styles.searchIcon } />
			<TextInput
				type="search"
				id={ props.id }
				className={ styles.searchInput }
				value={ props.query }
				aria-describedby={ props.descriptionId }
				onChange={ changeSearchQuery }
				placeholder={ searchLabel }
				autoComplete="off"
				autoCorrect="off"
				autoCapitalize="off"
				spellCheck="false"
			/>
		</div>
		<p className="screen-reader-text" id={ props.descriptionId }>
			{ props.description ? props.description : props.intl.formatMessage( messages.description ) }
		</p>
	</div>;
}

export default injectIntl( Search );

Search.propTypes = {
	searchLabel: PropTypes.string,
	id: PropTypes.string.isRequired,
	description: PropTypes.string,
	descriptionId: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	query: PropTypes.string,
	intl: intlShape.isRequired,
};
