import LocalIcon from "../../icons/newIcons/local_plugin_assistant_icon_optm.svg";
import VideoIcon from "../../icons/newIcons/video_plugin_assistant_optm.svg";
import WooIcon from "../../icons/newIcons/woo_plugin_assistant_optm.svg";
import NewsIcon from "../../icons/newIcons/news_plugin_assistant_optm.svg";
import AllPluginsIcon from "../../icons/newIcons/all_plugins_optm.svg";
import UpsellCard from "./UpsellCard";
import React from "react";

const pluginUpsellId = "plugin.upsell";
const pluginUpsellProps = [
	{
		icon: LocalIcon,
		description: {
			id: "local.description",
			defaultMessage: "Be found in Google Maps and local results.",
		},
		link: {
			name: "Get Local SEO",
			url: "https://yoast.com/wordpress/plugins/local-seo/",
		},
	},
	{
		icon: VideoIcon,
		description: {
			id: "video.description",
			defaultMessage: "Be found in Google Video search and enhance your video sharing on social media.",
		},
		link: {
			name: "Get Video SEO",
			url: "https://yoast.com/wordpress/plugins/video-seo/",
		},
	},
	{
		icon: WooIcon,
		description: {
			id: "woocommerce.description",
			defaultMessage: "Optimize your shop's SEO and sell more products!",
		},
		link: {
			name: "Get WooCommerce SEO",
			url: "https://yoast.com/wordpress/plugins/yoast-woocommerce-seo/",
		},
	},
	{
		icon: NewsIcon,
		description: {
			id: "news.description",
			defaultMessage: "Optimize your site for Google News",
		},
		link: {
			name: "Get News SEO",
			url: "https://yoast.com/wordpress/plugins/news-seo/",
		},
	},
	{
		icon: AllPluginsIcon,
		description: {
			id: "all.description",
			defaultMessage: "Cover all your SEO bases with all 5 Yoast plugins.",
		},
		link: {
			name: "Get all Yoast plugins",
			url: "https://yoast.com/subscriptions/",
		},
	},
];

/**
 * PluginUpsell component.
 * @returns {JSX.Element} Upsell card.
 */
const PluginUpsell = () => {
	return (
		<UpsellCard
			id={ pluginUpsellId }
			header={ {
				id: "header",
				defaultMessage: "Outrank your competitors with Yoast extensions",
			} }
			listPropsArray={ pluginUpsellProps }
		/>
	);
};

export default PluginUpsell;
