import _isUndefined from "lodash/isUndefined";
import _pull from "lodash/pull";
import _remove from "lodash/remove";
import _union from "lodash/union";
import _unset from "lodash/unset";

import {
	LINK_SITE_SUCCESS,
	RETRIEVE_SITES_SUCCESS,
} from "../actions/sites";
import {
	SITE_ADD_SUBSCRIPTION_SUCCESS,
	SITE_REMOVE_SUBSCRIPTION_SUCCESS,
	SITE_REMOVE_SUCCESS,
	SITE_CHANGE_PLATFORM_SUCCESS,
	CHANGE_SITE_URL_SUCCESS,
} from "../actions/site";
import { LOGOUT_SUCCESS } from "../actions/user";


/**
 * Initial state
 */
const initialState = {
	sites: {
		byId: {},
		allIds: [],
	},
};

/**
 * Returns a new site object with only subscription ids.
 *
 * @param {Object} site The site to pluck.
 * @returns {Object} New sites object with only subscription ids.
 */
function pluckSubscriptionIds( site ) {
	if ( _isUndefined( site.subscriptions ) ) {
		return Object.assign( {}, site, { subscriptions: [] } );
	}

	return Object.assign( {}, site, { subscriptions: site.subscriptions.map( subscription => subscription.id ) } );
}

/**
 * This function changes the site type of the site from wordpress.com to wordpress.
 *
 * @param {Object} site The site to change.
 *
 * @returns {Object} The changed site.
 */
function simplifySiteType( site ) {
	if ( site.type === "wordpress.com" ) {
		site.type = "wordpress";
	}
	return site;
}

/**
 * A reducer for the byId object.
 *
 * @param {Object} state The current state of the byId object.
 * @param {Object} action The current action received.
 *
 * @returns {Object} The updated byId object.
 */
export function byIdReducer( state = initialState.sites.byId, action ) {
	const sites = Object.assign( {}, state );

	switch ( action.type ) {
		case LINK_SITE_SUCCESS:
			sites[ action.site.id ] = pluckSubscriptionIds( action.site );
			break;

		case RETRIEVE_SITES_SUCCESS:
			action.sites.forEach( ( site ) => {
				site = pluckSubscriptionIds( site );
				sites[ site.id ] = simplifySiteType( site );
			} );
			break;

		case SITE_CHANGE_PLATFORM_SUCCESS:
			sites[ action.siteId ].type = action.siteType;
			break;

		case CHANGE_SITE_URL_SUCCESS:
			sites[ action.siteId ].type = action.siteType;
			break;

		case SITE_ADD_SUBSCRIPTION_SUCCESS:
			sites[ action.siteId ].subscriptions.push( action.subscriptionId );
			break;

		case SITE_REMOVE_SUBSCRIPTION_SUCCESS:
			_remove( sites[ action.siteId ].subscriptions, subscriptionId => subscriptionId === action.subscriptionId );
			break;

		case SITE_REMOVE_SUCCESS:
			_unset( sites, action.siteId );
			break;
		case LOGOUT_SUCCESS:
			return initialState.sites.byId;
	}

	return sites;
}
/* eslint-enable complexity */

/**
 * A reducer for the allIds array.
 *
 * @param {Array} state The current state of the allIds array.
 * @param {Object} action The current action received.
 *
 * @returns {Array} The updated allIds array.
 */
export function allIdsReducer( state = initialState.sites.allIds, action ) {
	let sites;
	switch ( action.type ) {
		case LINK_SITE_SUCCESS:
			return [ ...state, action.site.id ];
		case RETRIEVE_SITES_SUCCESS:
			sites = _union( state, action.sites.map( site => site.id ) );
			return sites;
		case SITE_REMOVE_SUCCESS:
			sites = _pull( state, action.siteId );
			return sites;
		case LOGOUT_SUCCESS:
			return initialState.sites.allIds;
		default:
			return state;
	}
}
