/**
 * Groups objects by particular property inside array.
 *
 * @param {Array} items Initial array of objects.
 * @param {string} key An object property by which you want to group.
 * @returns {Object} Object consisted of grouped arrays.
 */
module.exports = function( items, key ) {
	return items.reduce(
		( previousItem, currentItem ) => {
			return {
				...previousItem,
				[ currentItem[ key ] ]: [
					...( previousItem[ currentItem[ key ] ] || [] ),
					currentItem,
				],
			};
		},
		[],
	);
};
