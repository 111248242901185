import React, { Component } from "react";
import classNames from "classnames";
import DocumentTitle from "react-document-title";
import { getDocumentTitle } from "../helpers";

import styles from "./styles.scss";
import SkipLink from "../../SkipLink";
import { FormattedMessage } from "react-intl";

/**
 * Wraps a component in a layout with a white background
 *
 * @param {ReactElement} WrappedComponent The React component to wrap.
 * @param {string}       documentTitle    The document title.
 *
 * @returns {ReactElement} The layout with a white background with the wrapped component.
 */
export default ( WrappedComponent, documentTitle ) => {
	return class WhiteLayout extends Component {
		/**
     * Renders the component
     *
     * @returns {ReactElement} The rendered component.
     */
		render() {
			return (
				<DocumentTitle title={ getDocumentTitle( documentTitle ) }>
					<div className={ styles.layoutWhite }>
						<SkipLink>
							<FormattedMessage id="skiplink" defaultMessage="Skip to main content" />
						</SkipLink>
						<div className={ classNames( styles.main, styles.white ) }>
							<WrappedComponent { ...this.props } />
						</div>
					</div>
				</DocumentTitle>
			);
		}
	};
};
