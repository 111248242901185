import React from "react";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import icon from "../images/greeting.png";
import logo from "../images/my-yoast-academy-logo.svg";
import { Title } from "@yoast/ui-library";
import styles from "./ActivateAsideStyles.scss";

const messages = defineMessages( {
	header: {
		id: "activate.aside.header",
		defaultMessage: "Welcome!",
	},
	message: {
		id: "activate.aside.message",
		defaultMessage: "We are activating your MyYoast account. Please stay with us for a little while longer.",
	},
} );

/**
 * Display's the left side with the Signup branding.
 *
 * @returns {ReactElement} The component that contains the message.
 */
function ActivateAside() {
	return (
		<div className={ styles.firstColumn }>
			<img className={ styles.asideLogo } src={ logo } alt="MyYoast - Yoast Academy" />
			<img className={ styles.asideIcon }  src={ icon } alt="" />
			<Title size="2">
				<FormattedMessage { ...messages.header } />
			</Title>
			<p>
				<FormattedMessage { ...messages.message } />
			</p>
		</div>
	);
}

export default injectIntl( ActivateAside );
