import { defineMessages } from "react-intl";
import React from "react";
import PropTypes from "prop-types";
import { ErrorPropTypeShape } from "../../errors/ErrorDisplay";
import AddSite from "../AddSite";
import { Modal } from "@yoast/ui-library";

const modalAriaLabel = defineMessages( {
	id: "modal.arialabel.add",
	defaultMessage: "Add a new site",
} );

/**
 * Returns the AddSite modal.
 *
 * @param {Object} props The props required.
 * @returns { ReactElement } The AddSite modal.
 */
const AddSiteModal = ( props ) => (
	<Modal
		isOpen={ props.modalOpen }
		onClose={ props.onClose }
		aria-label={ props.intl.formatMessage( modalAriaLabel ) }
	>
		<Modal.Panel>
			<Modal.Title as="h2" size="2">Add site</Modal.Title>
			<AddSite
				onConnectClick={ props.onConnect }
				onCancelClick={ props.onClose }
				onChange={ props.onChange }
				errorFound={ props.errorFound }
				error={ props.linkError }
				query={ props.query }
				linkingSiteUrl={ props.linkingSiteUrl }
			/>
		</Modal.Panel>
	</Modal>
);


AddSiteModal.propTypes = {
	onConnect: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	errorFound: PropTypes.bool.isRequired,
	linkError: ErrorPropTypeShape,
	linkingSiteUrl: PropTypes.string,
	query: PropTypes.string,
	modalOpen: PropTypes.bool,
};

AddSiteModal.defaultProps = {
	query: "",
	linkingSiteUrl: "",
	modalOpen: false,
	linkError: null,
};

export default AddSiteModal;
