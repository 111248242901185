import "whatwg-fetch";

import { doRequest, getMyYoastHost, handleJSONResponse, prepareInternalRequest } from "../../functions/api";
import { getUserId, removeCookies as removeAuthCookies } from "../../functions/auth";

const host = getMyYoastHost();

/*
 * Action types
 */
export const LOGIN = "LOGIN";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

export const RESET_SAVE_MESSAGE = "RESET_SAVE_MESSAGE";

export const PASSWORD_UPDATE_REQUEST = "PASSWORD_UPDATE_REQUEST";
export const PASSWORD_UPDATE_FAILURE = "PASSWORD_UPDATE_FAILURE";
export const PASSWORD_UPDATE_SUCCESS = "PASSWORD_UPDATE_SUCCESS";

export const PROFILE_UPDATE_REQUEST = "PROFILE_UPDATE_REQUEST";
export const PROFILE_UPDATE_FAILURE = "PROFILE_UPDATE_FAILURE";
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS";

export const BEACON_DATA_SENT = "BEACON_DATA_SENT";

export const DISABLE_USER_START = "DISABLE_USER_START";
export const DISABLE_USER_FAILURE = "DISABLE_USER_FAILURE";
export const DISABLE_USER_SUCCESS = "DISABLE_USER_SUCCESS";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_USER_DATA_SEND = "GET_USER_DATA_SEND";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_FAILURE";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";

export const DELETE_USER_SEND = "DELETE_USER_SEND";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const ENABLE_CUSTOMER_SEND = "ENABLE_CUSTOMER_SEND";
export const ENABLE_CUSTOMER_SUCCESS = "ENABLE_CUSTOMER_SUCCESS";
export const ENABLE_CUSTOMER_FAILURE = "ENABLE_CUSTOMER_FAILURE";

/**
 * Action creators
 */

/**
 * An action creator for the login action.
 *
 * @param {string} accessToken A valid access token for the user.
 * @param {string} userId The user ID that wants to log in.
 * @returns {Object} A login action.
 */
export function login( accessToken, userId ) {
	return {
		type: LOGIN,
		data: {
			accessToken,
			userId,
		},
	};
}

/**
 * An action creator for the logout start action.
 *
 * @returns {Object} A logout start action.
 */
export function doingLogoutRequest() {
	return {
		type: LOGOUT_REQUEST,
	};
}

/**
 * An action creator for the logout success action.
 *
 * @returns {Object} A logout start action.
 */
export function logoutSuccess() {
	return {
		type: LOGOUT_SUCCESS,
	};
}

/**
 * An action creator for the logout failed action.
 *
 * @param {Object} error The error that occurred.
 *
 * @returns {Object} A logout failure action.
 */
export function logoutFailure( error ) {
	return {
		type: LOGOUT_FAILURE,
		error: error,
	};
}

/**
 * An action creator for the logout action.
 *
 * @returns {Object} A logout action.
 */
export function logout() {
	return ( dispatch ) => {
		dispatch( doingLogoutRequest() );

		const logoutRequest = prepareInternalRequest(
			"Customers/logout-user/",
			"POST",
			{},
			{ credentials: "include" },
		);

		doRequest( logoutRequest )
			.then( () => {
				removeAuthCookies();
				dispatch( logoutSuccess() );
			} )
			.catch( ( error ) => {
				/*
				 * A failed logout may indicate that there's something wrong with the user on the yoast.com side.
				 * They might have logged out on yoast.com since. To be sure, and get that back in sync,
				 * remove the cookie and attempt a new login on the next page load.
				 */
				removeAuthCookies();
				/*
				 * When the user was not logged in in the first place,
				 * we consider the logout a success after removing the MyYoast Auth cookies.
				 */
				if ( error.message === "You are not logged in" ) {
					dispatch( logoutSuccess() );
					return;
				}
				dispatch( logoutFailure( error ) );
			} );
	};
}

/**
 * An action creator for the request user action.
 *
 * @returns {Object} A request user action.
 */
export function requestUser() {
	return {
		type: FETCH_USER_REQUEST,
	};
}

/**
 * An action creator for the receive user action.
 *
 * @param {Object} user The user data that was successfully received.
 * @returns {Object} A receive user action.
 */
export function receiveUser( user ) {
	return {
		type: FETCH_USER_SUCCESS,
		user: user,
	};
}

/**
 * An action creator for the fetch user action.
 *
 * @param {string} userId The user ID for the user that we want to fetch.
 *
 * @returns {Object} A fetch user action.
 */
export const fetchUser = userId => async( dispatch ) => {
	dispatch( requestUser() );

	const profileRequest = prepareInternalRequest( `Customers/${ userId }/profile/` );
	try {
		const profileResult = await doRequest( profileRequest );

		const userData = profileResult.profile;
		window.dataLayer.push( {
			event: "is_eligible_for_premium_support",
			// eslint-disable-next-line camelcase
			is_eligible_for_premium_support: userData.isEligibleForPremiumSupport,
		} );

		return dispatch( receiveUser( profileResult ) );
	} catch ( e ) {
		removeAuthCookies();
	}
};

/**
 * An action creator for the request user action.
 *
 * @returns {Object} A request user action.
 */
export function disableUserStart() {
	return {
		type: DISABLE_USER_START,
	};
}

/**
 * An action creator for the receive user action.
 *
 * @returns {Object} A receive user action.
 */
export function disableUserSuccess() {
	return {
		type: DISABLE_USER_SUCCESS,
	};
}

/**
 * An action creator for the receive user action.
 *
 * @param {Object} error The error that was thrown.
 * @returns {Object} A disable user failure action.
 */
export function disableUserFailure( error ) {
	return {
		type: DISABLE_USER_FAILURE,
		error: error,
	};
}

/**
 * An action creator for the fetch user action.
 *
 * @returns {Object} A fetch user action.
 */
export function disableUser() {
	const userId = getUserId();

	return ( dispatch ) => {
		dispatch( disableUserStart() );

		const request = prepareInternalRequest( `Customers/${ userId }/`, "PATCH", { enabled: false } );

		return doRequest( request )
			.then( () => dispatch( disableUserSuccess() ) )
			.then( removeAuthCookies() )
			.catch( error => dispatch( disableUserFailure( error ) ) );
	};
}

/**
 * An action creator for the password update request action.
 *
 * @returns {Object} The password update request action.
 */
export function passwordUpdateRequest() {
	return {
		type: PASSWORD_UPDATE_REQUEST,
	};
}

/**
 * An action creator for the password update failure action.
 *
 * @param {Object} error The error that occurred.
 * @returns {Object} The password update failure action.
 */
export function passwordUpdateFailure( error ) {
	return {
		type: PASSWORD_UPDATE_FAILURE,
		error: error,
	};
}

/**
 * An action creator for the profile update success action.
 *
 * @param {Object} newPassword The password after a successful password update.
 * @returns {Object} The password update success action.
 */
export function passwordUpdateSuccess( newPassword ) {
	return {
		type: PASSWORD_UPDATE_SUCCESS,
		password: newPassword,
	};
}

/**
 * An action creator for the profile update request action.
 *
 * @param {string} subtype The kind of profileUpdate request.
 *
 * @returns {Object} The profile update request action.
 */
export function profileUpdateRequest( subtype = "" ) {
	return {
		type: PROFILE_UPDATE_REQUEST,
		subtype: subtype,
	};
}

/**
 * An action creator for the profile update failure action.
 *
 * @param {Object} error The error that occurred.
 * @param {string} subtype The kind of profileUpdate failure.
 * @returns {Object} The profile update failure action.
 */
export function profileUpdateFailure( error, subtype = "" ) {
	return {
		type: PROFILE_UPDATE_FAILURE,
		error: error,
		subtype: subtype,
	};
}

/**
 * An action creator for the profile update success action.
 *
 * @param {Object} newProfile The profile after a successful profile update.
 * @param {string} subtype The kind of profileUpdate success.
 *
 * @returns {Object} The profile update success action.
 */
export function profileUpdateSuccess( newProfile, subtype = "" ) {
	return {
		type: PROFILE_UPDATE_SUCCESS,
		profile: newProfile,
		subtype: subtype,
	};
}

/**
 * An action creator for the reset save message action.
 *
 * @returns {Object} The reset save message action.
 */
export function resetSaveMessage() {
	return {
		type: RESET_SAVE_MESSAGE,
	};
}

/**
 * An action creator to update the profile of the user.
 *
 * @param {Object} profile The profile object.
 * @param {string} profile.email The email to set on the profile.
 * @returns {Function} A function that can be dispatched to update a user's profile.
 */
export function updateProfile( profile ) {
	return ( dispatch ) => {
		dispatch( profileUpdateRequest() );

		const userId = getUserId();
		const request = prepareInternalRequest( `Customers/${ userId }/profile/`, "PATCH", profile );

		return doRequest( request )
			.then( ( response ) => {
				dispatch( profileUpdateSuccess( response ) );
			} )
			.catch( ( error ) => dispatch( profileUpdateFailure( error ) ) );
	};
}

/**
 * An action creator for beacon data sent action.
 *
 * @returns {Object} A beacon data sent action.
 */
export function sentUserDataToBeacon() {
	return {
		type: BEACON_DATA_SENT,
	};
}

/**
 * An action creator to update the profile of the user.
 *
 * @param {Object} passwords Object containing your old password, new password and password confirmation.
 * @returns {Function} A function that can be dispatched to update a user's password.
 */
export function updatePassword( passwords ) {
	return ( dispatch ) => {
		dispatch( passwordUpdateRequest() );

		const userId = getUserId();
		const request = prepareInternalRequest( `Customers/${ userId }/password/`, "PATCH", passwords );

		return doRequest( request )
			.then( ( response ) => dispatch( passwordUpdateSuccess( response ) ) )
			.catch( ( error ) => dispatch( passwordUpdateFailure( error ) ) );
	};
}


/**
 * Uploads an avatar image for the current user.
 * @param {File} image the image to upload
 * @returns {Function} Function to call when this action is dispatched.
 */
export function uploadAvatar( image ) {
	const actionSubtype = "imageUpload";
	return ( dispatch ) => {
		dispatch( profileUpdateRequest( actionSubtype ) );

		const userId = getUserId();

		const uploadData = new FormData();
		uploadData.append( "wp-user-avatars", image );

		// PrepareRequest set's this to JSON if not set. We want the browser to set it to correctly set the boundary.
		const uploadOptions = { headers: { "Content-Type": "let-the-browser-set-this" } };

		const uploadRequest = prepareInternalRequest( `Customers/${ userId }/avatar`, "POST", uploadData, uploadOptions );

		return doRequest( uploadRequest )
			.then( ( response ) => {
				dispatch( profileUpdateSuccess( response, actionSubtype ) );
			} )
			.catch( ( error ) => dispatch( profileUpdateFailure( error, actionSubtype ) ) );
	};
}

/**
 * An action creator that updates the reducer that the request will be send.
 *
 * @returns {Object} The action
 */
const enableCustomerSend = () => {
	return {
		type: ENABLE_CUSTOMER_SEND,
	};
};

/**
 * An action creator that updates the reducer that the request succeeded.
 *
 * @param {Object} customerId The enabled customerId
 *
 * @returns {Object} The action
 */
const enableCustomerSuccess = customerId => {
	return {
		type: ENABLE_CUSTOMER_SUCCESS,
		customerId,
	};
};

/**
 * An action creator that updates the reducer that the request failed.
 *
 * @returns {Object} The action
 */
const enableCustomerFailure = () => {
	return {
		type: ENABLE_CUSTOMER_FAILURE,
	};
};

/**
 * Sends a request to create an access token for the given customer.
 *
 * @param {string} customerId The ID of the customer to create an access token for.
 *
 * @returns {Promise.<Object>} A promise that returns the parsed results of the query. The token is under the id key.
 */
export const enableCustomer = customerId => async( dispatch, getState ) => {
	const accessToken = getState().user.accessToken;
	const url = host + "/api/Customers/" + customerId + "/enable?access_token=" + accessToken;

	dispatch( enableCustomerSend() );
	try {
		const rawResponse = await fetch( url, { method: "POST" } );
		await handleJSONResponse( rawResponse );

		dispatch( enableCustomerSuccess( customerId ) );
	} catch ( error ) {
		dispatch( enableCustomerFailure() );
	}
};

/**
 * An action creator that updates the reducer that the request will be sent.
 *
 * @returns {Object} The action
 */
const getUserDataSend = () => {
	return {
		type: GET_USER_DATA_SEND,
	};
};

/**
 * An action creator that updates the reducer that the request succeeded.
 *
 * @param {Object} userData data about a user.
 *
 * @returns {Object} The action.
 */
const getUserDataSuccess = userData => {
	return {
		type: GET_USER_DATA_SUCCESS,
		userData,
	};
};

/**
 * An action creator that updates the reducer that the request failed.
 *
 * @returns {Object} The action.
 */
const getUserDataFailure = () => {
	return {
		type: GET_USER_DATA_FAILURE,
	};
};

/**
 * Collects all data for a user.
 *
 * @param {string} userId an id for a user.
 *
 * @returns {void} dipatches an action to the store.
 */
export const getUserData = userId => async( dispatch, getState ) => {
	const accessToken = getState().user.accessToken;
	const param       = encodeURIComponent( JSON.stringify( {
		include: [ "orders", "studentAdmissions", "buyerAdmissions", "sites", "identities", "subscriptions" ],
	} ) );
	const url         = `${ host }/api/Customers/${ userId }?filter=${ param }&access_token=${ accessToken }`;

	dispatch( getUserDataSend() );

	try {
		const rawResponse = await fetch( url, { method: "GET" } );
		const userData = await handleJSONResponse( rawResponse );

		dispatch( getUserDataSuccess( userData ) );
	} catch ( error ) {
		dispatch( getUserDataFailure() );
	}
};


/**
 * An action creator that updates the reducer that the request will be sent.
 *
 * @returns {Object} The action
 */
const deleteUserSend = () => {
	return {
		type: DELETE_USER_SEND,
	};
};

/**
 * An action creator that updates the reducer that the request succeeded.
 *
 * @param {Object} deletedUserId The Id of the user that has been deleted in the database.
 *
 * @returns {Object} The action.
 */
const deleteUserSuccess = deletedUserId=> {
	return {
		type: DELETE_USER_SUCCESS,
		deletedUserId,
	};
};

/**
 * An action creator that updates the reducer that the request failed.
 *
 * @param {string} userId the id for a user.
 * @param {number} status the status of the response.
 * @param {string} errorMessage the error message of the response.
 *
 * @returns {Object} The action.
 */
const deleteUserFailure = ( userId, status, errorMessage ) => {
	return {
		type: DELETE_USER_FAILURE,
		userId,
		status,
		errorMessage,
	};
};

/**
 * Deletes a user in the database. THis is irreversible
 *
 * @param {string} userId an id for a user.
 *
 * @returns {void} dipatches an action to the store.
 */
export const deleteUser = userId => async( dispatch, getState ) => {
	const accessToken = getState().user.accessToken;
	const url         = `${ host }/api/Customers/${ userId }/gdpr-delete?access_token=${ accessToken }`;

	dispatch( deleteUserSend( userId ) );

	const response = await fetch( url, { method: "DELETE", headers: { Accept: "application/json" } } );

	if ( response.status === 200 ) {
		dispatch( deleteUserSuccess( userId ) );
	} else {
		response.json().then( error => {
			dispatch( deleteUserFailure( userId, response.status, error.message ) );
		} );
	}
};

/**
 * Returns the URL for downloading the profile of the user with the given ID (in CSV format).
 *
 * @param {string} userId the ID of the user
 *
 * @returns {string} the URL to the profile
 */
export const downloadUserData = ( userId ) => async( dispatch, getState ) => {
	const accessToken = getState().user.accessToken;
	const url = `${ host }/api/Customers/${ userId }/download/?access_token=${ accessToken }`;

	const data = await fetch( url, { method: "GET", headers: { Accept: "application/json" } } );
	window.location.href = data.url;
};
