import { connect } from "react-redux";

import HomePage from "../components/home/HomePage";
import { isOnlyProvisionerSubscriptions } from "../redux/selectors/ui/subscriptions";

// eslint-disable-next-line require-jsdoc
const mapStateToProps = state => {
	return {
		isOnlyProvisionerSubscriptions: isOnlyProvisionerSubscriptions( state ),
	};
};

const HomePageContainer = connect(
	mapStateToProps,
)( HomePage );

export default HomePageContainer;
