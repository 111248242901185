import PropTypes from "prop-types";
import React from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import { Table } from "@yoast/ui-library";

import SiteSubscriptionRow from "../SiteSubscriptionRow";


const messages = defineMessages( {
	active: {
		id: "siteSubscriptions.subscription.active",
		defaultMessage: "%s is active",
	},
	inactive: {
		id: "siteSubscriptions.subscription.inactive",
		defaultMessage: "%s is inactive",
	},
} );

/**
 * Renders a subscriptions component.
 *
 * @param {Object} props The props to use.
 * @returns {ReactElement} The rendered Subscriptions component.
 */
function SiteSubscriptions( props ) {
	const isProductActive = ( plugin ) => {
		const subscriptions = props.activeSubscriptions;
		for ( let i = 0; i < subscriptions.length; i++ ) {
			let products = subscriptions[i].products;
			if ( ! Array.isArray( products ) ) {
				products = [ products ];
			}
			const productIds = products.map( product => product.id );

			if ( plugin.ids.some( id => productIds.includes( id ) ) ) {
				return true;
			}
		}
		return false;
	};

	const onToggleDisabled = ( sourceId ) => {
		const plugin = props.plugins.findLast( ( plugin ) => plugin.sourceId = sourceId );
		return props.onToggleDisabled( plugin ? plugin.storeUrl : "" );
	};

	return (
			<>
				<Table>
					<Table.Body>
						{ props.plugins.map(
								( plugin ) =>
										<SiteSubscriptionRow
												{ ...plugin }
												key={ plugin.glNumber || plugin.id }
												onMoreInfoClick={ props.onMoreInfoClick }
												onToggleDisabled={ onToggleDisabled }
												onToggleSubscription={ props.onToggleSubscription }
												togglingSubscription={ props.togglingSubscription }
												modalOpen={ props.modalOpen }
												onClose={ props.onClose }
												onShop={ plugin.storeUrl }
										/>
						)
						}
					</Table.Body>
				</Table>
			</>
	);
}

SiteSubscriptions.propTypes = {
	activeSubscriptions: PropTypes.arrayOf( PropTypes.object ),
	plugins: PropTypes.arrayOf( PropTypes.object ),
	intl: intlShape.isRequired,
};

SiteSubscriptions.defaultProps = {
	activeSubscriptions: [],
	plugins: [],
};

export default injectIntl( SiteSubscriptions );
