import { defineMessages } from "react-intl";

export const messages = defineMessages( {
	currentSubscription: {
		id: "subscriptionUpgradeTool.currentSubscription",
		defaultMessage: "Current subscription",
	},
	upgradeTo: {
		id: "subscriptionUpgradeTool.upgradeTo",
		defaultMessage: "Upgrade to",
	},
	whatIsIncluded: {
		id: "subscriptionUpgradeTool.whatIsIncluded",
		defaultMessage: "What's included",
	},
	updatedPaymentDetailsHeader: {
		id: "subscriptionUpgradeTool.updatedPaymentDetailsHeader",
		defaultMessage: "Updated payment details",
	},
	updatedPaymentDetails: {
		id: "subscriptionUpgradeTool.updatedPaymentDetails",
		defaultMessage: "Upgrade and get a {percentage}% bundle discount. " +
      "You won't be charged now, but your next billing date will be sooner. {link}",
	},
	updatedPaymentDetailsLink: {
		id: "subscriptionUpgradeTool.updatedPaymentDetailsLink",
		defaultMessage: "Learn more about how we recalculated your next billing date and renewal price.",
	},
	refundPolicyLabel: {
		id: "subscriptionUpgradeTool.refundPolicyLabel",
		defaultMessage: "I agree to the {link}",
	},
	screenReaderRefundPolicyLabel: {
		id: "subscriptionUpgradeTool.screenReaderRefundPolicyLabel",
		defaultMessage: "I agree to the refund policy for upgrades.",
	},
	refundPolicyLabelLink: {
		id: "subscriptionUpgradeTool.refundPolicyLabelLink",
		defaultMessage: "refund policy for upgrades.",
	},
	termsPolicyLabel: {
		id: "subscriptionUpgradeTool.termsPolicyLabel",
		defaultMessage: "I’ve read and accept the {link}",
	},
	screenReaderTermsPolicyLabel: {
		id: "subscriptionUpgradeTool.screenReaderTermsPolicyLabel",
		defaultMessage: "I’ve read and accept the terms of service.",
	},
	termsPolicyLabelLink: {
		id: "subscriptionUpgradeTool.termsPolicyLabelLink",
		defaultMessage: "terms of service.",
	},
	nextBilling: {
		id: "subscriptionUpgradeTool.nextBilling",
		defaultMessage: "Next billing",
	},
	nextRenewalPrice: {
		id: "subscriptionUpgradeTool.nextRenewalPrice",
		defaultMessage: "Next renewal price",
	},
	newNextBilling: {
		id: "subscriptionUpgradeTool.newNextBilling",
		defaultMessage: "Your new next billing date",
	},
	currentPrice: {
		id: "subscriptionUpgradeTool.currentPrice",
		defaultMessage: "your current renewal price",
	},
	newPrice: {
		id: "subscriptionUpgradeTool.newPrice",
		defaultMessage: "Your new renewal price",
	},
	nextBillingSubtext: {
		id: "subscriptionDetails.paymentDetails.nextBillingSubtext",
		defaultMessage: "/ {term} (ex. VAT and possible discounts)",
	},
	cancel: {
		id: "subscriptionUpgradeTool.cancel",
		defaultMessage: "Cancel",
	},
	close: {
		id: "subscriptionUpgradeTool.close",
		defaultMessage: "Close",
	},
	confirm: {
		id: "subscriptionUpgradeTool.confirm",
		defaultMessage: "Upgrade to bundle",
	},
	upgrading: {
		id: "subscriptionUpgradeTool.upgrading",
		defaultMessage: "Upgrading your subscription...",
	},
	errorOccurredWhileFetching: {
		id: "subscriptionUpgradeTool.errorOccurredWhileFetching",
		defaultMessage: "Oops! Something went wrong while preparing your potential upgrade paths.",
	},
	errorOccurredWhileUpdating: {
		id: "subscriptionUpgradeTool.errorOccurredWhileUpdating",
		defaultMessage: "Oops! Something went wrong while upgrading your subscription. Please try again.",
	},
	noValidUpgrades: {
		id: "subscriptionUpgradeTool.noValidUpgrades",
		defaultMessage: "There is no further upgrade path available for your subscription at this time. Please check again later!",
	},
	tryAgain: {
		id: "subscriptionUpgradeTool.tryAgain",
		defaultMessage: "Try again",
	},
	currentNextBilling: {
		id: "subscriptionUpgradeTool.currentNextBilling",
		defaultMessage: "your current next billing date",
	},
	upgradeSuccess: {
		id: "subscriptionUpgradeTool.upgradeSuccess",
		defaultMessage: "Congratulations! You've just upgraded to the winning combination of the {newProduct}.",
	},
	getStarted: {
		id: "subscriptionUpgradeTool.getStarted",
		defaultMessage: "Let's get started! Install your new plugin(s) on your site, start optimizing, and rank higher in the search results!",
	},
	install: {
		id: "subscriptionUpgradeTool.install",
		defaultMessage: "Install plugin(s) on your site",
	},
	checkboxesNotChecked: {
		id: "subscriptionUpgradeTool.checkboxesNotChecked",
		defaultMessage: "To proceed with your upgrade, please agree to the refund policy for upgrades and accept the terms of service.",
	},
} );
