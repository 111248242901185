import React from "react";
import { defineMessages, FormattedMessage, injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";

import Link from "../Link";
import Modal, { defaultModalClassName } from "../modals/Modal";

const messages = defineMessages( {
	modalHeading: {
		id: "modal.heading",
		defaultMessage: "Yoast SEO Installation",
	},
	noLicensesAvailableModalText: {
		id: "install.noLicense.modal.text",
		defaultMessage: "It appears all your plugins are in use. Visit the shop to get more, " +
			"or disable the plugin on one of your other sites via MyYoast.",
	},
	noLicensesAvailableModalCancelButton: {
		id: "install.noLicense.modal.button.cancel",
		defaultMessage: "Cancel",
	},
	noLicensesAvailableModalGoToShopButton: {
		id: "install.noLicense.modal.button.goToShop",
		defaultMessage: "Go to the shop",
	},
} );

const ButtonPlacement = styled.div`
			display: flex;
			justify-content: flex-end;
			gap: 16px;
		`;

/**
	 * Modal for when there are no licenses available.
	 *
	 * @param {Object} props The props.
	 *
	 * @returns {ReactComponent} The rendered component.
	 */
const NoLicenseModal = props => {
	return (
		<Modal
			title={ props.intl.formatMessage( messages.modalHeading ) }
			onRequestClose={ props.closeModal }
			className={ defaultModalClassName }
		>
			<p><FormattedMessage { ...messages.noLicensesAvailableModalText } /></p>
			<ButtonPlacement>
				<FormattedMessage { ...messages.noLicensesAvailableModalCancelButton }>
					{ ( msg ) => ( <button onClick={ props.closeModal } className="yoast-button">{ msg }</button> ) }
				</FormattedMessage>
				<Link
					linkTarget="_blank"
					className="yoast-button--primary yoast-button"
					to="https://yoast.com/shop/"
				>
					<FormattedMessage { ...messages.noLicensesAvailableModalGoToShopButton } />
				</Link>
			</ButtonPlacement>
		</Modal>
	);
};

NoLicenseModal.propTypes = {
	intl: intlShape.isRequired,
	closeModal: PropTypes.func.isRequired,
};

export default injectIntl( NoLicenseModal );
