import PropTypes from "prop-types";

export const subscriptionShape = PropTypes.shape( {
	name: PropTypes.string.isRequired,
	limit: PropTypes.number.isRequired,
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string,
	nextPayment: PropTypes.string,
	product: PropTypes.shape( {
		icon: PropTypes.string.isRequired,
		billingTerm: PropTypes.string,
		glNumber: PropTypes.string.isRequired,
	} ),
	status: PropTypes.string.isRequired,
	id: PropTypes.string,
	requiresManualRenewal: PropTypes.bool,
	provisionerId: PropTypes.string,
} );
