import { connect } from "react-redux";

import ProfileDetails from "../components/login/ProfileDetails";
import { getUserId } from "shared-frontend/functions/auth";
import { fetchUser, updateProfile } from "shared-frontend/redux/actions/user";
import { getSaveEmailError, isProfilePageSaved, isProfilePageSaving } from "shared-frontend/redux/selectors/user/profilePage";
import { getPendingRequests } from "shared-frontend/redux/selectors/user/user";
import { getProfile } from "shared-frontend/redux/selectors/user/data/profile";
import { getRouter } from "../redux/selectors/router/router";

/* eslint-disable require-jsdoc */
export const mapDispatchToProps = ( dispatch ) => {
	return {
		attemptSubmitProfile: ( nameDetails ) => {
			dispatch( updateProfile( nameDetails ) );
		},
		fetchProfile: () => {
			dispatch( fetchUser( getUserId() ) );
		},
	};
};

export const mapStateToProps = ( state ) => {
	return {
		stateRouter: getRouter( state ),
		savingProfile: isProfilePageSaving( state ),
		profileSaved: isProfilePageSaved( state ),
		profile: getProfile( state ),
		pendingRequests: getPendingRequests( state ),
		profileSaveError: getSaveEmailError( state ),

	};
};

const ProfileDetailsContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( ProfileDetails );

export default ProfileDetailsContainer;
