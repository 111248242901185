import { useState, useEffect } from "react";
import { prepareInternalRequest, doRequest } from "shared-frontend/functions/api";

/**
 * Executes a specific request and handles potential errors.
 *
 * @param {string} path              The path to send the request to.
 * @param {string} method            The HTTP method to use for the request.
 * @param {object} payload           The payload of the request.
 * @param {Object} additionalOptions An optional object containing options to be used by the request object.
 *
 * @returns {Object} An object that contains the result data, loading-state and error state.
 */
function useRequest( path, method = "GET", payload = {}, additionalOptions = {} ) {
	const [ trigger, setTrigger ]     = useState( true );
	const [ data, setData ]           = useState( [] );
	const [ isLoading, setIsLoading ] = useState( trigger );
	const [ error, setError ]         = useState( null );

	if ( ! path ) {
		return { data, isLoading, error };
	}

	const request     = prepareInternalRequest( path, method, payload, additionalOptions );
	/**
	 * Fires the request.
	 *
	 * @returns {void}
	 */
	const fireRequest = async() => {
		setIsLoading( true );
		try {
			setData( await doRequest( request ) );
		} catch ( err ) {
			console.error( err );
			setError( err );
		} finally {
			setIsLoading( false );
		}
	};

	/**
	 * Re-fires the request.
	 *
	 * @returns {void}
	 */
	const reload = () => {
		setTrigger( trigger + 1 );
	};

	useEffect( () => {
		if ( trigger ) {
			fireRequest();
		}
	}, [ trigger ] );

	return { data, isLoading, error, reload };
}

export default useRequest;
