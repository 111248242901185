import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { injectIntl, intlShape } from "react-intl";
import { Root } from "@yoast/ui-library";

/*
 * Makes the `aria-hidden="true"` attribute being applied on the root element
 * instead of the body.
 */
Modal.setAppElement( "#root" );

/**
 * Base my yoast modal.
 * @param {object} props Properties.
 * @returns {JSX.Element} Base modal.
 */
function BaseMyYoastModal( props ) {
	return (
		<div>
			<Modal
				isOpen={ props.isOpen }
				onRequestClose={ props.onClose }
				role="dialog"
				contentLabel={ props.intl.formatMessage( props.modalAriaLabel ) }
				overlayClassName={ `${ props.className } my-yoast-modal__overlay` }
				className={ `${ props.className } my-yoast-modal__content` }
			>
				<Root>
					{ props.children }
				</Root>
			</Modal>
		</div>
	);
}

BaseMyYoastModal.propTypes = {
	children: PropTypes.element,
	className: PropTypes.string,
	intl: intlShape.isRequired,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	modalAriaLabel: PropTypes.object.isRequired,
};

BaseMyYoastModal.defaultProps = {
	isOpen: false,
	children: null,
	className: "",
};

const MyYoastModal = styled( injectIntl( BaseMyYoastModal ) )`
	&.my-yoast-modal__overlay {
		position: fixed;
		inset: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--bg-color-overlay);
		z-index: 999;
	}

	&.my-yoast-modal__content {
		max-width: 90vw;
		max-height: 90vh;
		padding: var(--gap);
		background-color: var(--bg-color-white);
		border-radius: var(--border-radius);
		box-shadow: var(--box-shadow);
		overflow: auto;
	}

	&.my-yoast-modal__content .yst-root {
		position: relative;
	}

	.my-yoast-modal__actions {
		padding-top: 1em;
		text-align: right;
	}

	.my-yoast-modal__actions button {
		margin-left: 1em;
	}
`;

export default injectIntl( MyYoastModal );
