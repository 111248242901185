import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { Title } from "@yoast/ui-library";

import styles from "./styles.scss";

export const PageSection = ( props ) => {
  return (
    <div className={ classNames( styles.sectionWrapper, { [styles.fullWidth]: props.full } ) }>
      { props.title && <Title as="h3" size="3">
        <FormattedMessage
          id={ props.title.id }
          key={ props.title.id }
          defaultMessage={ props.title.defaultMessage }
        />
      </Title> }
      { props.children && <div className={ styles.children }>{ props.children }</div>}
    </div>
  )
}
