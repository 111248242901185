import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { injectIntl, FormattedMessage, defineMessages } from "react-intl";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { prepareInternalRequest, doRequest } from "shared-frontend/functions/api";
import getEnv from "shared-frontend/functions/getEnv";
import { SUBSCRIBE_NEWSLETTER_SUCCESS } from "../../../config/Analytics";
import { Alert, Button, Link } from "@yoast/ui-library";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import styles from "./SubscribeNewsletterStyles.scss";

const MAILING_SECRET = getEnv( "MAILING_LIST_SECRET", "MailSecret" );

const messages = defineMessages( {
	subscribeReasons: {
		id: "newsletter.subscribe.reasons",
		defaultMessage: "Subscribe to get weekly tips on optimizing your website's SEO, usability and conversion. " +
			"You'll also be the first to know about new features and other cool (free) plugins!",
	},
	subscribeButton: {
		id: "newsletter.subscribeButton",
		defaultMessage: "Subscribe",
	},
	unsubscribeParagraph: {
		id: "newsletter.unsubscribeParagraph",
		defaultMessage: "You are subscribed to the Yoast newsletter. If you want to unsubscribe, click the button below.",
	},
	unsubscribeButton: {
		id: "newsletter.unSubscribeButton",
		defaultMessage: "Unsubscribe",
	},
	subscribeFailed: {
		id: "newsletter.subscribe.failed",
		defaultMessage: "Subscribing to the newsletter did not succeed, please try it another time.",
	},
	unsubscribeFailed: {
		id: "newsletter.unsubscribe.failed",
		defaultMessage: "Unsubscribing to the newsletter did not succeed, please try it another time.",
	},
	privacyPolicy: {
		id: "newsletter.privacyPolicy",
		defaultMessage: "Yoast respects your privacy. Read our {link} on how we handle your personal information.",
	},
} );

/**
 * Alert to be displayed.
 * @param {Object} error Error object.
 * @returns {React.JSX.Element|string} Alert.
 */
const SubscribeNewsletterAlert = ( error ) => {
	return ! isEmpty( error.message ) && <Alert variant={ error.type }><span>{ error.message }</span></Alert>;
};

/**
 * Handles subscribing and unsubscribing to the Yoast newsletter.
 *
 * @param {Object} props The props.
 *
 * @returns {ReactComponent} The subscribe/unsubscribe newsletter component
 */
const SubscribeNewsletter = ( { email } ) => {
	const [ subscribeData, setSubscribeData ] = useState( [] );
	const [ isLoading, setIsLoading ] = useState( false );
	const [ error, setError ] = useState( { message: "", type: "" } );

	const dispatch = useDispatch();

	// Check the status of the newsletter subscription for the customer when the component is mounted.
	useEffect( () => {
		const request = prepareInternalRequest(
			"Mailing-list/isSubscribedToNewsletter",
			"GET",
			{},
			{ headers: { secret: MAILING_SECRET }, queryParams: { email: encodeURIComponent( email ) } },
		);

		/**
		 * Fires the request.
		 *
		 * @returns {void}
		 */
		const fireRequest = async() => {
			setIsLoading( true );
			try {
				setSubscribeData( await doRequest( request ) );
			} catch ( err ) {
				// This is an error that the user doesn't need to be informed of. The default will be the subscribe button.
			} finally {
				setIsLoading( false );
			}
		};
		fireRequest();
	}, [] );

	/**
	 * Subscribe customer to the Yoast newsletter.
	 *
	 * @returns {void}
	 */
	async function subscribeToNewsletter() {
		const request = prepareInternalRequest(
			"Mailing-list/subscribeToNewsletter",
			"POST",
			{},
		);
		try {
			setSubscribeData( await doRequest( request ) );
			// Make sure this still dispatches for GTM,  see src/config/Analytics.js.
			dispatch( { type: SUBSCRIBE_NEWSLETTER_SUCCESS, status: "subscribed" } );
		} catch ( err ) {
			setError( { message: messages.subscribeFailed.defaultMessage, type: "error" } );
		}
	}

	/**
	 * Unsubscribe customer to the Yoast newsletter.
	 *
	 * @returns {void}
	 */
	async function unsubscribeToNewsletter() {
		const request = prepareInternalRequest(
			"Mailing-list/unsubscribeFromNewsletter",
			"POST",
			{},
			{ headers: { secret: MAILING_SECRET, "Content-Type": "application/json" } },
		);

		try {
			setSubscribeData( await doRequest( request ) );
		} catch ( err ) {
			setError( { message: messages.unsubscribeFailed.defaultMessage, type: "error" } );
		}
	}

	const privacyPolicyLink = <Link
		className={ styles.externalLink }
		href={ "https://yoast.com/privacy-notice/" }
		target="_blank"
	>
		<span>privacy policy</span>
		<ArrowTopRightOnSquareIcon className={ styles.externalLinkIcon } />
	</Link>;

	/**
	 * The PrivacyPolicy.
	 *
	 * @returns {ReactElement} The PrivacyPolicyParagraph.
	 */
	function PrivacyPolicyParagraph() {
		return (
			<p>
				<FormattedMessage
					id={ messages.privacyPolicy.id }
					defaultMessage={ messages.privacyPolicy.defaultMessage }
					values={ { link: privacyPolicyLink } }
				/>
			</p>
		);
	}

	if ( isLoading ) {
		return <div />;
	}

	if ( subscribeData.status === "subscribed" ) {
		return (
			<div className={ styles.content }>
				<SubscribeNewsletterAlert error={ error } />

				<p>
					<FormattedMessage
						id={ messages.unsubscribeParagraph.id }
						defaultMessage={ messages.unsubscribeParagraph.defaultMessage }
					/>
				</p>

				<PrivacyPolicyParagraph />

				<Button
					onClick={ unsubscribeToNewsletter }
					variant="secondary"
				>
					<FormattedMessage
						id={ messages.unsubscribeButton.id }
						defaultMessage={ messages.unsubscribeButton.defaultMessage }
					/>
				</Button>
			</div>
		);
	}

	return (
		<div className={ styles.content }>
			<SubscribeNewsletterAlert error={ error } />

			<p>
				<FormattedMessage
					id={ messages.subscribeReasons.id }
					defaultMessage={ messages.subscribeReasons.defaultMessage }
				/>
			</p>

			<PrivacyPolicyParagraph />

			<Button onClick={ subscribeToNewsletter }>
				<FormattedMessage
					id={ messages.subscribeButton.id }
					defaultMessage={ messages.subscribeButton.defaultMessage }
				/>
			</Button>
		</div>
	);
};

SubscribeNewsletter.propTypes = {
	email: PropTypes.string,
};

SubscribeNewsletter.defaultProps = {
	email: "",
};

export default injectIntl( SubscribeNewsletter );
