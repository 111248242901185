import PropTypes from "prop-types";
import React from "react";
import DownloadItem from "./DownloadItem";
import styles from "./DownloadItemsStyles.scss";

/**
 * Returns the rendered Products component.
 *
 * @param {Object} props The props to use.
 *
 * @returns {JSX.Element} The rendered products page.
 */
function DownloadItems( props ) {
	const glNumbersFilter = [ "82120" ];

	return (
		<div className={ styles.downloadItemsWrapper }>
			{ props.products
			// Filter out "Yoast SEO Premium + Academy" so we don't get two downloads.
				.filter( product => ! glNumbersFilter.includes( product.glNumber ) )
				.map( product => {
					return <DownloadItem
						key={ product.glNumber }
						name={ product.name }
						glNumber={ product.glNumber }
						currentVersion={ product.currentVersion }
						icon={ product.icon }
						buttons={ product.buttons }
						composerToken={ props.composerToken }
						onComposerHelpModalOpen={ props.onComposerHelpModalOpen }
						onComposerHelpModalClose={ props.onComposerHelpModalClose }
						type={ product.category }
					/>;
				} ) }
		</div>
	);
}

export default DownloadItems;

DownloadItems.propTypes = {
	composerToken: PropTypes.object,
	onComposerHelpModalOpen: PropTypes.func,
	onComposerHelpModalClose: PropTypes.func,
	products: PropTypes.array,
};

DownloadItems.defaultProps = {
	onComposerHelpModalClose: () => {},
	onComposerHelpModalOpen: () => {},
};
