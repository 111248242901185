import React, { useState } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

export const InstallContext = React.createContext();

/**
 * @param {object} children the passed down children.
 *
 * @returns {object} the children with access to the context.
 */
export const InstallContextProvider = ( { children } ) => {
	const [ downloadZip, setDownloadZip ] = useState( "" );
	const [ linkedSite, setLinkedSite ]   = useState( false );
	const [ orderExist, setOrderExist ]   = useState( false );
	const [ invoiceNumberParam ]          = useState( queryString.parse( window.location.search ).invoice_number );

	const contextValue = {
		downloadZip,
		setDownloadZip,
		linkedSite,
		setLinkedSite,
		orderExist,
		setOrderExist,
		invoiceNumberParam,
	};

	return (
		<InstallContext.Provider value={ contextValue }>
			{ children }
		</InstallContext.Provider>
	);
};

InstallContextProvider.propTypes = {
	children: PropTypes.element.isRequired,
};
