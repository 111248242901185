import { createAllOfEntitySelector, createEntityByIdSelector, createEntityStateSelector } from "./factories";
import { createSelector } from "reselect";
import { SHOPIFY_PROVISIONER_ID } from "../../../functions/constants/shopify";
import { orderBy } from "lodash/collection";


/**
 * Returns the full state of all sites.
 *
 * @param {Object} state Application state.
 *
 * @returns {Object} The full state of all sites.
 */
export const getSites = createEntityStateSelector( "sites" );

/**
 * Returns all sites in the state.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} All sites.
 */
export const getAllSites = createAllOfEntitySelector( "sites" );

/**
 * Returns byId part of sites entities in the state.
 *
 * @function
 *
 * @param {Object} state Application state.
 *
 * @returns {Array} byId part sites entities.
 */
export const getSitesById = createEntityByIdSelector( "sites" );

export const getViewableSites = createSelector(
	getAllSites,
	sites => {
		const viewableSites = sites.map( site => {
			const siteProps = {
				id: site.id,
				siteName: ( site.path === "/" ) ? site.hostname : ( site.hostname + site.path ),
				siteType: site.type,
				url: site.url,
				provisionerId: site.provisionerId,
			};

			if ( site.icon ) {
				siteProps.siteIcon = site.icon;
			}

			return siteProps;
		} )
			.filter( site => site.provisionerId !== SHOPIFY_PROVISIONER_ID );

		return orderBy(
			viewableSites,
			"siteName",
			"asc",
		);
	},
);
