import getEnv from "shared-frontend/functions/getEnv";

/**
 * Generates a renewal URL for a subscription.
 *
 * @param {Subscription} subscription The subscription to get a renewal URL for.
 * @returns {string} The renewal URL.
 */
export function generateRenewalUrl( subscription ) {
	const { subscriptionNumber, renewalSecret } = subscription;
	const homeUrl = getEnv( "API_URL", "https://my.yoast.test/api" );

	return `${ homeUrl }/subscriptions/renew/${ subscriptionNumber }/${ renewalSecret }`;
}
