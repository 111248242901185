import React, { createContext } from "react";
import { decode } from "jsonwebtoken";
import PropTypes from "prop-types";

const emptySession = {
	user: null,
	userToken: null,
	impersonatedBy: null,
	impersonatedByToken: null,
};

export const UserContext = createContext( {
	userSession: emptySession,
} );

/**
 * Map a JWT access token to a user session object.
 *
 * @param {string} accessToken The encoded access token of the logged in user.
 *
 * @returns {Object} the userSession.
 */
const getSessionForAccessToken   = ( accessToken ) => {
	const decodedToken = decode( accessToken, {} );

	if ( ! decodedToken ) {
		return emptySession;
	}

	const impersonatorToken = decodedToken.impersonatorToken;

	return {
		user: decodedToken,
		userToken: accessToken,
		impersonatedBy: impersonatorToken ? decode( impersonatorToken, {} ) : null,
		impersonatedByToken: impersonatorToken || null,
	};
};

/**
 * A wrapper of the UserContext.Provider with the added benefit of it transforming a JWT access token into a userSession.
 *
 * @param {Object} props The props.
 *
 * @returns {JSX.Element} The provider
 */
export const UserContextProvider = ( props ) => {
	const userSession = getSessionForAccessToken( props.accessToken );
	return (
		<UserContext.Provider value={ { userSession } }>
			{ props.children }
		</UserContext.Provider>
	);
};

UserContextProvider.propTypes    = {
	accessToken: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};
