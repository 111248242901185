/**
 * Lifting information about the subscriptions to the product.
 *
 * @param {Object} plugin             The plugin to be enriched.
 * @param {Array}  activatableSubscriptions The active subscriptions.
 * @param {Object}  site The site for which the product is available.
 *
 * @returns {Object}                     A productGroup updated with product and subscription info.
 */
export function addProvisioningInfoToPluginForSite( plugin, activatableSubscriptions ) {
	const enrichedPlugin = Object.assign( {}, plugin );

	// Set defaults.
	enrichedPlugin.accessGivenByProvisionedSubscription = false;
	enrichedPlugin.provisionerName = "";
	enrichedPlugin.provisionerAllowsSiteChange = false;

	// Find the active subscription for the plugin.
	const activeSubscription = activatableSubscriptions.find( subscription => subscription.id === plugin.subscriptionId );

	if ( activeSubscription ) {
		enrichedPlugin.accessGivenByProvisionedSubscription = !! activeSubscription.provisionerId;
		enrichedPlugin.provisionerName = activeSubscription.provisionerName;
		enrichedPlugin.provisionerAllowsSiteChange = activeSubscription.provisionerAllowsSiteChange;
	}

	return enrichedPlugin;
}
