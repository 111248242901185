// External dependencies.
import * as PropTypes from "prop-types";
import React, { memo, useEffect } from "react";
import { createGlobalStyle } from "styled-components";
import { defineMessages, IntlProvider } from "react-intl";
import { connect, Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
// eslint-disable-next-line no-unused-vars
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import queryString from "query-string";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import { Root } from "@yoast/ui-library";

// Internal dependencies.
import "./App.css";
import menuItems from "./config/Menu";
import { inLoginLayout, inMainLayout, inSingleLayout, inWhiteLayout } from "./components/Layout";
import PageNotFound from "./components/PageNotFound";
import AccountDisabled from "./components/AccountDisabled";
import SitePageContainer from "./containers/SitePage";
import SubscriptionPageContainer from "./containers/SubscriptionPage";
import ProfileDetails from "./containers/ProfileDetails";
import AlmostThere from "./components/login/AlmostThere";
import LoginPage from "./components/login/LoginSignupPage";
import ResetPasswordEmailContainer from "./containers/ResetPasswordEmail";
import ResetPasswordContainer from "./containers/ResetPassword";
import SendResetEmailSuccessPage from "./components/login/SendResetEmailSuccessPage";
import ResetPasswordSuccessPage from "./components/login/ResetPasswordSuccessPage";

import {
	getRedirectUrl,
	hasPeriLoginCookie,
	removePeriLoginCookie,
	sanitizeRedirectUrl,
	setPeriLoginCookie,
} from "shared-frontend/functions/auth";
import ActivateContainer from "./containers/ActivateContainer";
import { getAllSubscriptions } from "shared-frontend/redux/actions/subscriptions";
import Connect from "./containers/connect/Connect";
import { isOnlyProvisionerSubscriptions } from "./redux/selectors/ui/subscriptions";
import Loader from "./components/Loader";
import InstallWrapper from "./components/install/InstallWrapper";
import { UserContextProvider } from "./context/UserContext";

const titles = defineMessages( {
	login: {
		id: "menu.title.login",
		defaultMessage: "Login",
	},
	signup: {
		id: "menu.title.signup",
		defaultMessage: "Signup",
	},
	accountActivate: {
		id: "menu.title.accountActivate",
		defaultMessage: "Activate account",
	},
	accountEnterDetails: {
		id: "menu.title.accountEnterDetails",
		defaultMessage: "Enter account details",
	},
	passwordForgot: {
		id: "menu.title.passwordForgot",
		defaultMessage: "Reset your password",
	},
	passwordCheckEmail: {
		id: "menu.title.passwordCheckEmail",
		defaultMessage: "Check your email",
	},
	passwordReset: {
		id: "menu.title.passwordReset",
		defaultMessage: "Enter a new password",
	},
	passwordSuccess: {
		id: "menu.title.passwordSuccess",
		defaultMessage: "Password changed successfully!",
	},
	manageSite: {
		id: "menu.title.manageSite",
		defaultMessage: "Manage site",
	},
	manageSubscription: {
		id: "menu.title.manageSubscription",
		defaultMessage: "Manage subscription",
	},
	notFound: {
		id: "menu.title.notFound",
		defaultMessage: "Page not found",
	},
	connect: {
		id: "menu.title.connect",
		defaultMessage: "Connect",
	},
	install: {
		id: "menu.title.install",
		defaultMessage: "Install your products",
	},
	downloading: {
		id: "menu.title.install",
		defaultMessage: "Downloading your products",
	},
} );

/*
 * Helper method to write global CSS.
 * Only use it for the rare @font-face definition or body styling.
 */
/* eslint-disable no-unused-expressions */
const GlobalStyle = createGlobalStyle`
	body {
		font-family: "Open Sans", sans-serif !important;
		background: var(--bg-color-offwhite);
	}
`;

/**
 * This compares the previousProps with the nextProps.
 * If they aren't the same we should reRender the component.
 *
 * @param {Object} prevProps The previous props.
 * @param {Object} nextProps The next props.
 *
 * @returns {boolean} Are the compaired props equal.
 */
const arePropsEqual = ( prevProps, nextProps ) => {
	const simpleProps = omit( prevProps, [ "router", "history" ] );
	const simpleNextProps = omit( nextProps, [ "router", "history" ] );

	if ( ! isEqual( simpleNextProps, simpleProps ) ) {
		return false;
	}

	const samePath = prevProps.router.location && prevProps.router.location.pathname === nextProps.router.location.pathname;
	const clickedContentJumplink = nextProps.router.location && nextProps.router.location.hash === "#content";

	if ( samePath || clickedContentJumplink ) {
		return true;
	}

	return false;
};

/**
 * The Routes component.
 *
 * @param {Object} props The props.
 *
 * @returns {component} The Routes component.
 */
// eslint-disable-next-line react/display-name
const Routes = memo( props => {
	/**
	 * Gets the state for the redirect cookie.
	 *
	 * @returns {Object} The new state.
	 */
	const getCookieState = () => {
		if ( hasPeriLoginCookie() ) {
			return { redirectTo: getRedirectUrl() };
		}
		return {};
	};

	/**
	 * Redirects the user to the login page while saving the location the user was actually trying to reach.
	 * Saving this location allows us to redirect the user back to it as soon as the login succeeds.
	 *
	 * @param {RouteProps} routeProps The props of the Route
	 *
	 * @returns {ReactElement} The redirect component.
	 */
	const redirectToLogin = routeProps => {
		removePeriLoginCookie();
		setPeriLoginCookie();

		return ( <Redirect
			to={ {
				pathname: "/login",
				hash: routeProps.location.hash,
				search: routeProps.location.search,
			} }
		/> );
	};

	useEffect( () => {
		props.getAllSubscriptions();
	}, [ props.userData ] );

	/**
	 * Renders the menu routes.
	 *
	 * @returns {Routes} The menu routes.
	 */
	const renderMenuRoutes = () => {
		return menuItems.map( ( route, routeKey ) => {
			return <Route
				{ ...route }
				key={ routeKey }
				path={ route.path }
				component={ inMainLayout( route.component, route.title.defaultMessage, menuItems, route.noBackground ) }
			/>;
		} );
	};

	if ( props.loggedIn === false ) {
		return (
			<ConnectedRouter history={ props.history }>
				<Switch>
					<Route
						exact={ true }
						path="/login"
						component={ inLoginLayout( LoginPage, titles.login.defaultMessage ) }
					/>
					<Route
						exact={ true }
						path="/signup"
						component={ inLoginLayout( LoginPage, titles.signup.defaultMessage ) }
					/>
					<Route
						exact={ true }
						path="/activate"
						component={ inLoginLayout( ActivateContainer, titles.accountActivate.defaultMessage ) }
					/>
					<Route
						exact={ true }
						path="/almost-there"
						component={ inLoginLayout( AlmostThere, titles.accountActivate.defaultMessage ) }
					/>
					<Route
						exact={ true }
						path="/forgot-password"
						component={ inLoginLayout( ResetPasswordEmailContainer, titles.passwordForgot.defaultMessage ) }
					/>
					<Route
						exact={ true }
						path="/forgot-password/check-your-email"
						component={ inLoginLayout( SendResetEmailSuccessPage, titles.passwordCheckEmail.defaultMessage ) }
					/>
					<Route
						exact={ true }
						path="/forgot-password/reset-password"
						component={ inLoginLayout( ResetPasswordContainer, titles.passwordReset.defaultMessage ) }
					/>
					<Route
						exact={ true }
						path="/forgot-password/success"
						component={ inLoginLayout( ResetPasswordSuccessPage, titles.passwordSuccess.defaultMessage ) }
					/>
					<Route
						path="*"
						render={ redirectToLogin }
					/>
				</Switch>
			</ConnectedRouter>
		);
	}

	if ( props.userEnabled === false ) {
		return (
			<ConnectedRouter history={ props.history }>
				<Route path="*" component={ inSingleLayout( AccountDisabled ) } />
			</ConnectedRouter>
		);
	}

	if ( props.userEnabled === true ) {
		const cookieState = getCookieState();

		if ( cookieState && cookieState.redirectTo ) {
			removePeriLoginCookie();
			window.location.replace( cookieState.redirectTo );
			return null;
		}

		const queryParams = queryString.parse( window.location.search );
		if ( queryParams.redirect_to ) {
			window.location.replace( sanitizeRedirectUrl( queryParams.redirect_to ) );
			return null;
		}

		if ( props.subscriptionsLoaded ) {
			return (
				<ConnectedRouter history={ props.history }>
					<UserContextProvider accessToken={ props.accessToken }>
						<Switch>
							<Route
								exact={ true }
								path="/connect"
								component={ inLoginLayout( Connect, titles.connect.defaultMessage ) }
							/>
							<Route
								exact={ true }
								path="/activate"
								component={ inLoginLayout( ActivateContainer, titles.accountActivate.defaultMessage ) }
							/>
							<Route
								exact={ true }
								path="/enter-details"
								component={ inLoginLayout( ProfileDetails, titles.accountEnterDetails.defaultMessage ) }
							/>
							<Route
								exact={ true }
								path="/login"
								render={ () => <Redirect to={ "/" } /> }
							/>
							<Route
								exact={ true }
								path="/account/orders"
								render={ () => <Redirect to={ "/orders" } /> }
							/>
							<Route
								path="/sites/:id"
								component={ inMainLayout( SitePageContainer, titles.manageSite.defaultMessage, menuItems ) }
							/>
							<Route
								path="/account/subscriptions/:id"
								component={ inMainLayout( SubscriptionPageContainer, titles.manageSubscription.defaultMessage, menuItems ) }
							/>
							<Route
								path="/install"
								component={ inWhiteLayout( InstallWrapper, titles.install.defaultMessage ) }
							/>
							<Route
								exact={ true }
								path="/forgot-password/reset-password"
								component={ inLoginLayout( ResetPasswordContainer, titles.passwordReset.defaultMessage ) }
							/>
							<Route
								exact={ true }
								path="/forgot-password/success"
								component={ inLoginLayout( ResetPasswordSuccessPage, titles.passwordSuccess.defaultMessage ) }
							/>
							{ renderMenuRoutes() }
							<Route
								path="*"
								component={ inMainLayout( PageNotFound, titles.notFound.defaultMessage, menuItems ) }
							/>;
						</Switch>
					</UserContextProvider>
				</ConnectedRouter>
			);
		}
	}
	// We don't want to render anything until user is fetched, user.enabled is null by default.
	return <Loader />;
}, arePropsEqual );
/* eslint-enable complexity */

Routes.propTypes = {
	userEnabled: PropTypes.bool,
	userData: PropTypes.object.isRequired,
	accessToken: PropTypes.string,
	loggedIn: PropTypes.bool.isRequired,
	history: PropTypes.object,
	completedLogin: PropTypes.bool,
	router: PropTypes.object,
	getAllSubscriptions: PropTypes.func,
	subscriptionsLoaded: PropTypes.bool,
};

Routes.defaultProps = {
	userEnabled: false,
	accessToken: "",
	completedLogin: false,
	subscriptionsLoaded: false,
	history: {},
	router: {},
	getAllSubscriptions: () => {
	},
};


// eslint-disable-next-line require-jsdoc
const mapStateToProps = state => {
	return {
		userEnabled: state.user.enabled,
		userData: state.user.data.profile,
		accessToken: state.user.accessToken,
		loggedIn: state.user.loggedIn,
		completedLogin: state.ui.login.completedLogin,
		router: state.router,
		isOnlyProvisionerSubscriptions: isOnlyProvisionerSubscriptions( state ),
		subscriptionsLoaded: state.ui.subscriptions.firstTimeLoaded,
	};
};

// eslint-disable-next-line require-jsdoc
const mapDispatchToProps = dispatch => {
	return {
		getAllSubscriptions: () => dispatch( getAllSubscriptions() ),
	};
};

const RoutesContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)( Routes );

/**
 * The App Component.
 *
 * @param {Object} props The props.
 *
 * @returns {Component} The
 */
const App = props => {
	return (
		<Root>
			<GlobalStyle />
			<IntlProvider locale="en">
				<Provider store={ props.store }>
					<RoutesContainer history={ props.history } />
				</Provider>
			</IntlProvider>
		</Root>
	);
};


App.propTypes = {
	store: PropTypes.object,
	history: PropTypes.object,
};

App.defaultProps = {
	store: {},
	history: {},
};
export default App;
