import _union from "lodash/union";

import {
	GET_ALL_SUBSCRIPTIONS_SUCCESS,
	RECALCULATE_SUBSCRIPTION_COUNT_SUCCESS,
} from "../actions/subscriptions";
import { SITE_ADD_SUBSCRIPTION_SUCCESS, SITE_REMOVE_SUBSCRIPTION_SUCCESS } from "../actions/site";
import { LOGOUT_SUCCESS } from "../actions/user";

/*
 * Initial state
 */
const initialState = {
	subscriptions: {
		byId: {},
		allIds: [],
	},
	recalcalculatedSubscriptions: [],
};

/*
 * Reducers
 */

/**
 * A reducer for the byIdSubscriptions object.
 *
 * @param {Object} state The current state of the byIdSubscriptions object.
 * @param {Object} action The current action received.
 * @returns {Object} The updated byIdSubscriptions object.
 */
export function byIdSubscriptionsReducer( state = initialState.subscriptions.byId, action ) {
	const subscriptions = Object.assign( {}, state );

	switch ( action.type ) {
		case GET_ALL_SUBSCRIPTIONS_SUCCESS:
			action.subscriptions.forEach( ( subscription ) => {
				subscriptions[ subscription.id ] = Object.assign( {}, subscription, {
					orders: subscription.orders.map( order => order.id ),
				} );
			} );

			return subscriptions;

		case SITE_ADD_SUBSCRIPTION_SUCCESS:
			subscriptions[ action.subscriptionId ].used += 1;

			return subscriptions;

		case SITE_REMOVE_SUBSCRIPTION_SUCCESS:
			subscriptions[ action.subscriptionId ].used -= 1;

			return subscriptions;
		case LOGOUT_SUCCESS:
			return initialState.subscriptions.byId;
		default:
			return subscriptions;
	}
}

/**
 * A reducer for the allIdsSubscriptions array.
 *
 * @param {Array} state The current state of the allIdsSubscriptions array.
 * @param {Object} action The current action received.
 * @returns {Array} The updated allIdsSubscriptions array.
 */
export function allIdsSubscriptionsReducer( state = initialState.subscriptions.allIds, action ) {
	switch ( action.type ) {
		case GET_ALL_SUBSCRIPTIONS_SUCCESS:
			return _union( state, action.subscriptions.map( subscription => subscription.id ) );
		case LOGOUT_SUCCESS:
			return initialState.subscriptions.allIds;
		default:
			return state;
	}
}

/**
 * A reducer for the recalcalculatedSubscriptions array.
 *
 * @param {Array} state The current state of the recalcalculatedSubscriptions array.
 * @param {Object} action The current action received.
 * @returns {Array} The updated recalcalculatedSubscriptions array.
 */
export function recalculateSubscriptionCountReducer( state = initialState.recalcalculatedSubscriptions, action ) {
	switch ( action.type ) {
		case RECALCULATE_SUBSCRIPTION_COUNT_SUCCESS:
			if ( state.length > 0 ) {
				if ( state.some( subscription => subscription.id === action.subscription.id ) ) {
					return state;
				}
				return [ ...state, action.subscription ];
			}
			return [ ...state, action.subscription ];
		default:
			return state;
	}
}

