import SeoForWpIcon from "../../icons/Academy/Yoast_SEO_for_WP_2.svg";
import CopywritingIcon from "../../icons/Academy/SEO_copywriting.svg";
import SiteStructureIcon from "../../icons/Academy/Site_structure.svg";
import StructuredDataIcon from "../../icons/newIcons/sdt_beginners_icon_optm.svg";
import UpsellCard from "./UpsellCard";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserId } from "../../redux/selectors/ui/user";
import { prepareInternalRequest, doRequest } from "shared-frontend/functions/api";

const academyUpsellId = "academy.upsell";
const academyUpsellProps = [
	{
		icon: SeoForWpIcon,
		sourceId: 4484,
		description: {
			id: "seoforwordpress.description",
			defaultMessage: "Don't waste time figuring out the best settings yourself.",
		},
		link: {
			name: "Yoast SEO for WordPress training",
			// This URL is the same as the URL for enrolled customers, because it's a free course.
			url: "https://academy.yoast.com/courses/yoast-seo-for-wordpress-block-editor/",
			enrolledUrl: "https://academy.yoast.com/courses/yoast-seo-for-wordpress-block-editor/",
		},
	},
	{
		icon: CopywritingIcon,
		sourceId: 5244,
		description: {
			id: "copywriting.description",
			defaultMessage: "Write awesome copy that ranks.",
		},
		link: {
			name: "SEO copywriting training",
			url: "https://yoast.com/academy/seo-copywriting-training/",
			enrolledUrl: "https://academy.yoast.com/courses/seo-copywriting-2019/",
		},
	},
	{
		icon: SiteStructureIcon,
		sourceId: 3820,
		description: {
			id: "sitestructure.description",
			defaultMessage: "Learn how to improve your site structure to rank higher in Google.",
		},
		link: {
			name: "Site structure training",
			url: "https://yoast.com/academy/site-structure-training/",
			enrolledUrl: "https://academy.yoast.com/courses/site-structure-2018/",
		},
	},
	{
		icon: StructuredDataIcon,
		sourceId: 4,
		description: {
			id: "structureddata.description",
			defaultMessage: "Learn how to get great-looking rich results that stand out in the search results.",
		},
		link: {
			name: "Structured data for beginners",
			// This URL is the same as the URL for enrolled customers, because it's a free course.
			url: "https://academy.yoast.com/courses/structured-data-for-beginners/",
			enrolledUrl: "https://academy.yoast.com/courses/structured-data-for-beginners/",
		},
	},
];

/**
 * Academy upsell component.
 *
 * @returns {React.ReactElement} The component
 */
const AcademyUpsell = () => {
	const [ upSells, setUpSells ] = useState( academyUpsellProps );
	const userId = useSelector( getUserId );

	useEffect( () => {
		( async() => {
			try {
				const request = prepareInternalRequest( `Customers/academyCourseAccessListByCustomerId/${ userId }` );
				const { courseIds } = await doRequest( request );

				const newUpSells = upSells.map( upSell => {
					if ( courseIds.includes( upSell.sourceId ) ) {
						upSell.link.url = upSell.link.enrolledUrl;
					}
					return upSell;
				} );

				setUpSells( newUpSells );
			} catch ( e ) {
				/* Do nothing */
			}
		} )();
	}, [] );

	return (
		<UpsellCard
			id={ academyUpsellId }
			header={ {
				id: "header",
				defaultMessage: "Improve your SEO skills",
			} }
			listPropsArray={ upSells }
		/>
	);
};

export default AcademyUpsell;
