import { useRef, useEffect } from "react";

/**
 * Provides a declarative useInterval
 *
 * @param {func}   callback Function that will be called every `delay` ms.
 * @param {number} delay    Number representing the delay in ms. Set to `null` to "pause" the interval.
 *
 * @returns {void}
 */
function useInterval( callback, delay ) {
	const savedCallback = useRef();

	useEffect( () => {
		savedCallback.current = callback;
	}, [ callback ] );

	useEffect( () => {
		if ( delay !== null ) {
			const intervalId = setInterval( () => savedCallback.current(), delay );
			return () => {
				clearInterval( intervalId );
			};
		}
	}, [ callback, delay ] );
}

export default useInterval;
