import _union from "lodash/union";

import { GET_ALL_PRODUCTS_SUCCESS } from "../actions/products";
import { LOGOUT_SUCCESS } from "../actions/user";

/*
 * Initial state
 */

const initialState = {
	products: {
		byId: {},
		allIds: [],
	},
};

/*
 * Reducers
 */

/**
 * A reducer for the byIdProducts object.
 *
 * @param {Object} state The current state of the byIdProducts object.
 * @param {Object} action The current action received.
 * @returns {Object} The updated byIdProducts object.
 */
export function byIdProductsReducer( state = initialState.products.byId, action ) {
	let products;

	switch ( action.type ) {
		case GET_ALL_PRODUCTS_SUCCESS:
			products = Object.assign( {}, state );

			action.products.forEach( ( product ) => {
				products[ product.id ] = Object.assign( {}, product );
			} );

			return products;
		case LOGOUT_SUCCESS:
			return initialState.products.byId;
		default:
			return state;
	}
}

/**
 * A reducer for the allIdsProducts array.
 *
 * @param {Array} state The current state of the allIdsProducts array.
 * @param {Object} action The current action received.
 * @returns {Array} The updated allIdsProducts array.
 */
export function allIdsProductsReducer( state = initialState.products.allIds, action ) {
	switch ( action.type ) {
		case GET_ALL_PRODUCTS_SUCCESS:
			return _union( state, action.products.map( product => product.id ) );
		case LOGOUT_SUCCESS:
			return initialState.products.allIds;
		default:
			return state;
	}
}
