import { LOCATION_CHANGE } from "connected-react-router";

import {
	GET_ALL_SUBSCRIPTIONS_FAILURE,
	GET_ALL_SUBSCRIPTIONS_REQUEST,
	GET_ALL_SUBSCRIPTIONS_SUCCESS,
	CANCEL_SUBSCRIPTION_FAILURE,
	CANCEL_SUBSCRIPTION_SUCCESS,
	CANCEL_SUBSCRIPTION_REQUEST,
} from "shared-frontend/redux/actions/subscriptions";

import { LOGOUT_SUCCESS } from "shared-frontend/redux/actions/user";


import {
	ADD_LICENCES_MODAL_CLOSE,
	ADD_LICENCES_MODAL_OPEN,
} from "../actions/subscriptions";

/*
 * Initial state
 */

const initialState = {
	subscriptions: {
		requesting: false,
		error: null,
		firstTimeLoaded: false,
	},
	addSubscriptionModal: {
		storeUrl: null,
		modalOpen: false,
	},
	subscriptionsCancel: {
		error: null,
		loading: false,
		success: false,
	},
};

/*
 * Reducers
 */

/**
 * A reducer for the ui subscriptions object.
 *
 * @param {Object} state The current state of the ui subscriptions object.
 * @param {Object} action The current action received.
 * @returns {Object} The updated ui subscription object.
 */
export function uiAllSubscriptionsReducer( state = initialState.subscriptions, action ) {
	switch ( action.type ) {
		case GET_ALL_SUBSCRIPTIONS_REQUEST:
			return Object.assign( {}, state, {
				requesting: true,
				error: null,
			} );
		case GET_ALL_SUBSCRIPTIONS_SUCCESS:
			return Object.assign( {}, state, {
				requesting: false,
				firstTimeLoaded: true,
			} );
		case GET_ALL_SUBSCRIPTIONS_FAILURE:
			return Object.assign( {}, state, {
				requesting: false,
				error: action.error,
			} );
		case LOGOUT_SUCCESS:
			return initialState.subscriptions;
		default:
			return state;
	}
}

/**
 * A reducer for the ui addSubscriptionModal object.
 *
 * @param {Object} state The current state of the ui addSubscriptionModal object.
 * @param {Object} action The current action received.
 * @returns {Object} The updated ui addSubscriptionModal object.
 */
export function uiAddSubscriptionModalReducer( state = initialState.addSubscriptionModal, action ) {
	switch ( action.type ) {
		case ADD_LICENCES_MODAL_OPEN:
			return Object.assign( {}, state, {
				storeUrl: action.storeUrl,
				modalOpen: true,
			} );
		case ADD_LICENCES_MODAL_CLOSE:
			return Object.assign( {}, state, {
				storeUrl: null,
				modalOpen: false,
			} );
		default:
			return state;
	}
}

/* eslint-disable complexity */
/**
 * A reducer for the ui cancelSubscriptionAtEndOfTerm object.
 *
 * @param {Object} state The current state of the cancelSubscriptionAtEndOfTerm object.
 * @param {Object} action The current action received.
 *
 * @returns {Object} The updated cancelSubscriptionAtEndOfTerm object.
 */
export function uiCancelSubscriptionsReducer( state = initialState.subscriptionsCancel, action ) {
	switch ( action.type ) {
		case LOCATION_CHANGE:
			return Object.assign( {}, state, {
				modalOpen: false,
			} );
		case CANCEL_SUBSCRIPTION_REQUEST:
			return Object.assign( {}, state, {
				loading: true,
				error: null,
				success: false,
			} );
		case CANCEL_SUBSCRIPTION_SUCCESS:
			return Object.assign( {}, state, {
				loading: false,
				error: null,
				success: true,
			} );
		case CANCEL_SUBSCRIPTION_FAILURE:
			return Object.assign( {}, state, {
				loading: false,
				error: action.error,
				success: false,
			} );
		default:
			return state;
	}
}
/* eslint-enable complexity */
