/*
 * Action types
 */

export const ADD_LICENCES_MODAL_OPEN = "ADD_LICENCES_MODAL_OPEN";
export const ADD_LICENCES_MODAL_CLOSE = "ADD_LICENCES_MODAL_CLOSE";

/*
 * Action creators
 */

/**
 * An action creator for the opening add licenses modal action.
 *
 * @param {string} storeUrl The store URL.
 * @returns {Object} An open add licenses modal action.
 */
export function addLicensesModalOpen( storeUrl ) {
	return {
		type: ADD_LICENCES_MODAL_OPEN,
		storeUrl,
	};
}

/**
 * An action creator for the closing add licenses modal action.
 *
 * @returns {Object} An closing add licenses modal action.
 */
export function addLicensesModalClose() {
	return {
		type: ADD_LICENCES_MODAL_CLOSE,
	};
}
