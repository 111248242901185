import {
	GET_ALL_PRODUCTS_FAILURE,
	GET_ALL_PRODUCTS_REQUEST,
	GET_ALL_PRODUCTS_SUCCESS,
} from "shared-frontend/redux/actions/products";

/*
 * Initial state
 */

const rootState = {
	products: {
		requesting: false,
		error: null,
	},
};

/*
 * Reducers
 */

/**
 * A reducer for the ui products object.
 *
 * @param {Object} state The current state of the ui products object.
 * @param {Object} action The current action received.
 * @returns {Object} The updated ui product object.
 */
export function uiAllProductsReducer( state = rootState.products, action ) {
	switch ( action.type ) {
		case GET_ALL_PRODUCTS_REQUEST:
			return Object.assign( {}, state, {
				requesting: true,
				error: "",
			} );
		case GET_ALL_PRODUCTS_SUCCESS:
			return Object.assign( {}, state, {
				requesting: false,
			} );
		case GET_ALL_PRODUCTS_FAILURE:
			return Object.assign( {}, state, {
				requesting: false,
				error: action.error,
			} );
		default:
			return state;
	}
}

