import PropTypes from "prop-types";
import styled from "styled-components";
import defaults from "../config/defaults.json";
import { addPlaceholderStyles } from "../styles/inputs";

export const InputField = addPlaceholderStyles( styled.input`
	width: ${ props => props.inputWidth };
	height: ${ defaults.css.inputField.height };
	box-shadow: inset 0 2px 8px 0 rgba(0, 0, 0, 0.3);
	background: ${ props => props.backgroundColor };
	padding: 0 10px;
	font-size: 1em;
	border: 0;
	margin-bottom: 8px;
` );

InputField.propTypes = {
	inputWidth: PropTypes.string,
	backgroundColor: PropTypes.string,
};

InputField.defaultProps = {
	inputWidth: "100%",
	backgroundColor: "var(--bg-color-white)",
};
