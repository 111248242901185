import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { getDocumentTitle } from "../helpers";

import styles from "./styles.scss";

/**
 * Wraps a component in the layout used for the login.
 *
 * @param {ReactElement} WrappedComponent The React component to wrap.
 * @param {string}       documentTitle    The document title.
 *
 * @returns {ReactElement} The login layout with the wrapped component.
 */
export default( WrappedComponent, documentTitle ) => {
  return class LoginLayout extends Component {
    /**
     * Renders the component.
     *
     * @returns {ReactElement} The rendered component.
     */
    render() {
      return (
        <DocumentTitle title={ getDocumentTitle( documentTitle ) }>
          <div className={ styles.layoutLogin }>
            <div className={ styles.whitePaper }>
              <WrappedComponent { ...this.props } />
            </div>
          </div>
        </DocumentTitle>
      );
    }
  };
};
