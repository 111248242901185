import _flatMap from "lodash/flatMap";
import _uniq from "lodash/uniq";

import { getAllOfEntity } from "../redux/selectors/entities/factories";

/** Product helpers */

export const SITE_TYPE_PLUGIN_SLUG_MAPPING = {
	wordpress: "all-plugins",
	typo3: "all-typo3-extensions",
	shopify: "all-shopify-apps",
};

/**
 * Returns the product group that have the passed slug's productGroup as a parent.
 *
 * @param   {string}  slug             The slug of the parent productGroup.
 * @param   {Array}   productGroups    All productGroups.
 * @param   {boolean} includeParent    Whether or not to include the parent itself.
 *
 * @returns {Array}   The productGroups that belong to the parent slug.
 */
export function getProductGroupsByParentSlug( slug, productGroups, includeParent = false ) {
	// Get the id of the parent productGroup.
	const parentGroup = productGroups.find( productGroup => productGroup.slug === slug );
	if ( parentGroup ) {
		const children = productGroups.filter( productGroup => productGroup.parentId === parentGroup.id );

		if ( includeParent ) {
			return [ parentGroup, ...children ];
		}
		return children;
	}
	return [];
}

/**
 * Get the plugin product that we will be showing in the site subscription list.
 *
 * @param {Object} product The product that sells the plugin.
 * @param {Object[]} pluginProductGroups The product group that represents the plugin.
 *
 * @returns {Object} The plugin.
 */
export function getInitialPluginSetup( product, pluginProductGroups ) {
	const plugin = Object.assign( {}, product );

	// Set product group specific info on the plugin. I would move this to its own function if I could.
	const containedPluginProductGroups = product.productGroups.filter( pg => pluginProductGroups.map( pg2 => pg2.id ).includes( pg.id ) );
	if ( containedPluginProductGroups.length === 1 ) {
		plugin.name = containedPluginProductGroups[ 0 ].name;
	}

	// Override productGroup name and icon in case of the all plugins subscription. The icon hardcode is a temporary fix!
	if ( product.productGroups.some( pg => pg.slug === "all-plugins" ) ) {
		plugin.name = "Plugin subscription";
		plugin.icon = "https://yoast.com/app/uploads/2018/11/Plugin_subscription.png";
	}

	return plugin;
}

/**
 * Adds information to a plugin in the case no subscription is enabled for the plugin.
 *
 * @param {Object} plugin                                The plugin to be enriched.
 * @param {Array}  activatableSubscriptionsForThisPlugin The active subscriptions for the plugin, in an array.
 *
 * @returns {Object}                                     A productGroup updated with product and subscription info.
 */
function addInfoToInactivePluginOnSite( plugin, activatableSubscriptionsForThisPlugin ) {
	// Prefer yoast subscriptions over third party subscriptions.
	activatableSubscriptionsForThisPlugin.sort( subscription => ( subscription.provisionerId ) ? 1 : -1 );

	const subscriptionWithFreeSlots = activatableSubscriptionsForThisPlugin.find( subscription => subscription.limit > ( subscription.used || 0 ) );
	if ( subscriptionWithFreeSlots ) {
		plugin.isAvailable = true;
		plugin.subscriptionId = subscriptionWithFreeSlots.id;
		plugin.currency = subscriptionWithFreeSlots.currency;

		if ( subscriptionWithFreeSlots.provisionerId ) {
			const anyYoastSubscription = activatableSubscriptionsForThisPlugin.find( subscription => ! subscription.provisionerId );

			if ( anyYoastSubscription ) {
				plugin.manageButtonSubscriptionId = anyYoastSubscription.id;
				plugin.storeUrl = anyYoastSubscription.product.storeUrl || plugin.storeUrl;
			}
			return plugin;
		}

		plugin.manageButtonSubscriptionId = subscriptionWithFreeSlots.id;
		plugin.storeUrl = subscriptionWithFreeSlots.product.storeUrl || plugin.storeUrl;

		return plugin;
	}
	return plugin;
}

/**
 * Lifting information about the subscriptions to the product.
 *
 * @param {Object} plugin                   The plugin to be enriched.
 * @param {Array}  activatableSubscriptions The active subscriptions, in an array.
 *
 * @returns {Object}                        A productGroup updated with product and subscription info.
 */
// eslint-disable-next-line max-statements,require-jsdoc
export function addSubscriptionInfoToProduct( plugin, activatableSubscriptions ) {
	// Set subscription related defaults
	plugin.used = 0;
	plugin.limit = 0;
	plugin.isAvailable = false;
	plugin.isEnabled = false;
	plugin.subscriptionId = "";
	plugin.manageButtonSubscriptionId = "";

	// Get the subscriptions for the same product ( based on gl number ) as the one passed.
	const activeSubscriptionsForThisPlugin = activatableSubscriptions.filter( subscription => subscription.product.glNumber === plugin.glNumber );

	// Count all used columns together.
	plugin.used = activeSubscriptionsForThisPlugin.reduce( ( accumulator, subscription ) => accumulator + ( subscription.used || 0 ), 0 );
	// Count all limit columns together.
	plugin.limit = activeSubscriptionsForThisPlugin.reduce( ( accumulator, subscription ) => accumulator + subscription.limit, 0 );

	plugin.hasSubscriptions = plugin.limit > 0;

	const activeSubscription = activeSubscriptionsForThisPlugin.find( subscription => subscription.isEnabled === true );
	if ( activeSubscription ) {
		plugin.isEnabled = true;
		plugin.subscriptionId = activeSubscription.id;
		plugin.manageButtonSubscriptionId = activeSubscription.id;
		plugin.currency = activeSubscription.currency;
		plugin.storeUrl = activeSubscription.product.storeUrl || plugin.storeUrl;
		plugin.isAvailable = true;

		return plugin;
	}

	return addInfoToInactivePluginOnSite( plugin, activeSubscriptionsForThisPlugin );
}

/**
 * Get all products that are in a productGroup.
 *
 * @param   {string}  pluginGroupId The id of the pluginGroup we want to find the products for.
 * @param   {Array}   allProducts   All products.
 * @returns {Array}                 The products that are in the productGroup.
 */
export function getProductsByProductGroupId( pluginGroupId, allProducts ) {
	return allProducts
		.filter( ( product ) => {
			const productGroupIds = product.productGroups.map( productGroup => productGroup.id );
			return productGroupIds.includes( pluginGroupId );
		} );
}

/**
 * Get all products that are in a set of productGroups.
 *
 * @param   {Array}   pluginGroupIds The ids of the pluginGroups we want to find the products for.
 * @param   {Array}   allProducts    All products.
 * @returns {Array}                  The products that are in the productGroups.
 */
export function getProductsByProductGroupIds( pluginGroupIds, allProducts ) {
	return _uniq(
		_flatMap( pluginGroupIds, pluginGroupId => getProductsByProductGroupId( pluginGroupId, allProducts ) ),
	);
}

/**
 * Gets the products that a subscription gives access to.
 *
 * @param   {Object} state        The application state.
 * @param   {Object} subscription The subscription.
 *
 * @returns {Array}               An array with the products for the subscription.
 */
export function getProductsFromSubscription( state, subscription ) {
	if ( ! subscription.product.productGroups ) {
		/*
		 If this product has no productGroups attached to it, we can not find potential other products via the productGroups.
		 Best guess is to at least return the product inside an array to be in line with expected behaviour for this function.
		 If the subscription does not even have a product, return an empty array.
		 */
		return subscription.product ? [ subscription.product ] : [];
	}

	const allProductGroups = getAllOfEntity( state, "productGroups" );

	// Map the subscription ProductGroups to the ProductGroups already stored in state, so we have access to more data, like the parents.
	let subscriptionProductGroups = allProductGroups.filter( lookupProductGroup =>
		subscription.product.productGroups.some( subscriptionProductGroup =>
			lookupProductGroup.id === subscriptionProductGroup.id ),
	);

	const allProducts = getAllOfEntity( state, "products" );
	// If at least one productGroup doesn't have a parent.
	if ( subscriptionProductGroups.some( productGroup => ! productGroup.parentId ) ) {
		// Retrieve the child product groups for the parent product group.
		subscriptionProductGroups = _flatMap( subscriptionProductGroups, ( productGroup ) => {
			return getProductGroupsByParentSlug( productGroup.slug, allProductGroups );
		} );
	}
	// Return the products that are accessible trough the subscription.
	return _flatMap( subscriptionProductGroups, productGroup => getProductsByProductGroupId( productGroup.id, allProducts ) );
}

/**
 * Returns true if a product has downloads.
 *
 * @param   {Object}  product The product to check downloads for.
 * @returns {boolean}         Whether or not the product has downloads.
 */
export const hasDownload = ( product ) => {
	return product.hasOwnProperty( "downloads" ) &&
		product.downloads.length > 0 &&
		! [ "Ecommerce SEO training", "Local SEO training" ].includes( product.name );
};
