import React, { Fragment, useState } from "react";
import { defineMessages, injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import ValidationInputField from "../ValidationInputField";
import { noop } from "shared-frontend/functions/noop";

const messages = defineMessages( {
	enterSiteUrl: {
		id: "sites.select.enterNewSiteUrl",
		defaultMessage: "Enter your site URL",
	},
	validationFormatURL: {
		id: "validation.format.url",
		defaultMessage: "Please enter a valid URL. Remember to use http:// or https://.",
	},
} );

/**
 * A component for specifying a new site url.
 *
 * @param {object} props The properties.
 *
 * @returns {ReactComponent} The rendered components.
 */
const AddNewSite = ( props ) => {
	let urlConstraints    = "";
	const [ url, setUrl ] = useState( props.newSiteUrl );

	if ( url !== "" ) {
		urlConstraints = {
			url: {
				message: props.intl.formatMessage( messages.validationFormatURL ),
			},
		};
	}
	/**
	 * Updates the state if a new site url has been entered.
	 *
	 * @param {string} value The new url value.
	 * @param {string[]} errors Any validation errors that apply to the new site url.
	 * @param {boolean} validating Whether or not the new site value is still being validated.
	 *
	 * @returns {void}
	 */
	function onChange( value, errors, validating ) {
		setUrl( value );
		props.onUrlChange( value );
		if ( validating || errors.length > 0 ) {
			props.onValidUrl( "" );
			return;
		}

		props.onValidUrl( value );
	}

	/**
	 * Renders the AddNewSite component.
	 *
	 * @returns {ReactElement} The AddNewSite component.
	 */
	return (
		<>
			<ValidationInputField
				type="url"
				id="add-site-input"
				label={ props.intl.formatMessage( messages.enterSiteUrl ) }
				constraint={ urlConstraints }
				placeholder="https://example-site.com"
				value={ url }
				onChange={ onChange }
				disabled={ props.disabled }
				trimWhiteSpace={ true }
			/>
		</>
	);
};

AddNewSite.propTypes = {
	intl: intlShape.isRequired,
	newSiteUrl: PropTypes.string,
	onUrlChange: PropTypes.func,
	onValidUrl: PropTypes.func,
	disabled: PropTypes.bool,
	trimWhiteSpace: PropTypes.bool,
};

AddNewSite.defaultProps = {
	newSiteUrl: "",
	onUrlChange: noop,
	onValidUrl: noop,
	disabled: false,
};

export default injectIntl( AddNewSite );
