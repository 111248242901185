/*
 * Action types
 */

export const COURSE_INVITE_MODAL_OPEN = "COURSE_INVITE_MODAL_OPEN";
export const COURSE_INVITE_MODAL_CLOSE = "COURSE_INVITE_MODAL_CLOSE";

/*
 * Action creators
 */

/**
 * An action creator for the academy invite modal open action.
 *
 * @param {string} courseEnrollmentId The id of the courseEnrollment
 *
 * @returns {Object} An academy invite modal open action.
 */
export function courseInviteModalOpen( courseEnrollmentId ) {
	return {
		type: COURSE_INVITE_MODAL_OPEN,
		courseEnrollmentId: courseEnrollmentId,
	};
}

/**
 * An action creator for the academy invite modal close action.
 *
 * @returns {Object} An academy invite modal close action.
 */
export function courseInviteModalClose() {
	return {
		type: COURSE_INVITE_MODAL_CLOSE,
	};
}
