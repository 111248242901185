import _union from "lodash/union";

import { GET_ORDERS_SUCCESS } from "../actions/orders";
import { LOGOUT_SUCCESS } from "../actions/user";

/**
 * Initial state
 */
const initialState = {
	orders: {
		byId: {},
		allIds: [],
	},
};

/**
 * Reducers
 */


/**
 * A reducer for the byIdOrdersReducer list.
 *
 * @param {Object} state The current state of the byIdOrdersReducer list.
 * @param {Object} action The current action received.
 * @returns {Object} The updated byIdOrdersReducer object.
 */
export function byIdOrdersReducer( state = initialState.orders.byId, action ) {
	let orders;

	switch ( action.type ) {
		case GET_ORDERS_SUCCESS:
			orders = Object.assign( {}, state );

			action.orders.forEach( ( order ) => {
				orders[ order.id ] = order;
			} );

			return orders;
		case LOGOUT_SUCCESS:
			return initialState.orders.byId;
		default:
			return state;
	}
}

/**
 * A reducer for the allIdsOrdersReducer list.
 *
 * @param {Array} state The current state of the allIdsOrdersReducer list.
 * @param {Object} action The current action received.
 * @returns {Array} The updated allIdsOrdersReducer array.
 */
export function allIdsOrdersReducer( state = initialState.orders.allIds, action ) {
	switch ( action.type ) {
		case GET_ORDERS_SUCCESS:
			return _union( state, action.orders.map( order => order.id ) );
		case LOGOUT_SUCCESS:
			return initialState.orders.allIds;
		default:
			return state;
	}
}
