import _union from "lodash/union";

import { GET_PRODUCT_GROUPS_SUCCESS } from "../actions/productGroups";
import { LOGOUT_SUCCESS } from "../actions/user";

/*
 * Initial state
 */

const initialState = {
	productGroups: {
		byId: {},
		allIds: [],
	},
};

/*
 * Reducers
 */

/**
 * A reducer for the byIdProductGroups object.
 *
 * @param {Object} state The current state of the byIdProductGroups object.
 * @param {Object} action The current action received.
 * @returns {Object} The updated byIdProductGroups object.
 */
export function byIdProductGroupsReducer( state = initialState.productGroups.byId, action ) {
	let productGroups;

	switch ( action.type ) {
		case GET_PRODUCT_GROUPS_SUCCESS:
			productGroups = Object.assign( {}, state );

			action.productGroups.forEach( ( productGroup ) => {
				productGroups[ productGroup.id ] = Object.assign( {}, productGroup );
			} );

			return productGroups;
		case LOGOUT_SUCCESS:
			return initialState.productGroups.byId;

		default:
			return state;
	}
}

/**
 * A reducer for the allIdsProductGroups array.
 *
 * @param {Array} state The current state of the allIdsProductGroups array.
 * @param {Object} action The current action received.
 * @returns {Array} The updated allIdsProductGroups array.
 */
export function allIdsProductGroupsReducer( state = initialState.productGroups.allIds, action ) {
	switch ( action.type ) {
		case GET_PRODUCT_GROUPS_SUCCESS:
			return _union( state, action.productGroups.map( productGroup => productGroup.id ) );
		case LOGOUT_SUCCESS:
			return initialState.productGroups.allIds;
		default:
			return state;
	}
}
