import reduceReducers from "reduce-reducers";

import {
	SITE_TOGGLE_SUBSCRIPTION_REQUEST,
	SITE_ADD_SUBSCRIPTION_SUCCESS,
	SITE_REMOVE_SUBSCRIPTION_SUCCESS,
	SITE_TOGGLE_SUBSCRIPTION_FAILURE,
	SITE_REMOVE_START,
	SITE_REMOVE_SUCCESS,
	SITE_REMOVE_FAILURE,
} from "shared-frontend/redux/actions/site";

/**
 * Initial state
 */
const rootState = {
	site: {
		removing: false,
		subscriptions: {
			error: null,
			toggling: false,
		},
		downloadModalOpen: false,
		downloadModalSubscriptionId: "",
	},
};

/**
 * Reducers
 */

/**
 * A reducer for the site add subscription actions within the ui site object.
 *
 * @param {Object} state The current state of the object.
 * @param {Object} action The current action received.
 * @returns {Object} The updated state.
 */
export function uiSiteSubscriptionsReducer( state = rootState.site.subscriptions, action ) {
	switch ( action.type ) {
		case SITE_TOGGLE_SUBSCRIPTION_REQUEST:
			return Object.assign( {}, state, {
				toggling: true,
				error: null,
			} );
		case SITE_ADD_SUBSCRIPTION_SUCCESS:
		case SITE_REMOVE_SUBSCRIPTION_SUCCESS:
			return Object.assign( {}, state, {
				toggling: false,
			} );
		case SITE_TOGGLE_SUBSCRIPTION_FAILURE:
			return Object.assign( {}, state, {
				toggling: false,
				error: action.error,
			} );
		default:
			return state;
	}
}

/**
 * A reducer for the site remove actions within the ui site object.
 *
 * @param {Object} state The current state of the object.
 * @param {Object} action The current action received.
 * @returns {Object} The updated state.
 */
export function uiSiteRemoveReducer( state = rootState.site, action ) {
	const site = Object.assign( {}, state );
	switch ( action.type ) {
		case SITE_REMOVE_START:
			site.removing = true;
			break;
		case SITE_REMOVE_SUCCESS:
			site.removing = false;
			break;
		case SITE_REMOVE_FAILURE:
			site.removing = false;
			break;
	}
	site.subscriptions = uiSiteSubscriptionsReducer( state.subscriptions, action );
	return site;
}

const uiSite = reduceReducers( uiSiteRemoveReducer );

/**
 * A reducer for the site object within the ui object.
 *
 * @param {Object} state The current state of the object.
 * @param {Object} action The current action received.
 *
 * @returns {Object} The updated Site object.
 */
export function uiSiteReducer( state = rootState.site, action ) {
	return uiSite( state, action );
}
