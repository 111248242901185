import zxcvbn from "zxcvbn";

/**
 * Validates the strength of the password using the zxcvbn module.
 *
 * @param {String} password The password to validate.
 * @param {Array<string>} additionalRiskyStrings Additional strings that should be considered risky in the password.
 *
 * @returns {Object} The result of the password validation, see result object below.
 */
export function validatePasswordStrength( password, additionalRiskyStrings = [] ) {
	const passwordStrengthEvaluation = zxcvbn( password, additionalRiskyStrings );

	const result = {
		isFilledIn: password.length > 0,
		score: passwordStrengthEvaluation.score,
		isStrongEnough: passwordStrengthEvaluation.score >= 3,
		feedback: passwordStrengthEvaluation.feedback,
		errors: [],
		isAllowedPassword: false,
	};

	// Determine the amount of UTF-8 bytes in the password. Because bcrypt only supports 72 bytes.
	const byesInPassword = ( new TextEncoder().encode( password ) ).length;
	if ( byesInPassword > 72 ) {
		result.errors.push( "passwordTooLong" );
		result.feedback.suggestions.unshift( "The password is too long. Please shorten it." );
	}

	if ( passwordStrengthEvaluation.score < 3 ) {
		result.errors.push( "passwordTooWeak" );
		// If the password feedback warning is set, add it to the suggestions at the front.
		if ( result.feedback.warning ) {
			result.feedback.suggestions.unshift( result.feedback.warning + "." );
		}
	}

	if ( result.isStrongEnough && result.errors.length === 0 ) {
		result.isAllowedPassword = true;
	}
	return result;
}
