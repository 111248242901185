import { defineMessages } from "react-intl";

const messages = defineMessages( {
	passwordsDoNotMatch: {
		id: "validation.email.doNotMatch",
		defaultMessage: "Passwords do not match.",
	},
	validationFormatEmail: {
		id: "validation.email.format",
		defaultMessage: "{field} must be a valid email address.",
	},
	tooLong: {
		id: "validation.tooLong",
		defaultMessage: "{field} is too long.",
	},
	validationRequired: {
		id: "validation.required",
		defaultMessage: "{field} cannot be empty.",
	},
} );

const maximumEmailLength = 100;

/**
 * The default repeat password constraint, to be used with the validate.js library.
 * (https://validatejs.org/ / `import{ validate } from "validate.js"`)
 *
 * @param {Object} intl the react internationalization library object.
 * @returns {{equality: {attribute: "password", message: string}}} the constraint
 */
export function passwordRepeatConstraint( intl ) {
	return {
		equality: {
			attribute: "password",
			message: intl.formatMessage( messages.passwordsDoNotMatch ),
		},
	};
}

/**
 * The default email constraint, to be used with the validate.js library.
 * (https://validatejs.org/ / `import{ validate } from "validate.js"`)
 *
 * @param {Object} intl the react internationalization library object.
 * @returns {{email: {message: *}}} the constraint
 */
export function emailConstraints( intl ) {
	return {
		presence: {
			allowEmpty: false,
			message: intl.formatMessage( messages.validationRequired, {
				field: "Email",
			} ),
		},
		email: {
			message: intl.formatMessage( messages.validationFormatEmail, {
				field: "Email",
			} ),
		},
		length: {
			maximum: maximumEmailLength,
			message: intl.formatMessage( messages.tooLong, {
				field: "Email",
			} ),
		},
	};
}
