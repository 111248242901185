import reduceReducers from "reduce-reducers";

import {
	BEACON_DATA_SENT,
	DISABLE_USER_FAILURE,
	DISABLE_USER_START,
	DISABLE_USER_SUCCESS,
	FETCH_USER_REQUEST,
	FETCH_USER_SUCCESS,
	LOGIN,
	LOGOUT_FAILURE,
	LOGOUT_REQUEST,
	LOGOUT_SUCCESS,
	PASSWORD_UPDATE_FAILURE,
	PASSWORD_UPDATE_REQUEST,
	PASSWORD_UPDATE_SUCCESS,
	PROFILE_UPDATE_FAILURE,
	PROFILE_UPDATE_REQUEST,
	PROFILE_UPDATE_SUCCESS,
	RESET_SAVE_MESSAGE,
	GET_USER_DATA_SEND,
	GET_USER_DATA_FAILURE,
	GET_USER_DATA_SUCCESS,
	DELETE_USER_SEND,
	DELETE_USER_FAILURE,
	DELETE_USER_SUCCESS,
	ENABLE_CUSTOMER_SUCCESS,
	ENABLE_CUSTOMER_FAILURE,
} from "../actions/user";

const initialState = {
	user: {
		// Whether or not the user is enabled.
		enabled: null,

		// Whether or not the user is currently logged in.
		loggedIn: false,

		// Whether or not the user is currently trying to logout.
		loggingOut: false,

		// Whether or not we are fetching the logged in user data.
		isFetching: false,

		// The currently active & valid access token.
		accessToken: "",

		// The user ID for fetching the user.
		userId: null,

		// The userdata as retrieved from the server.
		data: {
			profile: {
				email: "",
				userFirstName: "",
				userLastName: "",
			},
		},
		pendingRequests: [],
		savingProfile: false,
		saveEmailError: null,
		logOutError: null,
		profileSaved: false,
		sendingPasswordReset: false,
		sendPasswordReset: false,
		passwordResetError: null,
		deletingProfile: false,
		deleteProfileError: null,
		beaconHasData: false,
		userData: "",
		deletedUser: { id: "", error: false, errorMessage: "", status: "", deleted: false },
		enabledCustomers: [],
	},
};

/**
 *  A reducer for the default user object.
 * @param {Object} state The previous state of the store.
 * @param {Object} action The action that just occurred.
 * @returns {Object} The new state for the store.
 */
export function userDataReducer( state = initialState.user, action ) {
	switch ( action.type ) {
		case LOGIN:
			return Object.assign( {}, state, {
				loggedIn: true,
				accessToken: action.data.accessToken,
				userId: action.data.userId,
			} );

		case LOGOUT_REQUEST:
			return Object.assign( {}, state, {
				loggingOut: true,
			} );
		case LOGOUT_FAILURE:
			return Object.assign( {}, state, {
				loggingOut: false,
				logOutError: action.error,
			} );
		case LOGOUT_SUCCESS:
			return initialState.user;
		case FETCH_USER_REQUEST:
			return Object.assign( {}, state, {
				isFetching: true,
			} );
		case FETCH_USER_SUCCESS:
			return Object.assign( {}, state, {
				data: action.user,
				enabled: action.user.profile.enabled,
				isFetching: false,
			} );
		default:
			return state;
	}
}

/**
 * A reducer for the beaconHasData boolean in the user object.
 *
 * @param   {Object} state  The previous state of the store.
 * @param   {Object} action The action that just occurred.
 * @returns {Object} The new state for the store.
 */
export function beaconDataReducer( state = initialState.user, action ) {
	switch ( action.type ) {
		case BEACON_DATA_SENT:
			return Object.assign( {}, state, { beaconHasData: true } );
		default:
			return state;
	}
}

/**
 *  A reducer for the email string within the user object.
 *
 * @param {Object} state The previous state of the store.
 * @param {Object} action The action that just occurred.
 * @returns {Object} The new state for the store.
 */
export function userEmailReducer( state = initialState.user, action ) {
	const subtype = action.subtype || "default";
	switch ( action.type ) {
		case PROFILE_UPDATE_REQUEST:
			return Object.assign( {}, state, {
				pendingRequests: state.pendingRequests.concat( subtype ),
				sendPasswordReset: false,
				savingProfile: true,
				saveEmailError: null,
				profileSaved: false,
			} );

		case PROFILE_UPDATE_FAILURE:
			return Object.assign( {}, state, {
				pendingRequests: state.pendingRequests.filter( ( pendingRequest ) => pendingRequest !== subtype ),
				savingProfile: false,
				saveEmailError: action.error,
				profileSaved: false,
			} );

		case PROFILE_UPDATE_SUCCESS:
			return Object.assign( {}, state, {
				pendingRequests: state.pendingRequests.filter( ( pendingRequest ) => pendingRequest !== subtype ),
				savingProfile: false,
				sendPasswordReset: false,
				profileSaved: true,
				data: Object.assign( {}, state.data, {
					profile: Object.assign( {}, state.data.profile, {
						email: action.profile.userEmail,
						userFirstName: action.profile.userFirstName,
						userLastName: action.profile.userLastName,
					} ),
				} ),
			} );

		// Reset profileSaved when leaving the page.
		case RESET_SAVE_MESSAGE:
			return Object.assign( {}, state, {
				profileSaved: false,
			} );

		default:
			return state;
	}
}

/* eslint-enable complexity */


/**
 * A reducer for the password reset.
 *
 * @param {Object} state The previous state of the store.
 * @param {Object} action The action that just occurred.
 * @returns {Object} The new state of the store.
 */
export function passwordResetReducer( state = initialState.user, action ) {
	switch ( action.type ) {
		case PASSWORD_UPDATE_REQUEST:
			return Object.assign( {}, state, {
				sendingPasswordReset: true,
				passwordResetError: null,
				sendPasswordReset: false,
			} );

		case PASSWORD_UPDATE_SUCCESS:
			return Object.assign( {}, state, {
				sendingPasswordReset: false,
				sendPasswordReset: true,
			} );

		case PASSWORD_UPDATE_FAILURE:
			return Object.assign( {}, state, {
				sendingPasswordReset: false,
				passwordResetError: action.error,
				sendPasswordReset: false,
			} );

		default:
			return state;
	}
}

/**
 * A reducer for disabling a user account
 *
 * @param {Object} state The previous state of the store.
 * @param {Object} action The action that just occurred.
 * @returns {Object} The new state of the store.
 */
export function userDisableReducer( state = initialState.user, action ) {
	switch ( action.type ) {
		case DISABLE_USER_START:
			return Object.assign( {}, state, {
				deletingProfile: true,
			} );
		case DISABLE_USER_SUCCESS:
			return Object.assign( {}, state, {
				deletingProfile: false,
				enabled: false,
			} );
		case DISABLE_USER_FAILURE:
			return Object.assign( {}, state, {
				deletingProfile: false,
				deleteProfileError: action.error,
			} );

		default:
			return state;
	}
}

/**
 * A reducer for enabling user actions.
 *
 * @param {Object} state The previous state of the store.
 * @param {Object} action The action that just occurred.
 * @returns {Object} The new state for the store.
 */
export const enableUserReducer = ( state = initialState.user.enabledCustomers, action ) => {
	switch ( action.type ) {
		case ENABLE_CUSTOMER_SUCCESS:
			return { ...state, enabledCustomers: [ ...state.enabledCustomers, action.customerId ] };
		case ENABLE_CUSTOMER_FAILURE:
		default:
			return state;
	}
};

/**
 * A reducer for userData.
 *
 * @param {Object} state The previous state of the store.
 * @param {Object} action The action that just occurred.
 *
 * @returns {Object} The new state for the store.
 */
export const getUserDataReducer = ( state = initialState.user.userData, action ) => {
	switch ( action.type ) {
		case GET_USER_DATA_SUCCESS:
			return { ...state, userData: action.userData };
		case GET_USER_DATA_FAILURE:
		case GET_USER_DATA_SEND:
		default:
			return state;
	}
};

/**
 * A reducer for deleting a user.
 *
 * @param {Object} state The previous state of the store.
 * @param {Object} action The action that just occurred.
 *
 * @returns {Object} The new state for the store.
 */
export const deleteUserReducer = ( state = initialState.user.deletedUser, action ) => {
	switch ( action.type ) {
		case DELETE_USER_SEND:
			return {
				...state,
				deletedUser: {
					...state.deletedUser,
					id: action.userId,
					error: false,
					status: "",
					deleted: false,
				},
			};
		case DELETE_USER_SUCCESS:
			return {
				...state,
				deletedUser: {
					...state.deletedUser,
					id: action.deletedUserId,
					deleted: true,
				},
			};
		case DELETE_USER_FAILURE:
			return {
				...state,
				deletedUser: {
					...state.deletedUser,
					id: action.userId,
					error: true,
					status: action.status,
					errorMessage: action.errorMessage,
					deleted: false,
				},
			};
		default:
			return state;
	}
};

const userState = reduceReducers(
	userDataReducer,
	userEmailReducer,
	passwordResetReducer,
	userDisableReducer,
	beaconDataReducer,
	getUserDataReducer,
	deleteUserReducer,
	enableUserReducer,
);

/**
 * A combineReducer for the user object.
 *
 * @param {Object} state The current state of the object.
 * @param {Object} action The current action received.
 *
 * @returns {Object} The updated email string.
 */
export function userReducer( state = initialState.user, action ) {
	return userState( state, action );
}

export default userDataReducer;
