import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";

/**
 * Determines if a certain URL is an external URL.
 *
 * @param {string} url The URL to test.
 * @returns {boolean} Whether or not the URL is an external URL.
 */
function isExternal( url ) {
	return url.startsWith( "http" ) || url === "#" || url.startsWith( "mailto:" );
}

/**
 * Determines if a certain URL points to Yoast.
 *
 * @param {string} url The URL to test.
 * @returns {boolean} Whether or not the URL points to Yoast.
 */
function isYoastLink( url ) {
	return /yoast\.com|yoast\.test|yoa\.st/.test( url );
}

/**
 * Link that also works for external URL's.
 *
 * @property {Object} props The component props.
 * @returns {React.JSX} A <Link/> component.
 */
function Link( {
	to,
	id,
	className,
	children,
	ariaLabel,
	linkTarget,
	linkRel,
	onClick,
	hasExternalLinkIcon,
} ) {
	/*
	 * When a link has a target _blank attribute, and it doesn't point to Yoast,
	 * we always want a default "noopener" rel attribute value.
	 */
	let relValueForTargetBlank = "noopener";

	/*
	 * When a link has a target _blank attribute, and it does point to Yoast,
	 * we use the linkRel prop which defaults to `null` so it doesn't render
	 * the rel attribute. This way, it can be overridden setting the prop, if necessary.
	 */
	if ( isYoastLink( to ) ) {
		relValueForTargetBlank = linkRel;
	}

	const hasTargetBlank = linkTarget === "_blank";
	const externalProps = {
		href: to,
		id,
		"aria-label": ariaLabel,
		target: linkTarget,
		rel: hasTargetBlank ? relValueForTargetBlank : linkRel,
		onClick,
	};

	return isExternal( to ) ? (
		<a { ...externalProps } className={ classNames( "yst-inline yst-link--default", className ) }>
			{ children }
			{ hasExternalLinkIcon && <ArrowTopRightOnSquareIcon className="yst-inline-block yst-w-3 yst-h-3 yst-ml-0.5 -yst-translate-y-0.5" /> }
		</a>
	) : (
		<RouterLink
			to={ to }
			id={ id }
			className={ classNames( "yst-inline yst-link--default", className ) }
			aria-label={ ariaLabel }
			onClick={ onClick }
		>
			{ children }
		</RouterLink>
	);
}

Link.propTypes = {
	to: PropTypes.string.isRequired,
	id: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.any.isRequired,
	ariaLabel: PropTypes.string,
	linkTarget: PropTypes.string,
	linkRel: PropTypes.string,
	onClick: PropTypes.func,
	hasExternalLinkIcon: PropTypes.bool,
};

Link.defaultProps = {
	className: null,
	ariaLabel: null,
	linkTarget: null,
	linkRel: null,
	onClick: null,
	hasExternalLinkIcon: false,
};

export default Link;
