import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { entitiesReducer, userReducer } from "shared-frontend/redux/reducers";
import { uiResetPasswordReducer } from "./resetPassword";
import { uiLoginReducer } from "./login";
import { uiSignupReducer } from "./signup";
import { uiActivateReducer } from "./activate";
import { uiHomeReducer } from "./home";
import { uiSiteReducer } from "./site.js";
import { uiSearch } from "./search.js";
import { uiCourseInviteRequestReducer } from "./courses";
import { uiInvoicesReducer } from "./invoices";
import { uiSitesReducer } from "./sites.js";
import { uiCourseEnrollmentsReducer, uiCourseInviteModalReducer, uiCoursesReducer } from "./courses.js";
import {
	uiAddSubscriptionModalReducer,
	uiAllSubscriptionsReducer,
	uiCancelSubscriptionsReducer,
} from "./subscriptions.js";
import { uiAllProductsReducer } from "./products.js";
import { uiOrdersReducer } from "./orders";
import { uiRefundsReducer } from "./refunds";
import { uiComposerTokensReducer } from "./composerTokens";
import { uiAllProductGroupsReducer } from "./productGroups";
import { uiConnectReducer } from "./connect";


export const uiReducer = combineReducers( {
	home: uiHomeReducer,
	sites: uiSitesReducer,
	site: uiSiteReducer,
	search: uiSearch,
	subscriptions: uiAllSubscriptionsReducer,
	subscriptionsCancel: uiCancelSubscriptionsReducer,
	courses: uiCoursesReducer,
	courseEnrollments: uiCourseEnrollmentsReducer,
	courseInviteModal: uiCourseInviteModalReducer,
	courseInviteRequest: uiCourseInviteRequestReducer,
	products: uiAllProductsReducer,
	productGroups: uiAllProductGroupsReducer,
	orders: uiOrdersReducer,
	invoiceModal: uiInvoicesReducer,
	refunds: uiRefundsReducer,
	addSubscriptionModal: uiAddSubscriptionModalReducer,
	composerTokens: uiComposerTokensReducer,
	resetPassword: uiResetPasswordReducer,
	login: uiLoginReducer,
	signup: uiSignupReducer,
	activate: uiActivateReducer,
	connect: uiConnectReducer,
} );


export default ( history ) => combineReducers( {
	ui: uiReducer,
	user: userReducer,
	entities: entitiesReducer,
	router: connectRouter( history ),
} );
