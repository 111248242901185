import React, { useContext } from "react";

import InstallContainer from "../../containers/InstallContainer";
import Downloading from "./Downloading";
import ProcessingOrder from "./ProcessingOrder";
import { InstallContext, InstallContextProvider } from "./InstallContext";

/**
 * Checks which component should be rendered.
 *
 * @returns {ReactComponent} the in react context wrapped InstallContainer or Downloading component.
 */
const InstallWrapper = () => {
	/**
	 * @returns {object} the active component.
	 */
	const ActiveComponent = () => {
		const { linkedSite, downloadZip, orderExist, invoiceNumberParam } = useContext( InstallContext );

		if ( linkedSite && downloadZip ) {
			return <Downloading />;
		}

		if ( ! invoiceNumberParam || orderExist ) {
			return <InstallContainer />;
		}

		return <ProcessingOrder />;
	};

	return (
		<InstallContextProvider>
			<ActiveComponent />
		</InstallContextProvider>
	);
};

export default InstallWrapper;
